import React from "react";

export const Slider = ({ child, transform_by, cust_class,cust_style }) => {
  return (
    <div
      className={"slider_main" + (cust_class ?  ` ${cust_class}` : "")}
      style={{ "--transform_by": transform_by , ...(cust_style?{...cust_style}:{})}}
    >
      <div className="slider_sub">{child}</div>
    </div>
  );
};