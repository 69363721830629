import React, { useEffect, useState } from "react";
import { Button } from "../../../Custom/Button/Button";
// import { CustInput } from "../../../Custom/CustInput/CustInput";
export const RequireSignature = ({ modal_data, modal_page_data, close }) => {
  // const [page_data, setpage_data] = useState({
  //   // authorization_list: [ {
  //   //   name : "Writer One",
  //   //   percentage_share : 2.00,
  //   //   authorized : true,
  //   // },
  //   // {
  //   //   name : "Writer One",
  //   //   percentage_share : 2.00,
  //   //   authorized : true,
  //   // },
  //   // {
  //   //   name : "Writer One",
  //   //   percentage_share : 2.00,
  //   //   authorized : false,
  //   // },
  //   // ],
  // });
  const [Data, setData] = useState()
  // console.log(modal_data.authorization_list);
  //   const dispatch = useDispatch()
  // useEffect(() => {
  //   setpage_data((prev) => ({
  //     ...prev,
  //     ...(Object.keys(modal_page_data).length > 0 ? modal_page_data : {}),
  //   }));
  //   return () => {};
  // }, []);
// console.log( modal_data)
// useEffect(() => {
//   setData(
//     JSON.parse(localStorage.getItem("MMPREQUIREDSIGNATURE"))[0].writers
//   )
  
// }, [])
useEffect(() => {
  if (JSON.parse(localStorage.getItem("MMPREQUIREDSIGNATURE")))
    setData([...JSON.parse(localStorage.getItem("MMPREQUIREDSIGNATURE"))]);
}, []);
// console.log(Data);

  return (
    <div className="requiresignature">
      <div className="writers_group">
        {Data?.map((el) => (
          <div className="writer_item">
            <div className="writer_img" style={{backgroundImage:`url(${el.photo_link})`,backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              {/* {el.photo_link ? <img src={el.photo_link} alt="persons_img" /> : ""} */}
            </div>
            <div className="writer_txt">
              <div >{el.name}</div>
              {/* <div style={{textOverflow:"ellipsis"}}>{el.name}</div> */}
              <div>
                {el?.percentage
                  ? Number(el.percentage).toFixed(2)
                  : "0"}
                %
              </div>
            </div>
            <div
              className="authorization_status"
              authorised={el.authorised.toString()}
            >
              {el?.authorised ? "Authorized" : "Pending"}
            </div>
          </div>
        ))}
      </div>
      <Button
        custclass="close_btn"
        type="type1"
        btn_obj={{
          txt: "Close",
          //   show_type:
          //     current_login_page === "complete_profile2" ? "hide" : "",
          onclick: () => {
            close(0);
          },
          loading: false,
        }}
      />
    </div>
  );
};
