import React, { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export const Loader = () => {
  const current_store = useSelector((state) => state);
  const circle1 = useRef();
  const circle2 = useRef();
  const [circles, setcircles] = useState([
    {
      height: 0,
      width: 0,
    },
    {
      height: 0,
      width: 0,
    },
  ]);
  useLayoutEffect(() => {
    setcircles([
      {
        height: circle1?.current?.getBoundingClientRect()?.height,
        width: circle1?.current?.getBoundingClientRect()?.width,
      },
      {
        height: circle2?.current?.getBoundingClientRect()?.height,
        width: circle2?.current?.getBoundingClientRect()?.width,
      },
    ]);
  }, []);
  if (!current_store.appdata.loading) {
    return "";
  }
  return (
    <div
      className="loader"
      style={{
        transform: !current_store.appdata.loading ? "scale(0)" : "scale(1)",
      }}
    >
      <div className="loader_main">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97"
          height="102"
          viewBox="0 0 97 102"
          fill="none"
          className="logo_animated"
        >
          <path
            className="border"
            d="M49.9073 1.13601C49.5823 1.18602 49.0072 1.58608 48.5821 2.01114L47.832 2.76125L47.882 22.8392L47.957 42.9421L48.6822 43.6922C49.0822 44.1173 49.7573 44.5674 50.1824 44.6924C50.6574 44.8424 59.1337 44.8674 72.5106 44.7674L94.0888 44.5924L95.0139 43.6422L95.9641 42.7171L95.9391 40.5168C95.889 35.341 93.2887 28.0899 89.3881 22.2141C82.8121 12.3126 71.6355 4.88656 59.3087 2.18617C57.4084 1.78611 50.9575 0.885976 50.5824 0.985991C50.5074 1.01099 50.2074 1.086 49.9073 1.13601Z"
            fill="#C50D6D"
            ref={circle1}
          />
          <path
            className="path6"
            d="M38.3549 1.56123C38.1299 1.83627 38.0049 7.26206 37.9048 22.8143C37.7548 42.7672 37.7798 43.7424 38.2049 44.2175C38.6049 44.6425 38.98 44.7175 41.1053 44.7175C43.3806 44.7175 43.6057 44.6675 43.9307 44.1424C44.2058 43.6924 44.3058 40.0168 44.4308 22.8143C44.5808 3.91157 44.5558 1.98629 44.2058 1.61124C43.6557 1.06116 38.805 1.03615 38.3549 1.56123Z"
            fill="#C50D6D"
          />
          <path
            className="path5"
            d="M29.8537 2.91148C29.7037 3.18652 29.6037 11.1877 29.5537 23.7145C29.5037 47.1679 29.1786 44.7176 32.3291 44.7176C33.9043 44.7176 34.3294 44.6176 34.6794 44.2425C35.0795 43.8174 35.1295 42.1422 35.2545 23.5145C35.3795 7.1871 35.3545 3.16151 35.0795 2.83646C34.8294 2.56142 34.2293 2.46141 32.4291 2.46141C30.4288 2.46141 30.0537 2.53642 29.8537 2.91148Z"
            fill="#C50D6D"
          />
          <path
            className="path4"
            d="M22.1284 6.76199C21.9784 6.96202 21.8284 13.888 21.7033 25.3897L21.5283 43.6674L22.0534 44.1925C22.5035 44.6425 22.8785 44.7175 24.4537 44.7175C26.104 44.7175 26.354 44.6675 26.6791 44.1424C26.9541 43.6924 27.0541 40.4419 27.1541 25.4897C27.2542 13.813 27.2041 7.21206 27.0541 6.91201C26.8541 6.53696 26.479 6.46195 24.6038 6.46195C23.1286 6.46195 22.3034 6.56196 22.1284 6.76199Z"
            fill="#C50D6D"
          />
          <path
            className="path3"
            d="M14.6513 12.3377L14.0762 12.7378V28.315C14.0762 40.4918 14.1512 43.9673 14.4012 44.1423C14.5512 44.2924 15.5264 44.4924 16.5015 44.5674C18.2268 44.7424 18.3518 44.7174 18.9519 44.1173L19.577 43.4922V28.165C19.577 19.0137 19.477 12.6878 19.3269 12.4627C18.9769 11.8876 15.3764 11.7876 14.6513 12.3377Z"
            fill="#C50D6D"
          />
          <path
            className="path2"
            d="M7.47721 19.3636C7.12716 19.7137 7.07715 21.1889 7.07715 31.8154C7.07715 40.8418 7.15216 43.9422 7.37719 44.1672C7.55222 44.3423 8.45235 44.4673 9.62752 44.4673C11.2778 44.4673 11.6028 44.3923 11.8278 43.9922C12.2029 43.2421 12.1779 19.6637 11.7778 19.2636C11.3028 18.7885 7.95228 18.8635 7.47721 19.3636Z"
            fill="#C50D6D"
          />
          <path
            className="path1"
            d="M1.00096 32.6407C0.92595 32.7407 0.800932 35.2661 0.700917 38.2415C0.475884 44.5675 0.45088 44.4674 2.80122 44.4674C3.75136 44.4674 4.37645 44.3424 4.55148 44.1174C4.75151 43.8924 4.82652 42.0921 4.77651 38.1915L4.7015 32.5907L2.90124 32.5157C1.9261 32.4657 1.07597 32.5407 1.00096 32.6407Z"
            fill="#C50D6D"
          />
          <path
            d="M0.575195 50.9683V55.969H1.3253H2.07541L2.10042 53.0186L2.12542 50.0931L2.80052 52.0934C3.20058 53.2436 3.65064 54.1187 3.85067 54.1687C4.12571 54.2187 4.40075 53.6937 4.82582 52.3435C5.15086 51.2933 5.52592 50.3932 5.62593 50.3182C5.72595 50.2432 5.82596 51.4933 5.82596 53.0686V55.969H6.45105H7.07614V50.9433V45.9425L6.40105 46.0175C5.75095 46.0926 5.62593 46.2926 4.80081 48.7179C3.75066 51.6934 3.95069 51.7934 2.62549 47.9178C2.0004 46.1176 1.90039 45.9675 1.2753 45.9675H0.575195V50.9683Z"
            fill="#C50D6D"
          />

          <path
            d="M8.07715 46.1426C8.07715 46.2676 8.47721 47.5428 8.95228 48.968C9.67738 51.1683 9.8274 51.9184 9.8274 53.7687V55.969H10.5775H11.3276V53.7437C11.3276 51.7684 11.4526 51.1933 12.3278 48.7179L13.3279 45.9425L12.5028 46.0175C11.7277 46.0926 11.6777 46.1676 11.2276 47.8928C10.9776 48.893 10.6775 49.7181 10.6025 49.7181C10.5025 49.7181 10.2275 48.893 9.95242 47.8928C9.50236 46.2676 9.37734 46.0926 8.75225 46.0175C8.37719 45.9675 8.07715 46.0425 8.07715 46.1426Z"
            fill="#C50D6D"
          />
       
          <path
            d="M16.8271 50.9433V55.969H17.4522H18.0523L18.1273 52.9185L18.2023 49.8431L18.9275 52.0434C19.8026 54.7938 20.1526 54.8188 21.0528 52.0934L21.7029 50.0931L21.7779 53.0186L21.8529 55.969H22.578H23.3281V50.9683V45.9675H22.603C21.9279 45.9675 21.8529 46.0926 20.9528 48.6429C19.8776 51.6184 20.0526 51.6684 18.8524 48.0928C18.2274 46.3176 18.0773 46.0926 17.5022 46.0175L16.8271 45.9425V50.9433Z"
            fill="#C50D6D"
          />
          <path
            d="M24.6297 50.3182C24.7047 54.4938 24.7297 54.6938 25.2798 55.2939C26.0299 56.094 26.955 56.3191 27.8802 55.944C29.1554 55.419 29.3304 54.6938 29.3304 50.0932V45.9676H28.5803H27.8302V50.2182C27.8302 53.9437 27.7802 54.4938 27.4301 54.6438C27.2051 54.7188 26.83 54.6938 26.605 54.5438C26.2799 54.3188 26.2049 53.6437 26.1299 50.1182L26.0799 45.9676H25.3048H24.5547L24.6297 50.3182Z"
            fill="#C50D6D"
          />
          <path
            d="M31.2302 46.7426C30.7801 47.2677 30.5801 47.7928 30.5801 48.4679C30.5801 50.0181 31.0551 50.7182 32.5054 51.3683C33.9306 52.0184 34.3556 52.7435 33.9306 53.9437C33.5305 55.0689 31.8303 54.8688 31.8303 53.6937C31.8303 53.3436 31.6552 53.2186 31.2052 53.2186C30.7051 53.2186 30.5801 53.3436 30.5801 53.8687C30.5801 55.994 33.5055 56.9941 34.8307 55.2939C35.2058 54.8188 35.3308 54.2937 35.3308 53.2436C35.3308 51.4933 34.9307 50.8932 33.3305 50.1931C32.0303 49.6431 31.7052 49.168 31.9803 48.1178C32.2553 46.9927 33.7055 46.9927 33.9806 48.1178C34.0806 48.5429 34.3056 48.7179 34.7307 48.7179C35.2308 48.7179 35.3308 48.5929 35.3308 47.9678C35.3308 46.7926 34.3556 45.9675 32.9804 45.9675C32.0553 45.9675 31.7803 46.0925 31.2302 46.7426Z"
            fill="#C50D6D"
          />
          <path
            d="M36.5801 50.9683V55.969H37.3302H38.0803V50.9683V45.9675H37.3302H36.5801V50.9683Z"
            fill="#C50D6D"
          />
          <path
            d="M39.9811 46.7426C39.3561 47.4927 39.3311 47.6177 39.3311 50.8432C39.3311 52.9185 39.4561 54.4437 39.6311 54.8438C39.9561 55.5689 41.0063 56.219 41.8564 56.219C42.6065 56.219 44.0817 54.6688 44.0817 53.8687C44.0817 53.3186 43.9817 53.2186 43.3316 53.2186C42.7065 53.2186 42.5815 53.3186 42.5815 53.8436C42.5815 54.2937 42.4065 54.4937 41.9814 54.6188C41.6314 54.6938 41.2313 54.6938 41.1063 54.5938C40.9313 54.4937 40.8313 53.1685 40.8313 51.1183C40.8313 48.3678 40.9063 47.7178 41.2313 47.4427C41.7314 47.0427 42.4315 47.4177 42.5315 48.1928C42.6065 48.5929 42.7816 48.7179 43.3816 48.7179C44.1068 48.7179 44.1318 48.6679 43.9817 47.9178C43.7317 46.6426 42.9316 45.9675 41.6814 45.9675C40.8063 45.9675 40.5062 46.0925 39.9811 46.7426Z"
            fill="#C50D6D"
          />
          <path
            d="M47.832 50.9683V55.969H48.5821H49.3323V53.9687V51.9684H50.1824C50.6574 51.9684 51.2325 51.8934 51.4576 51.8184C52.1077 51.5684 52.5827 50.3182 52.5827 48.843C52.5827 47.6178 52.4827 47.3427 51.8576 46.6926C51.1825 46.0425 50.9575 45.9675 49.4823 45.9675H47.832V50.9683ZM51.0825 47.6928C51.2075 47.9678 51.3325 48.5429 51.3325 48.968C51.3325 50.1431 50.9325 50.7182 50.0824 50.7182H49.3323V48.968V47.2177H50.0824C50.5574 47.2177 50.9075 47.3927 51.0825 47.6928Z"
            fill="#C50D6D"
          />
          <path
            d="M53.833 50.2431C53.833 52.6935 53.933 54.7188 54.083 54.9938C54.4831 55.7189 55.7583 56.269 56.6084 56.094C57.0085 55.994 57.5586 55.6439 57.8336 55.2939C58.2837 54.6938 58.3337 54.2937 58.3337 50.3181V45.9675H57.7086H57.0835V49.8681C57.0835 53.8437 56.9085 54.7188 56.1333 54.7188C55.1832 54.7188 55.0832 54.2437 55.0832 50.0431V45.9675H54.4581H53.833V50.2431Z"
            fill="#C50D6D"
          />
          <path
            d="M59.834 50.9683V55.969H61.4092C62.4094 55.969 63.1595 55.819 63.5095 55.5939C64.6347 54.8188 64.8847 52.0434 63.9346 51.0933C63.5845 50.7682 63.5845 50.6682 63.9346 50.3182C64.5347 49.7431 64.6847 48.7929 64.3596 47.6928C63.9596 46.3676 63.2845 45.9675 61.4092 45.9675H59.834V50.9683ZM62.7844 47.5178C63.2845 48.0178 63.1595 49.6681 62.6094 49.9681C61.5592 50.5182 61.3342 50.3182 61.3342 48.7179C61.3342 47.3177 61.3842 47.2177 61.9093 47.2177C62.2343 47.2177 62.6094 47.3427 62.7844 47.5178ZM62.4344 51.4683C63.0094 51.6684 63.0845 51.8434 63.0845 52.9436C63.0845 53.9187 62.9844 54.2687 62.6094 54.4688C61.5092 55.0439 61.3342 54.8188 61.3342 52.9686C61.3342 51.1433 61.3842 51.0683 62.4344 51.4683Z"
            fill="#C50D6D"
          />
          <path
            d="M65.834 50.9683V55.969H67.9593C70.0596 55.969 70.0846 55.969 70.0846 55.3439C70.0846 54.7438 70.0096 54.7188 68.7094 54.7188H67.3342V50.3432V45.9675H66.5841H65.834V50.9683Z"
            fill="#C50D6D"
          />
          <path
            d="M71.335 50.9683V55.969H71.9601H72.5851V50.9683V45.9675H71.9601H71.335V50.9683Z"
            fill="#C50D6D"
          />
          <path
            d="M74.9111 46.3427C73.8359 47.0928 73.5859 49.4681 74.486 50.4933C74.6861 50.6933 75.4112 51.1434 76.0863 51.4684C77.2864 52.0435 77.3364 52.0935 77.3364 53.0936C77.3364 54.2938 76.9114 54.7939 76.0363 54.6689C75.6112 54.6189 75.4362 54.3938 75.3862 53.8938C75.3112 53.3187 75.1861 53.2187 74.561 53.2187C74.011 53.2187 73.8359 53.3437 73.8359 53.7187C73.8359 54.5939 74.386 55.444 75.2861 55.869C77.1114 56.7442 78.8367 55.419 78.8367 53.1437C78.8367 51.5684 78.4616 51.0183 76.9114 50.2932C75.5112 49.6181 75.4612 49.5681 75.4612 48.618C75.4612 47.9679 75.6112 47.5678 75.9112 47.3678C76.4613 47.0178 77.1864 47.4428 77.2864 48.1929C77.3615 48.593 77.5365 48.718 78.1116 48.718C78.7367 48.718 78.8367 48.618 78.8367 48.0679C78.8367 47.0678 77.5865 45.9676 76.4113 45.9676C75.8862 45.9676 75.2111 46.1426 74.9111 46.3427Z"
            fill="#C50D6D"
          />
          <path
            d="M79.8369 50.9683V55.969H80.587H81.3371V53.7187V51.4683H82.2123H83.0874V53.7187V55.969H83.8375H84.5876V50.9683V45.9675H83.8375H83.0874V48.0928V50.2182H82.2123H81.3371V48.0928V45.9675H80.587H79.8369V50.9683Z"
            fill="#C50D6D"
          />
          <path
            d="M86.0879 50.9683V55.969H88.2132C90.3135 55.969 90.3385 55.969 90.3385 55.3439C90.3385 54.7938 90.2385 54.7438 88.9133 54.6688L87.4631 54.5938L87.3881 53.1436L87.3131 51.7184H88.5883C89.8134 51.7184 89.8384 51.6934 89.8384 50.9683C89.8384 50.2432 89.8134 50.2182 88.5883 50.2182H87.3381V48.7179V47.2177H88.8383C90.2635 47.2177 90.3385 47.1927 90.3385 46.5926C90.3385 45.9675 90.3135 45.9675 88.2132 45.9675H86.0879V50.9683Z"
            fill="#C50D6D"
          />
          <path
            d="M91.3379 50.9683V55.969H91.963C92.5881 55.969 92.5881 55.944 92.5881 53.8187C92.5881 51.7684 92.6131 51.6934 93.1382 51.7684C93.5882 51.8184 93.7882 52.2184 94.2883 53.8937C94.8884 55.869 94.9384 55.969 95.6135 55.969C96.0136 55.969 96.3386 55.869 96.3386 55.769C96.3386 55.6439 96.0136 54.6688 95.6135 53.5686L94.8884 51.5934L95.4885 50.8933C95.9636 50.3432 96.0886 49.9181 96.0886 48.818C96.0886 47.6178 95.9886 47.3177 95.3635 46.6926C94.6884 46.0425 94.4633 45.9675 92.9881 45.9675H91.3379V50.9683ZM94.1883 47.3677C94.6884 47.5678 94.9384 48.918 94.6134 49.7931C94.3883 50.3432 94.1883 50.4682 93.4632 50.4682H92.5881V49.018C92.5881 48.2179 92.6631 47.4678 92.7631 47.3927C92.9631 47.1677 93.6632 47.1677 94.1883 47.3677Z"
            fill="#C50D6D"
          />
          <path
            className="path1"
            d="M1.09941 57.5694C0.899381 57.7944 0.82437 59.5947 0.874377 63.4952L0.949388 69.0961L2.69964 69.1711C4.12485 69.2211 4.49991 69.1711 4.74994 68.821C5.15 68.2709 5.20001 58.8696 4.82495 57.8694C4.59992 57.2693 4.4749 57.2193 2.97468 57.2193C1.92453 57.2193 1.29944 57.3444 1.09941 57.5694Z"
            fill="#C50D6D"
          />
          <path
            className="path2"
            d="M7.62622 57.5193C7.40118 57.7444 7.32617 60.8948 7.32617 70.1462C7.32617 82.0479 7.35118 82.473 7.80124 82.723C8.55135 83.1231 11.1267 83.0231 11.7768 82.573L12.3269 82.1979V70.1962C12.3269 62.395 12.2269 58.0194 12.0769 57.6944C11.8518 57.2943 11.5268 57.2193 9.87654 57.2193C8.70137 57.2193 7.80124 57.3443 7.62622 57.5193Z"
            fill="#C50D6D"
          />
          <path
            className="path3"
            d="M14.4768 58.0195C14.1267 58.5195 14.0767 60.4448 14.0767 73.8968C14.0767 91.7744 13.8017 90.2242 16.8521 90.2242C19.8776 90.2242 19.5775 92.0744 19.5775 73.8968C19.5775 61.92 19.5025 57.9945 19.2775 57.7694C19.1025 57.5944 18.1773 57.4694 16.9271 57.4694C15.0769 57.4694 14.8268 57.5194 14.4768 58.0195Z"
            fill="#C50D6D"
          />
          <path
            className="path4"
            d="M22.0782 57.9443C21.9032 58.2694 21.8282 64.6203 21.8282 76.7971C21.8282 98.0502 21.5282 95.9749 24.6286 95.9749C26.1288 95.9749 26.5789 95.8749 26.9039 95.5248C27.304 95.0997 27.329 93.4245 27.329 76.7471C27.329 64.5953 27.254 58.2694 27.079 57.9443C26.8539 57.5193 26.5289 57.4693 24.5786 57.4693C22.6283 57.4693 22.3033 57.5193 22.0782 57.9443Z"
            fill="#C50D6D"
          />
          <path
            className="path5"
            d="M29.9792 58.0192C29.6291 58.5193 29.5791 60.8946 29.5791 78.7222C29.5791 96.5498 29.6291 98.9252 29.9792 99.4253C30.3292 99.9253 30.5792 99.9753 32.5545 99.9753C33.9047 99.9753 34.8299 99.8753 35.0299 99.6753C35.455 99.2502 35.455 58.1943 35.0299 57.7692C34.8299 57.5692 33.9047 57.4691 32.5545 57.4691C30.5792 57.4691 30.3292 57.5192 29.9792 58.0192Z"
            fill="#C50D6D"
          />
          <path
            className="path6"
            d="M38.5061 57.9193C38.1061 58.3443 38.0811 60.1946 38.0811 79.4974C38.0811 95.5998 38.1561 100.701 38.3811 100.926C38.5811 101.126 39.6063 101.226 41.3315 101.226C43.0568 101.226 44.0819 101.126 44.282 100.926C44.682 100.525 44.707 58.6944 44.332 57.9443C44.0819 57.5192 43.7819 57.4692 41.4816 57.4692C39.3062 57.4692 38.8562 57.5442 38.5061 57.9193Z"
            fill="#C50D6D"
          />
          <path
            ref={circle2}
            className="quarter_circle"
            d="M49.6073 57.9195C49.1322 58.1695 48.5321 58.7696 48.2821 59.2447C47.857 60.0448 47.832 61.1699 47.832 79.6726C47.832 97.7003 47.857 99.3255 48.2571 100.001C48.9572 101.176 50.0824 101.551 52.3077 101.351C74.886 99.3755 92.8886 83.6232 95.9641 63.0702C96.3891 60.2198 96.2641 59.4447 95.2139 58.3195L94.4138 57.4694H72.4356C51.6076 57.4694 50.4074 57.4944 49.6073 57.9195Z"
            fill="#C50D6D"
          />
        </svg> */}

        <svg
          width="97"
          height="102"
          viewBox="0 0 97 102"
          fill="none"
          className="logo_animated"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
          className="border"
            d="M49.8116 0.831326C49.4866 0.881332 48.9115 1.28139 48.4864 1.70646L47.7363 2.45657L47.7863 22.5345L47.8613 42.6374L48.5865 43.3875C48.9865 43.8126 49.6616 44.2627 50.0867 44.3877C50.5617 44.5377 59.038 44.5627 72.4149 44.4627L93.9931 44.2877L94.9182 43.3375L95.8684 42.4124L95.8433 40.2121C95.7933 35.0363 93.193 27.7853 89.2924 21.9094C82.7164 12.008 71.5398 4.58187 59.213 1.88148C57.3127 1.48142 50.8618 0.581287 50.4867 0.681305C50.4117 0.706306 50.1117 0.781319 49.8116 0.831326Z"
            fill="#C50D6D"
            ref={circle1}
          />
          <path
           className="path6"
            d="M38.2592 1.25654C38.0342 1.53158 37.9092 6.95737 37.8091 22.5096C37.6591 42.4626 37.6841 43.4377 38.1092 43.9128C38.5092 44.3378 38.8843 44.4128 41.0096 44.4128C43.2849 44.4128 43.51 44.3628 43.835 43.8378C44.1101 43.3877 44.2101 39.7122 44.3351 22.5096C44.4851 3.60689 44.4601 1.68161 44.1101 1.30655C43.56 0.75647 38.7093 0.731468 38.2592 1.25654Z"
            fill="#C50D6D"
          />
          <path
           className="path5"
            d="M29.758 2.60679C29.608 2.88183 29.508 10.883 29.458 23.4098C29.408 46.8632 29.0829 44.4129 32.2334 44.4129C33.8086 44.4129 34.2337 44.3129 34.5837 43.9378C34.9838 43.5128 35.0338 41.8375 35.1588 23.2098C35.2838 6.88241 35.2588 2.85682 34.9838 2.53178C34.7337 2.25674 34.1336 2.15672 32.3334 2.15672C30.3331 2.15672 29.958 2.23173 29.758 2.60679Z"
            fill="#C50D6D"
          />
          <path
          className="path4"
            d="M22.0327 6.45731C21.8827 6.65733 21.7327 13.5833 21.6076 25.085L21.4326 43.3627L21.9577 43.8878C22.4078 44.3378 22.7828 44.4128 24.358 44.4128C26.0083 44.4128 26.2583 44.3628 26.5834 43.8378C26.8584 43.3877 26.9584 40.1372 27.0584 25.185C27.1585 13.5083 27.1084 6.90737 26.9584 6.60733C26.7584 6.23227 26.3833 6.15726 24.5081 6.15726C23.0329 6.15726 22.2077 6.25727 22.0327 6.45731Z"
            fill="#C50D6D"
          />
          <path
           className="path3"
            d="M14.5556 12.033L13.9805 12.4331V28.0103C13.9805 40.1871 14.0555 43.6626 14.3055 43.8377C14.4555 43.9877 15.4307 44.1877 16.4058 44.2627C18.1311 44.4377 18.2561 44.4127 18.8562 43.8127L19.4813 43.1876V27.8603C19.4813 18.709 19.3813 12.3831 19.2312 12.158C18.8812 11.5829 15.2807 11.4829 14.5556 12.033Z"
            fill="#C50D6D"
          />
          <path
           className="path2"
            d="M7.3815 19.0589C7.03145 19.409 6.98145 20.8842 6.98145 31.5108C6.98145 40.5371 7.05646 43.6375 7.28149 43.8626C7.45651 44.0376 8.35665 44.1626 9.53182 44.1626C11.1821 44.1626 11.5071 44.0876 11.7321 43.6875C12.1072 42.9374 12.0822 19.359 11.6821 18.9589C11.2071 18.4839 7.85657 18.5589 7.3815 19.0589Z"
            fill="#C50D6D"
          />
          <path
           className="path1"
            d="M0.905258 32.336C0.830247 32.436 0.705228 34.9614 0.605214 37.9368C0.380181 44.2628 0.355177 44.1627 2.70552 44.1627C3.65566 44.1627 4.28075 44.0377 4.45578 43.8127C4.6558 43.5877 4.73082 41.7874 4.68081 37.8868L4.6058 32.286L2.80553 32.211C1.83039 32.161 0.980269 32.236 0.905258 32.336Z"
            fill="#C50D6D"
          />
          <path
            d="M1.00371 57.2647C0.803678 57.4897 0.728667 59.29 0.778674 63.1906L0.853685 68.7914L2.60394 68.8664C4.02915 68.9164 4.4042 68.8664 4.65424 68.5163C5.0543 67.9663 5.1043 58.5649 4.72925 57.5647C4.50422 56.9647 4.3792 56.9146 2.87898 56.9146C1.82883 56.9146 1.20374 57.0397 1.00371 57.2647Z"
            fill="#C50D6D"
            className="path1"
          />
          <path
            d="M7.53051 57.2146C7.30548 57.4397 7.23047 60.5901 7.23047 69.8415C7.23047 81.7432 7.25547 82.1683 7.70554 82.4183C8.45565 82.8184 11.031 82.7184 11.6811 82.2683L12.2312 81.8932V69.8915C12.2312 62.0904 12.1312 57.7147 11.9812 57.3897C11.7561 56.9896 11.4311 56.9146 9.78084 56.9146C8.60567 56.9146 7.70554 57.0396 7.53051 57.2146Z"
            fill="#C50D6D"
            className="path2"
          />
          <path
            d="M14.3811 57.7148C14.031 58.2149 13.981 60.1401 13.981 73.5921C13.981 91.4697 13.706 89.9195 16.7564 89.9195C19.7819 89.9195 19.4818 91.7697 19.4818 73.5921C19.4818 61.6153 19.4068 57.6898 19.1818 57.4647C19.0068 57.2897 18.0816 57.1647 16.8314 57.1647C14.9812 57.1647 14.7311 57.2147 14.3811 57.7148Z"
            fill="#C50D6D"
            className="path3"
          />
          <path
            d="M21.9825 57.6396C21.8075 57.9647 21.7325 64.3156 21.7325 76.4924C21.7325 97.7455 21.4325 95.6702 24.5329 95.6702C26.0331 95.6702 26.4832 95.5702 26.8082 95.2201C27.2083 94.7951 27.2333 93.1198 27.2333 76.4424C27.2333 64.2906 27.1583 57.9647 26.9833 57.6396C26.7582 57.2146 26.4332 57.1646 24.4829 57.1646C22.5326 57.1646 22.2076 57.2146 21.9825 57.6396Z"
            fill="#C50D6D"
            className="path4"
          />
          <path
            d="M29.8835 57.7145C29.5334 58.2146 29.4834 60.59 29.4834 78.4176C29.4834 96.2452 29.5334 98.6205 29.8835 99.1206C30.2335 99.6206 30.4835 99.6707 32.4588 99.6707C33.809 99.6707 34.7342 99.5706 34.9342 99.3706C35.3593 98.9455 35.3593 57.8896 34.9342 57.4645C34.7342 57.2645 33.809 57.1645 32.4588 57.1645C30.4835 57.1645 30.2335 57.2145 29.8835 57.7145Z"
            fill="#C50D6D"
            className="path5"
          />
          <path
            d="M38.4104 57.6146C38.0104 58.0396 37.9854 59.8899 37.9854 79.1927C37.9854 95.2951 38.0604 100.396 38.2854 100.621C38.4854 100.821 39.5106 100.921 41.2358 100.921C42.9611 100.921 43.9862 100.821 44.1863 100.621C44.5863 100.221 44.6113 58.3897 44.2363 57.6396C43.9862 57.2145 43.6862 57.1645 41.3858 57.1645C39.2105 57.1645 38.7605 57.2395 38.4104 57.6146Z"
            fill="#C50D6D"
            className="path6"
          />
          <path
            d="M49.5116 57.6148C49.0365 57.8648 48.4364 58.4649 48.1864 58.94C47.7613 59.7401 47.7363 60.8653 47.7363 79.368C47.7363 97.3956 47.7613 99.0208 48.1614 99.6959C48.8615 100.871 49.9867 101.246 52.212 101.046C74.7903 99.0708 92.7929 83.3185 95.8683 62.7655C96.2934 59.9151 96.1684 59.14 95.1182 58.0148L94.3181 57.1647H72.3399C51.5119 57.1647 50.3117 57.1897 49.5116 57.6148Z"
            fill="#C50D6D"
          />
          <path
            d="M28.2207 54.0321V55.3207H24.3804V54.0321H28.2207ZM24.7866 46.0785V55.3207H23.2441V46.0785H24.7866Z"
            fill="#C50D6D"
          />
          <path
            d="M36.6636 49.9442V51.5184C36.6636 52.1701 36.5874 52.7414 36.4351 53.2323C36.2869 53.7232 36.0711 54.1337 35.7876 54.4637C35.5083 54.7896 35.1698 55.035 34.772 55.2001C34.3784 55.3651 33.9383 55.4476 33.4517 55.4476C32.9692 55.4476 32.5291 55.3651 32.1313 55.2001C31.7378 55.035 31.3971 54.7896 31.1094 54.4637C30.8258 54.1337 30.6058 53.7232 30.4492 53.2323C30.2969 52.7414 30.2207 52.1701 30.2207 51.5184V49.9442C30.2207 49.2841 30.2969 48.7064 30.4492 48.2113C30.6016 47.712 30.8195 47.2951 31.103 46.9608C31.3908 46.6265 31.7314 46.3747 32.125 46.2054C32.5228 46.0362 32.9608 45.9515 33.439 45.9515C33.9256 45.9515 34.3657 46.0362 34.7593 46.2054C35.1571 46.3747 35.4977 46.6265 35.7812 46.9608C36.0648 47.2951 36.2827 47.712 36.4351 48.2113C36.5874 48.7064 36.6636 49.2841 36.6636 49.9442ZM35.1338 51.5184V49.9315C35.1338 49.4618 35.0957 49.0598 35.0195 48.7255C34.9476 48.3869 34.8397 48.1119 34.6958 47.9003C34.5519 47.6844 34.3742 47.5258 34.1626 47.4242C33.9552 47.3184 33.714 47.2655 33.439 47.2655C33.1724 47.2655 32.9333 47.3184 32.7217 47.4242C32.5143 47.5258 32.3366 47.6844 32.1885 47.9003C32.0446 48.1119 31.9346 48.3869 31.8584 48.7255C31.7865 49.0598 31.7505 49.4618 31.7505 49.9315V51.5184C31.7505 51.9797 31.7886 52.3775 31.8647 52.7118C31.9409 53.0419 32.0509 53.3127 32.1948 53.5243C32.3429 53.7316 32.5228 53.8861 32.7344 53.9877C32.946 54.0892 33.1851 54.14 33.4517 54.14C33.7225 54.14 33.9637 54.0892 34.1753 53.9877C34.3869 53.8861 34.5625 53.7316 34.7021 53.5243C34.846 53.3127 34.9539 53.0419 35.0259 52.7118C35.0978 52.3775 35.1338 51.9797 35.1338 51.5184Z"
            fill="#C50D6D"
          />
          <path
            d="M42.5098 47.348L40.2754 55.3207H38.6631L41.5957 46.0785H42.5986L42.5098 47.348ZM44.2935 55.3207L42.0527 47.348L41.9448 46.0785H42.9668L45.9121 55.3207H44.2935ZM44.3315 51.8866V53.1815H40.0278V51.8866H44.3315Z"
            fill="#C50D6D"
          />
          <path
            d="M50.1792 55.3207H48.5732L48.5859 54.0321H50.1792C50.6743 54.0321 51.07 53.9411 51.3662 53.7592C51.6624 53.573 51.8761 53.2894 52.0073 52.9086C52.1385 52.5277 52.2041 52.0411 52.2041 51.4486V49.9442C52.2041 49.4872 52.166 49.0979 52.0898 48.7762C52.0179 48.4504 51.9036 48.1859 51.7471 47.9828C51.5947 47.7754 51.3937 47.6231 51.144 47.5258C50.8944 47.4242 50.5939 47.3734 50.2427 47.3734H48.5415V46.0785H50.2427C50.7716 46.0785 51.2498 46.1631 51.6772 46.3324C52.1047 46.5017 52.4707 46.7513 52.7754 47.0814C53.0843 47.4073 53.3213 47.8114 53.4863 48.2938C53.6514 48.7762 53.7339 49.3306 53.7339 49.9569V51.4486C53.7339 52.0749 53.6514 52.6293 53.4863 53.1117C53.3213 53.5899 53.0843 53.994 52.7754 54.3241C52.4665 54.6499 52.0941 54.8975 51.6582 55.0668C51.2223 55.236 50.7293 55.3207 50.1792 55.3207ZM49.4556 46.0785V55.3207H47.9131V46.0785H49.4556Z"
            fill="#C50D6D"
          />
          <path
            d="M57.2759 46.0785V55.3207H55.7334V46.0785H57.2759Z"
            fill="#C50D6D"
          />
          <path
            d="M65.5415 46.0785V55.3207H64.0308L60.7681 48.9222V55.3207H59.2764V46.0785H60.7681L64.0562 52.5214V46.0785H65.5415Z"
            fill="#C50D6D"
          />
          <path
            d="M73.7617 50.579V54.2225C73.6475 54.3622 73.474 54.5293 73.2412 54.724C73.0127 54.9144 72.7038 55.0837 72.3145 55.2318C71.9294 55.3757 71.4427 55.4476 70.8545 55.4476C70.3594 55.4476 69.9066 55.3672 69.4961 55.2064C69.0898 55.0456 68.7407 54.8023 68.4487 54.4764C68.1567 54.1506 67.9325 53.7443 67.7759 53.2577C67.6193 52.7668 67.541 52.1871 67.541 51.5184V49.8744C67.541 49.2142 67.6108 48.6387 67.7505 48.1478C67.8944 47.6569 68.1038 47.2486 68.3789 46.9227C68.654 46.5927 68.9883 46.3493 69.3818 46.1927C69.7754 46.0319 70.2282 45.9515 70.7402 45.9515C71.3877 45.9515 71.9272 46.0658 72.3589 46.2943C72.7948 46.5186 73.127 46.8529 73.3555 47.2972C73.5882 47.7373 73.7236 48.2748 73.7617 48.9095H72.2573C72.2235 48.5202 72.1494 48.205 72.0352 47.9637C71.9251 47.7183 71.7664 47.5363 71.5591 47.4178C71.3517 47.2994 71.0851 47.2401 70.7593 47.2401C70.4631 47.2401 70.2091 47.2909 69.9976 47.3925C69.786 47.494 69.6125 47.6527 69.4771 47.8685C69.3416 48.0801 69.2422 48.351 69.1787 48.681C69.1152 49.0111 69.0835 49.4047 69.0835 49.8617V51.5184C69.0835 51.9839 69.1237 52.3838 69.2041 52.7181C69.2845 53.0524 69.4009 53.3275 69.5532 53.5433C69.7098 53.7549 69.9023 53.9115 70.1309 54.0131C70.3594 54.1104 70.6196 54.1591 70.9116 54.1591C71.1994 54.1591 71.4279 54.1294 71.5972 54.0702C71.7664 54.0109 71.8976 53.9454 71.9907 53.8734C72.0881 53.7972 72.1663 53.738 72.2256 53.6957V51.7914H70.7339V50.579H73.7617Z"
            fill="#C50D6D"
          />
            <path
            ref={circle2}
            className="quarter_circle"
            d="M49.6073 57.9195C49.1322 58.1695 48.5321 58.7696 48.2821 59.2447C47.857 60.0448 47.832 61.1699 47.832 79.6726C47.832 97.7003 47.857 99.3255 48.2571 100.001C48.9572 101.176 50.0824 101.551 52.3077 101.351C74.886 99.3755 92.8886 83.6232 95.9641 63.0702C96.3891 60.2198 96.2641 59.4447 95.2139 58.3195L94.4138 57.4694H72.4356C51.6076 57.4694 50.4074 57.4944 49.6073 57.9195Z"
            fill="#C50D6D"
          />
        </svg>

        <div
          className="logo_first_dashes_main"
          style={{
            ...circles[0],
          }}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="117"
              height="105"
              viewBox="0 0 117 105"
              fill="none"
              className="dash1"
            >
              <path
                d="M35.1194 60.5428C25.3012 62.6947 18.1974 64.1169 17.8467 63.9938C16.9814 63.7114 16.5569 62.2093 15.9839 57.4341C15.2714 51.1623 15.4891 49.3439 17.0087 49.2553C18.4579 49.1741 51.4264 53.9933 51.8539 54.3395C52.3785 54.7644 52.5042 55.96 52.0636 56.5041C51.9063 56.6984 44.2768 58.5314 35.1194 60.5428ZM52.0533 51.6697C51.0789 51.3632 22.8096 35.099 22.099 34.4271C21.307 33.657 21.2822 32.5752 22.0394 31.3223C22.4652 30.6375 22.728 30.4321 23.4434 30.3036C24.4401 30.1455 22.744 29.0615 42.7153 42.6601C53.6286 50.0819 53.4574 49.9755 53.3879 50.4983C53.2934 51.2905 52.6576 51.8373 52.0533 51.6697Z"
                fill="white"
              />
              <path
                d="M82.1765 44.6804C91.9947 42.5284 99.0985 41.1062 99.4492 41.2294C100.314 41.5118 100.739 43.0138 101.312 47.7891C102.025 54.0608 101.807 55.8792 100.287 55.9678C98.838 56.0491 65.8695 51.2298 65.442 50.8836C64.9173 50.4588 64.7917 49.2631 65.2323 48.719C65.3896 48.5247 73.0191 46.6917 82.1765 44.6804ZM65.2426 53.5535C66.217 53.8599 94.4863 70.1241 95.1969 70.7961C95.9889 71.5662 96.0137 72.648 95.2565 73.9009C94.8307 74.5856 94.5679 74.791 93.8525 74.9196C92.8558 75.0777 94.5519 76.1616 74.5806 62.563C63.6673 55.1412 63.8385 55.2477 63.908 54.7248C64.0025 53.9327 64.6383 53.3859 65.2426 53.5535Z"
                fill="white"
              />
              <path
                d="M49.2751 11.7651C51.5165 11.1882 56.2866 10.5041 58.5017 10.4577C59.7527 10.4282 60.5378 10.8034 60.8011 11.5005C60.866 11.7282 60.4904 19.6723 59.9593 29.1388C59.1034 43.9515 58.8798 46.4246 58.4342 46.8157C57.7792 47.3781 56.7656 47.2777 56.3182 46.6143C56.1364 46.3449 54.48 41.1285 52.6466 35.0363C50.8196 28.9097 48.6351 21.7273 47.8297 19.0434C46.7521 15.5177 46.4007 13.9451 46.5084 13.3598C46.6047 12.9329 46.7696 12.5201 46.8712 12.4516C46.9727 12.3831 48.042 12.0841 49.2751 11.7651ZM69.9331 11.6744C71.1499 11.6379 72.4674 12.408 72.6787 13.2909C72.8161 13.8014 70.2895 22.8948 64.0468 44.357C63.4357 46.5184 63.0207 47.1301 62.2319 46.9685C61.1687 46.7507 61.2598 45.9654 63.5806 34.5076C64.855 28.1589 66.3714 20.5914 66.9642 17.6581C68.1183 11.9636 68.2313 11.7366 69.9331 11.6744Z"
                fill="white"
              />
              <path
                d="M67.7259 93.8966C65.4845 94.4735 60.7143 95.1575 58.4993 95.2039C57.2483 95.2334 56.4632 94.8582 56.1999 94.1611C56.135 93.9335 56.5106 85.9893 57.0417 76.5229C57.8976 61.7101 58.1211 59.237 58.5667 58.846C59.2218 58.2835 60.2354 58.384 60.6828 59.0473C60.8646 59.3168 62.521 64.5331 64.3543 70.6253C66.1814 76.7519 68.3659 83.9343 69.1713 86.6182C70.2489 90.1439 70.6002 91.7165 70.4926 92.3018C70.3963 92.7287 70.2314 93.1415 70.1298 93.21C70.0282 93.2785 68.959 93.5775 67.7259 93.8966ZM47.0678 93.9872C45.8511 94.0238 44.5336 93.2536 44.3223 92.3707C44.1849 91.8602 46.7114 82.7669 52.9542 61.3046C53.5652 59.1432 53.9802 58.5315 54.7691 58.6931C55.8322 58.911 55.7412 59.6962 53.4204 71.1541C52.146 77.5028 50.6296 85.0702 50.0367 88.0036C48.8826 93.6981 48.7697 93.925 47.0678 93.9872Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="logo_first_dashes_main1"
          style={{
            ...circles[1],
          }}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="117"
              height="105"
              viewBox="0 0 117 105"
              fill="none"
              className="dash1"
            >
              <path
                d="M35.1194 60.5428C25.3012 62.6947 18.1974 64.1169 17.8467 63.9938C16.9814 63.7114 16.5569 62.2093 15.9839 57.4341C15.2714 51.1623 15.4891 49.3439 17.0087 49.2553C18.4579 49.1741 51.4264 53.9933 51.8539 54.3395C52.3785 54.7644 52.5042 55.96 52.0636 56.5041C51.9063 56.6984 44.2768 58.5314 35.1194 60.5428ZM52.0533 51.6697C51.0789 51.3632 22.8096 35.099 22.099 34.4271C21.307 33.657 21.2822 32.5752 22.0394 31.3223C22.4652 30.6375 22.728 30.4321 23.4434 30.3036C24.4401 30.1455 22.744 29.0615 42.7153 42.6601C53.6286 50.0819 53.4574 49.9755 53.3879 50.4983C53.2934 51.2905 52.6576 51.8373 52.0533 51.6697Z"
                fill="white"
              />
              <path
                d="M82.1765 44.6804C91.9947 42.5284 99.0985 41.1062 99.4492 41.2294C100.314 41.5118 100.739 43.0138 101.312 47.7891C102.025 54.0608 101.807 55.8792 100.287 55.9678C98.838 56.0491 65.8695 51.2298 65.442 50.8836C64.9173 50.4588 64.7917 49.2631 65.2323 48.719C65.3896 48.5247 73.0191 46.6917 82.1765 44.6804ZM65.2426 53.5535C66.217 53.8599 94.4863 70.1241 95.1969 70.7961C95.9889 71.5662 96.0137 72.648 95.2565 73.9009C94.8307 74.5856 94.5679 74.791 93.8525 74.9196C92.8558 75.0777 94.5519 76.1616 74.5806 62.563C63.6673 55.1412 63.8385 55.2477 63.908 54.7248C64.0025 53.9327 64.6383 53.3859 65.2426 53.5535Z"
                fill="white"
              />
              <path
                d="M49.2751 11.7651C51.5165 11.1882 56.2866 10.5041 58.5017 10.4577C59.7527 10.4282 60.5378 10.8034 60.8011 11.5005C60.866 11.7282 60.4904 19.6723 59.9593 29.1388C59.1034 43.9515 58.8798 46.4246 58.4342 46.8157C57.7792 47.3781 56.7656 47.2777 56.3182 46.6143C56.1364 46.3449 54.48 41.1285 52.6466 35.0363C50.8196 28.9097 48.6351 21.7273 47.8297 19.0434C46.7521 15.5177 46.4007 13.9451 46.5084 13.3598C46.6047 12.9329 46.7696 12.5201 46.8712 12.4516C46.9727 12.3831 48.042 12.0841 49.2751 11.7651ZM69.9331 11.6744C71.1499 11.6379 72.4674 12.408 72.6787 13.2909C72.8161 13.8014 70.2895 22.8948 64.0468 44.357C63.4357 46.5184 63.0207 47.1301 62.2319 46.9685C61.1687 46.7507 61.2598 45.9654 63.5806 34.5076C64.855 28.1589 66.3714 20.5914 66.9642 17.6581C68.1183 11.9636 68.2313 11.7366 69.9331 11.6744Z"
                fill="white"
              />
              <path
                d="M67.7259 93.8966C65.4845 94.4735 60.7143 95.1575 58.4993 95.2039C57.2483 95.2334 56.4632 94.8582 56.1999 94.1611C56.135 93.9335 56.5106 85.9893 57.0417 76.5229C57.8976 61.7101 58.1211 59.237 58.5667 58.846C59.2218 58.2835 60.2354 58.384 60.6828 59.0473C60.8646 59.3168 62.521 64.5331 64.3543 70.6253C66.1814 76.7519 68.3659 83.9343 69.1713 86.6182C70.2489 90.1439 70.6002 91.7165 70.4926 92.3018C70.3963 92.7287 70.2314 93.1415 70.1298 93.21C70.0282 93.2785 68.959 93.5775 67.7259 93.8966ZM47.0678 93.9872C45.8511 94.0238 44.5336 93.2536 44.3223 92.3707C44.1849 91.8602 46.7114 82.7669 52.9542 61.3046C53.5652 59.1432 53.9802 58.5315 54.7691 58.6931C55.8322 58.911 55.7412 59.6962 53.4204 71.1541C52.146 77.5028 50.6296 85.0702 50.0367 88.0036C48.8826 93.6981 48.7697 93.925 47.0678 93.9872Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      {/* <rect width="49" height="44" fill="white" /> */}
    </div>
  );
};
