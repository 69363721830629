import React from "react";
import { useState } from "react";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";
import { Images } from "../../../Common/Images/Images";

function SimultaneousISRCCode({ close }) {
  const [page_data, setpage_data] = useState({
    song_title_list: 1,
    song_list: [],
  });
  const [page_values, setpage_values] = useState({
    require: "",
  });
  return (
    <>
      <div className="main-thank-you2">
        <div className="inner-thank-you">
          Thank you!
          <div>
            <Images img_var={"like"} />
          </div>
        </div>
        <div className="inner-thank-you3">
          Please select a song or catalog(multiple songs)
        </div>
        <div className="inner_width">
          <CustInput
            custclass="inner-dp"
            className="inner-dp2"
            type="dropdown"
            inp_obj={{
              options: [
                {
                  option_key: 1,
                  option_value: "Song  One",
                },
                {
                  option_key: 2,
                  option_value: "Song ",
                },
                {
                  option_key: 3,
                  option_value: "Song Title One",
                },
              ],
              value: page_data.song_title_list,
              // placeholder: "Select One...",
              on_event: (data) => {
                setpage_data((prev) => ({
                  ...prev,
                  song_title_list: data,
                  song_list: [],
                }));
              },
            }}
          />
        </div>
        <div className="inner-thank-you4">
          Please enter details about your requirement
        </div>
        <CustInput
          custclass="textare_for_modal_copyright"
          type="textarea"
          inp_obj={{
            title: "",
            placeholder: "",
            value: page_values.require,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                require: target.value,
              }));
            },
          }}
        />
        <div className="inner-thank-you4">
          YOU WILL RECEIVE A FOLLOW UP EMAIL WITHIN 2 DAYS. CHECK OUT SOME OF
          OTHER SERVICES HERE.
        </div>

        <Button
          type="type1"
          custclass="Okay-btn"
          btn_obj={{
            txt: "Okay",
            onclick: () => {
              close();
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
      </div>
    </>
  );
}

export default SimultaneousISRCCode;
