import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IndiVidualDocumentPage } from "../../../Pages/Documents";
import { SiganCanva } from "../../Common/SignCanva/SiganCanva";
import { CardDetailAccount } from "../../Pages/Account/CardDetailAccount";
import { CardDetailEditAccount } from "../../Pages/Account/CardDetailEditAccount";
import { TicketModal } from "../../Pages/Account/TicketModal";
import AdminAddService from "../../Pages/Services/Modal/AdminAddServices";
import { AdminEditContractFirstLaert } from "../../Pages/Services/Modal/AdminEditContractFirstLaert";
import AdminEditService from "../../Pages/Services/Modal/AdminEditService";
import { AlertForBasicPlan } from "../../Pages/Services/Modal/AlertForBasicPlan";
import AlertForPremiumPlan from "../../Pages/Services/Modal/AlertForPremiumPlan";
import { CopyRightSubmissionSelectSongAlert } from "../../Pages/Services/Modal/CopyRightSubmissionSelectSongAlert";
import FirstTimeISRCCode from "../../Pages/Services/Modal/FirstTimeISRCCode";
import { ForAllThegeneralServices } from "../../Pages/Services/Modal/ForAllThegeneralServices";
import ServicesPage5 from "../../Pages/Services/Modal/ServicesPage5";
import SimultaneousISRCCode from "../../Pages/Services/Modal/SimultaneousISRCCode";
import { RequireSignature } from "../../Pages/Songs/RequireSignature/RequireSignature";
import { SongColabAuth } from "../../Pages/Songs/SongColabAuth/SongColabAuth";
import { SplitSheet } from "../../Pages/Songs/SplitSheet/SplitSheet";
import Accordian from "../Dropdown/Accordian";
import { MainAccordian } from "../Dropdown/MainAccordian";
import { CreateTicket } from "../../Common/Api/Api";
import { ModalAdver } from "../../Common/ModalForAdd/ModalAdver";
import AddServiceMisc from "../../Pages/Services/Modal/AddServiceMisc";
import AddServiceDropDown from "../../Pages/Services/Modal/AddServiceDropDown";
import AddServiceAdmin from "../../Pages/Services/AddServiceAdmin";
import ISRCModal from "../../Pages/Services/Modal/ISRCModal";

export const Modal = () => {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const handel_close = () => {
    // console.log(current_store, "44444444444444444444444444444444");
    if (
      current_store?.modaldata?.modal_type == "TicketModal" ||
      current_store?.modaldata?.modal_type == "Service_ThankYou"
    ) {
      return window.location.reload();
    }
    // if (current_store?.modaldata?.modal_type == "ForAllThegeneralServices") {
    //   CreateTicket(
    //     current_store?.modaldata?.modal_data?.id,
    //     ({ status, response }) => {
    //       if (status) {
    //         return window.location.reload();
    //       }
    //     }
    //   );
    // }
    dispatch({
      type: "closemodal",
    });
    setTimeout(() => {
      dispatch({
        type: "dropmodal",
      });
    }, 300);
  };
  useEffect(() => {
    return () => {};
  }, [current_store]);
  // console.log(current_store);
  return (
    <div
      className={
        "modal_main" +
        (current_store.modaldata.modal_isopen && !false ? " active" : "")
      }
      id="modal_main"
      onClick={(e) => (e.target.id === "modal_main" ? handel_close() : "")}
    >
      <div className="modal_container">
        {<GetModal modal_data={current_store.modaldata} close={handel_close} />}
      </div>
    </div>
  );
};

export const GetModal = ({ modal_data, close }) => {
  // console.log(modal_data.modal_data.contract);
  // return <ServicesPage5  />
  if (!modal_data?.modal_type) {
    return <div>Modal N/A</div>;
  }
  switch (modal_data.modal_type) {
    case "SongColabAuth":
      return (
        <SongColabAuth
          modal_data={modal_data.modal_data}
          modal_page_data={modal_data.modal_page_data}
          close={close}
        />
      );
    case "RequireSignature":
      return (
        <RequireSignature
          modal_data={modal_data.modal_data}
          modal_page_data={modal_data.modal_page_data}
          close={close}
        />
      );
    case "Advertisnment":
      return <ModalAdver close={close} />;
    case "AddServiceMisc":
      return <AddServiceMisc close={close}  modal_data={modal_data.modal_data}/>;
    case "AddServiceDropDown":
      return <AddServiceDropDown close={close} />;
    case "Service_ThankYou":
      return <ServicesPage5 close={close} />;
    case "FirstTimeIsrcCode":
      return <FirstTimeISRCCode close={close} />;
    case "SimultaneousIsrcCode":
      return <SimultaneousISRCCode close={close} />;
    case "CardDetailFill":
      return <CardDetailAccount close={close} />;
    case "CardDetailEditFill":
      return <CardDetailEditAccount close={close} />;
    case "TicketModal":
      return <TicketModal close={close} inp_obj={modal_data} />;
    case "ISRCModal":
      return <ISRCModal close={close} inp_obj={modal_data}/>;
    case "Accordian":
      return <MainAccordian close={close} />;
    case "AddServiceAdmin":
      return <AddServiceAdmin close={close} />;
    case "SeriviceEdit":
      return (
        <AdminEditService
          close={close}
          adminService={modal_data?.modal_data?.adminService}
        />
      );
    case "SeriviceAdd":
      return (
        <AdminAddService
          close={close}
          datainto={modal_data?.modal_data?.disp?.disp}
        />
      );
    case "AlertBasicPlan":
      return <AlertForBasicPlan />;
    case "AlertPremiumPlan":
      return <AlertForPremiumPlan />;
    case "CopyRightSubmissionSelectSongAlert":
      return <CopyRightSubmissionSelectSongAlert close={close} />;
    case "ForAllThegeneralServices":
      return <ForAllThegeneralServices close={close} />;
    case "SignCanva":
      return <SiganCanva close={close} />;
    case "ListOfDocument":
      return (
        <IndiVidualDocumentPage
          close={close}
          data={modal_data?.modal_data?.data}
        />
      );
    case "SplitSheet":
      return <SplitSheet close={close} />;
    default:
      break;
  }
  return <div>Modal</div>;
};
