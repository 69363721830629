const default_user = {
  token: "",
  admin: false,
  data: { name: "", phone: "" },
};
export const user = {
  ...default_user,
  data: {
    name: "Philip Martin",
    f_name: "Phillip",
    birth_date : "Wed Feb 22 2023 13:55:08 GMT+0530",
    phone: "+14148929343",
    songs_analytics: {
      graph_plot: [
        [0, 10],
        [25, 10],
        [45, 36],
        [65, 15],
        [85, 20],
        [100, 13],
      ],
      count: 23,
      since_last_month: "+10%",
    },
    pending_song_collaboration: 20,
    membershin_ends_in: "15 days",
  },
};

const User = (state = user, { type, payload }) => {
  // console.log('login_reducer', type, payload);
  switch (type) {
    case "login":
      return {
        ...state,
        token: payload.token,
        admin: payload.admin,
        data: { ...payload.data },
        // admin: false,
      };
    case "logout":
      return { ...state, ...default_user };
    default:
      return state;
  }
};
export default User;
