import React from "react";
import { useState } from "react";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";
import { Images } from "../../../Common/Images/Images";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CreateTicket } from "../../../Common/Api/Api";

export const CopyRightSubmissionSelectSongAlert = ({ close }) => {
  const current_store = useSelector((state) => state);
  const [SendId, setSendId] = useState("");
  const [page_data, setpage_data] = useState({
    song_title_list: ["Select One"],
    song_list: [],
    requirement: "",
  });
  const dispatch = useDispatch()
  // console.log(current_store.modaldata.modal_data.id);
  const [InputError, setInputError] = useState({
    song_title_list: false,
    requirement: "",
  });
  // console.log(current_store?.user?.data?.songs);
  useEffect(() => {
    if (!current_store?.user?.data?.songs) return;
    try {
      setpage_data((prev) => ({
        ...prev,
        song_list: [...current_store?.user?.data?.songs],
      }));
      setSendId(current_store.modaldata.modal_data.id);
    } catch (error) {
      // setSampleClearance(false);
    }
  }, [current_store]);
  return (
    <>
      <div className="main-thank-you2">
        <div className="inner-thank-you">
          Thank you!
          <div>
            <Images img_var={"like"} />
          </div>
        </div>
        <div className="inner-thank-you3">
          Please select a song or catalog(multiple songs)
        </div>
        <div className="inner_width">
          <CustInput
            custclass=""
            // className="inner-dp2"
            type="multi-dropSel"
            inp_obj={{
              options: [
                {
                  option_key: "Select One",
                  option_value: "Select One",
                },
                ...page_data?.song_list
                  ?.filter(
                    (el) =>
                      el.splitsheet_created == true &&
                      !el.writers.map((el) => el.authorised).includes(false)
                  )
                  .map((el, i) => ({
                    option_key: el.id,
                    option_value: el.name,
                  })),
              ],
              value: page_data.song_title_list,
              error: InputError.song_title_list,
              // placeholder: "Select One...",
              on_event: (data) => {
                setpage_data((prev) => ({
                  ...prev,
                  song_title_list: data,
                  // song_list: [],
                }));
                setInputError((prev) => ({
                  ...prev,
                  song_title_list: false,
                  // song_list: [],
                }));

              },
            }}
          />
        </div>
        <div className="inner-thank-you4">
          Please enter details about your requirement
        </div>
        <CustInput
          custclass="textare_for_modal_copyright"
          type="textarea"
          inp_obj={{
            title: "",
            placeholder: "",
            value: page_data.requirement,
            // error: InputError.requirement,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_data((prev) => ({
                ...prev,
                requirement: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                requirement: target.value === "",
              }));
            },
          }}
        />
        <div className="inner-thank-you4">
          YOU WILL RECEIVE A FOLLOW UP EMAIL WITHIN 2 DAYS. CHECK OUT SOME OF
          OTHER SERVICES HERE.
        </div>
          {/* {console.log( ["Select One"].includes(...page_data.song_title_list))} */}
        <Button
          type="type1"
          custclass="Okay-btn"
          btn_obj={{
            txt: "Okay",
            onclick: () => {
              if (
                // page_data.requirement == "" ||
                ["Select One"].includes(...page_data.song_title_list) 
                // page_data.requirement === ""
              ) {
                setInputError((prev) => ({
                  ...prev,
                  // requirement: page_data.requirement == "",
                  song_title_list:  ["Select One"].includes(...page_data.song_title_list),
                  // requirement : page_data.requirement == ""
                }));
                return;
              }
              dispatch({
                type: "loading",
              });
              CreateTicket(
                [
                  {
                    requirement: page_data.requirement,
                    song_title_list: page_data?.song_list
                      .filter((el) => page_data.song_title_list.includes(el.id))
                      .map((el, i) => ({
                        option_key: el.id,
                        option_value: el.name,
                      })),
                  },
                ],
                SendId,
                ({ status, response }) => {
                  if (status) {
                    dispatch({
                      type: "loaded",
                    });
                    close();
                  }
                }
              );
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
      </div>
    </>
  );
};
