import React, {
  useEffect,
  useLayoutEffect,
  useState,
  Suspense,
  lazy,
} from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";

// import { Navbar } from "./Components/Navbar/Navbar";

// import { AnimatedRoutes } from "./Utils/AnimatedRoutes";

import { Dashboard } from "./Pages/Dashboard";
// import { Login } from "./Pages/Login";
// import { Account } from "./Pages/Account";
import { Images } from "./Components/Common/Images/Images";
// import { SplitSheet } from "./Pages/SplitSheet";
// import { Songs } from "./Pages/Songs";
import { Documents } from "./Pages/Documents";
// import { ImagesPage } from "./Pages/ImagesPage";
// import Services from "./Pages/Services";
// import { AddUser } from "./Pages/AddUser";
// import EditSong from "./Components/Pages/Songs/SongsPages/EditSong";
import { Membership } from "./Pages/Membership";
import { Modal } from "./Components/Custom/Modal/Modal";
// import { Users } from "./Pages/Users";
import { Loader } from "./Components/Custom/loader/Loader";
// import { Test } from "./Pages/Test";
// import { Tickets } from "./Pages/Tickets";
// import { TestDashboard } from "./Pages/test/Pages/TestDashboard";
// import { TestDcoument } from "./Pages/test/Pages/TestDcoument";
// import { TestMembership } from "./Pages/test/Pages/TestMembership";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import SplitSheet1 from "./Pages/SplitSheet1";

const Navbar = lazy(() => import("./Components/Navbar/Navbar"));
const AnimatedRoutes = lazy(() => import("./Utils/AnimatedRoutes"));
const Login = lazy(() => import("./Pages/Login"));
const TestDashboard = lazy(() => import("./Pages/test/Pages/TestDashboard"));
const Account = lazy(() => import("./Pages/Account"));
const Songs = lazy(() => import("./Pages/Songs"));
// const TestDcoument = lazy(() => import("./Pages/test/Pages/TestDcoument"));
const TestMembership = lazy(() => import("./Pages/test/Pages/TestMembership"));
const EditSong = lazy(() =>
  import("./Components/Pages/Songs/SongsPages/EditSong")
);
const Tickets = lazy(() => import("./Pages/Tickets"));
const Users = lazy(() => import("./Pages/Users"));
const SplitSheet1 = lazy(() => import("./Pages/SplitSheet1"));
const Services = lazy(() => import("./Pages/Services"));
const AddUser = lazy(() => import("./Pages/AddUser"));
const Test = lazy(() => import("./Pages/Test"));
const ImagesPage = lazy(() => import("./Pages/ImagesPage"));
const Miscellaneous = lazy(() => import("./Pages/Miscellaneous"));
const Faq = lazy(() => import("./Pages/Faq"));

function App() {
  const handelnav_active = (nav_state) => {
    setapp_values((prev) => ({
      ...prev,
      nav_active: nav_state,
    }));
  };
  // const current_store = useSelector((state) => state);

  const [app_values, setapp_values] = useState({
    nav_active: false,
  });
  console.log(store);
  const [MoveToAdduser, setMoveToAdduser] = useState(false);
  const [AddSong, setAddSong] = useState(false);
  const routes = [
    {
      path: "/",
      element: <Login handelnav_active={handelnav_active} />,
    },
    {
      path: "/account",
      element: <Account handelnav_active={handelnav_active} />,
    },
    {
      path: "/dashboard",
      element: <TestDashboard handelnav_active={handelnav_active} />,
    },
    // {
    //   path: "/test-dashboard",
    //   element: <TestDashboard handelnav_active={handelnav_active} />,
    // },
    // {
    //   path: "/documents",
    //   element: <TestDcoument handelnav_active={handelnav_active} />,
    // },
    {
      path: "/faq",
      element: <Faq handelnav_active={handelnav_active} />,
    },
    // {
    //   path: "/test-documents",
    //   element: <TestDcoument handelnav_active={handelnav_active} />,
    // },
    {
      path: "/songs",
      element: (
        <Songs
          handelnav_active={handelnav_active}
          MoveToAdduser={MoveToAdduser}
          setMoveToAdduser={setMoveToAdduser}
          AddSong={AddSong}
          setAddSong={setAddSong}
        />
      ),
    },
    {
      path: "/images",
      element: <ImagesPage handelnav_active={handelnav_active} />,
    },
    {
      path: "/services",
      element: <Services handelnav_active={handelnav_active} />,
    },
    {
      path: "/add-user",
      element: (
        <AddUser
          handelnav_active={handelnav_active}
          MoveToAdduser={MoveToAdduser}
          setMoveToAdduser={setMoveToAdduser}
        />
      ),
    },
    {
      path: "/edit-song",
      element: <EditSong handelnav_active={handelnav_active} />,
    },
    {
      path: "/membership",
      element: <TestMembership handelnav_active={handelnav_active} />,
    },
    // {
    //   path: "/test-membership",
    //   element: <TestMembership handelnav_active={handelnav_active} />,
    // },
    {
      path: "/Users",
      element: <Users handelnav_active={handelnav_active} />,
    },
    {
      path: "/Tickets",
      element: <Tickets handelnav_active={handelnav_active} />,
    },
    {
      path: "/miscellaneous",
      element: <Miscellaneous handelnav_active={handelnav_active} />,
    },
    // [
    //   [
    //     "/reports",
    //     navbar_status.reports,
    //     <TempOrders
    //       // <Orders
    //       setdata={setdata}
    //       setview_order={setview_order}
    //       setshow_navbar={setshow_navbar}
    //       setloader={setloader}
    //       type="report"
    //     />,
    //   ],
    // ].map((el) => {
    //   return {
    //     path: el[0],
    //     element: el[1] ? el[2] : <Error />,
    //   };
    // }),
    {
      path: "/splitsheet/:song_id",
      element: <SplitSheet1 setapp_values={setapp_values} />,
    },
    {
      path: "/test",
      element: <Test setapp_values={setapp_values} />,
    },
    {
      path: "/*",
      element: <h1>404</h1>,
    },
  ];

  useLayoutEffect(() => {}, []);

  // console.log(app_values);
  // const location = useLocation();
  // const route = useRoutes(routes, location, location.pathname);

  // let a = {k1 : "v1"}
  // let b ={ ...a}
  // b["k2"] = "v2"
  // console.log(b,a);

  return (
    <>
      {/* <Images img_var={"logo_animated"} cust_class={"page_bg_logo"} /> */}
      <Provider store={store}>
        <Loader />
        <BrowserRouter>
          <Modal />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100vh",
                }}
              >
                <Images img_var={"lazylogo"} />
              </div>
            }
          >
            <Navbar
              nav_active={app_values.nav_active}
              setAddSong={setAddSong}
              setMoveToAdduser={setMoveToAdduser}
            />
            <AnimatedRoutes routes={routes} />
          </Suspense>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
