const page_width_desktop = 1512;
const page_width_mobile = 414;
export const calc_horizontal = (num) => {
  if(window.innerWidth <= 500){
    return `${(num / page_width_mobile) * 100}vw`;
  }
  return `${(num / page_width_desktop) * 100}vw`;
};

// export const calc_horizontal_mobile = (num) => {
//   return `${(num / page_width_mobile) * 100}vw`;
// };
