import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { EditAdminContract } from "../../../Common/Api/Api";
import { Images } from "../../../Common/Images/Images";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";

export const AdminEditContractAlert2 = ({
  close,
  setCurrentpag,
  currentpage,
  data,
  currentpage1,
  currentpageView,
  setcurrentpageView,
}) => {
  const [page_values, setpage_values] = useState({
    name: "",
    upload_doc: "",
    price: "",
    show: "",
    id: "",
  });

  const [FileDocum, setFileDocum] = useState("");
  const [FileName, setFileName] = useState("");
  const [fetch, setFetch] = useState(currentpage1);
  const currentpage34 = true;
  useEffect(() => {
    setpage_values({
      name: currentpage1?.name,
      upload_doc: currentpage1?.link,
      price: currentpage1?.price,
      show: currentpage1?.show,
      id: currentpage1?.id,
    });
  }, [currentpage1]);
  console.log(currentpage1);
  const onSelectFile = (e) => {
    // console.log(e.target.files[0]);
    setFileName(e.target.files[0].name);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setpage_values((prev) => ({
          ...prev,
          upload_doc: reader.result?.toString() || "",
        }));
        e.target.value = null;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <div style={{ pointerEvents: currentpageView ? "none" : "" }}>
        <div className="title_edit_modal">
          {currentpageView ? "View " : "Edit "}
          {currentpage1?.name}
        </div>
        <CustInput
          custclass="innnname"
          type="text"
          inp_obj={{
            title: "Name",
            placeholder: "",
            value: page_values.name,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                name: target.value,
              }));
            },
          }}
        />

        <label htmlFor="tricket_file" style={{ width: "100%" }}>
          {page_values.upload_doc ? (
            <>
              <div className="addcontract_data_e">
                {/* <CustInput
                  custclass="inner_input_admin_service"
                  type="text"
                  inp_obj={{
                    title: "File",
                    placeholder: "",
                    value: page_values.upload_doc,
                    readonly: true,
                    on_event: ({ target }) => {
                      console.log(target.value);
                    },
                  }}
                /> */}
                <div className="box_linkfile">
                  <a
                    href={page_values.upload_doc}
                    target="_blank"
                    className="linkFile"
                  >
                    {page_values.name}
                  </a>
                </div>
                <div className="detete_btn_service_edit">
                  <Images img_var={"delete"} />
                </div>
              </div>
            </>
          ) : (
            <Button
              type="type1"
              custclass="btn_uploaddoc"
              btn_obj={{
                txt: "Upload Document",
                onclick: () => "",
                loading: false,
                show_type: "",
              }}
            ></Button>
          )}

          <input
            type="file"
            className={"add_img_senior"}
            accept=".pdf,.docx,.doc"
            onChange={onSelectFile}
            id="tricket_file"
          />
        </label>

        {/* <CustInput
          custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Upload Document",
            placeholder: "",
            value: currentpage34
              ? currentpage1?.upload_doc
              : page_values.upload_doc,
            on_event: ({ target }) => {
              console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                upload_doc: target.value,
              }));
            },
          }}
        /> */}
        <div className="handler_shower_in_modal">
          <CustInput
            custclass="inner_handler"
            type="text"
            inp_obj={{
              title: "Price",
              placeholder: "",
              value: page_values.price,
              on_event: ({ target }) => {
                // console.log(target.value);
                setpage_values((prev) => ({
                  ...prev,
                  price: target.value,
                }));
              },
            }}
          />
          <div className="inner-input_modal1">
            <div className="tag">Status</div>
            <CustInput
              custclass="inuit"
              type="checkbox"
              inp_obj={{
                title: "Show",
                placeholder: "",
                value: page_values.show,
                on_event: ({ target }) => {
                  // console.log(target.value);
                  setpage_values((prev) => ({
                    ...prev,
                    show: !prev.show,
                  }));
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="btn_handler_inedit_modal">
        {currentpageView ? (
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Back",
              onclick: () => {
                setCurrentpag(!currentpage);
                setcurrentpageView(!currentpageView);
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        ) : (
          <>
            <Button
              type="type1"
              custclass="btn2"
              btn_obj={{
                txt: "Cancel",
                onclick: () => {
                  setCurrentpag(!currentpage);
                },
                loading: false,
                show_type: "",
              }}
            ></Button>
            <Button
              type="type1"
              custclass="btn2"
              btn_obj={{
                txt: "Save",
                onclick: () => {
                  EditAdminContract(page_values, ({ status, response }) => {
                    if (status) {
                      window.location.reload();
                    }
                  });
                },
                loading: false,
                show_type: "",
              }}
            ></Button>
          </>
        )}
      </div>
    </>
  );
};
