import axios from "axios";
import { useDispatch } from "react-redux";
import { utils } from "../../../Utilities";

axios.defaults.withCredentials = true;
// const url = "a0f4-103-44-49-168.in.ngrok.io";
// const base_url = `https://${url}/api/v1/mmp/`;
// const base_url = `http://localhost:3001/api/v1/mmp/`;
// const base_url = Utilities();
// const base_url = `http://15.207.123.95:3001/api/v1/mmp/`;

const { base_url } = utils;

const api = {
  sendOtp: "send-otp",
  verifyOtp: "verify-otp",
  saveUser: "user/new-user",
  checkToken: "check-token",
  addSong: "user/add-song",
  editSong: "user/edit-song",
  AdmineditSong: "admin/edit-song",
  findwriter: "user/find-writer",
  Adminfindwriter: "admin/find-writer",
  editSongWriter: "user/edit-song-writers",
  editAdminSongWriter: "admin/edit-song-writers",
  deleteSong: "user/song-delete",
  deleteSongAdmin: "admin/song-delete",
  songAuth: "user/edit-song-auth",
  songSplitSheet: "user/get-song-splitsheet",
  songSplitSheetAdmin: "admin/get-song-splitsheet",
  editUser: "user/edit-user",
  editUserAdmin: "admin/edit-user",
  clearCheckToken: "user/clear-token",
  addUser: "user/add-new-user",
  servicesMain: "user/get-services",
  create_ticket: "user/create-ticket",
  admin_getService: "admin/get-services",
  admin_editService: "admin/edit-services",
  admin_editServiceapp: "admin/edit-inapp-services",
  admin_addService: "admin/add-services",
  admin_get_ticket: "admin/get-tickets",
  miscellaneous_get: "admin/get-miscellaneous",
  miscellaneous_get_user: "user/get-miscellaneous",
  miscellaneous_add: "admin/add-miscellaneous",
  miscellaneous_edit: "admin/edit-miscellaneous",
  service_miscellaneous_add: "admin/add-miscellaneous-service",
  img_toLink: "base64-to-link",
  memberShip: "user/membership-upgrade",
  def_img: "https://picsum.photos/400/400",
  card_detail: "user/add-card",
  memberShipCancel: "user/membership-cancel",
  edit_ticket: "admin/edit-tickets",
  edit_creator: "admin/edit-creator",
  admin_getContract: "admin/get-contracts",
  Add_contract: "admin/add-contracts",
  delete_contract: "admin/delete-contracts",
  edit_contract_admin: "admin/edit-contracts",
  user_getcontract: "user/get-contracts",
  purchase_contract: "user/buy-contracts",
  pendingTicket: "admin/pending-tickets",
  googlelogin: "google-login",
  add: "admin/add-add",
  edit_ticket_status: "admin/edit-tickets-status",
  edit_misc_status: "admin/edit-miscellaneous-status",
  getpdfsplitsheet: "user/send-mail-to-writers",
};

export const sendOtp = (email, callback) => {
  axios
    .post(`${base_url}${api.sendOtp}`, { email }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }

      return callback({
        status: true,
        response,
      });
    });
};

export const verifyotp = (email, otp, callback) => {
  axios
    .post(`${base_url}${api.verifyOtp}`, { email, otp }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
          response: response,
        });
      }

      return callback({
        status: true,
        response: response,
      });
    });
};

export const ImageToLink = (base_64, to, callback) => {
  axios
    .post(`${base_url}${api.img_toLink}`, { base_64, to }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
          response: response,
        });
      }

      return callback({
        status: true,
        response: response,
      });
    });
};
// fi@b.com
export const saveuser = (login_data, callback) => {
  // console.log(login_data);
  // console.log(  {
  //   // photo: login_data.photo,
  //   photo: login_data.image,
  //   email: login_data.email,
  //   name: login_data.name,
  //   phone: login_data.phone,
  //   address: login_data.address,
  //   has_publisher: login_data.have_publisher,
  //   publisher: {
  //     resiter_with_BmiOrAscap: login_data.publisher.resiter_with_BmiOrAscap,
  //     name: login_data.publisher.name,
  //     email: login_data.publisher.email,
  //     ipi_no: login_data.publisher.ipi_number,
  //   },
  //   recording_org:
  //     login_data.organization == "Others"
  //       ? login_data.organization2
  //       : login_data.organization,
  //   birth_date: login_data.birthdate,
  //   ssn: login_data.last_four_social_security_number,
  //   // signature: login_data.signature,
  //   signature: login_data.signimg,
  // },);
  // return
  {
    login_data.photo.includes("amazonaws")
      ? axios
          .post(
            `${base_url}${api.saveUser}`,
            {
              // photo: login_data.photo,
              photo: login_data.photo,
              email: login_data.email,
              name: login_data.name,
              phone: (login_data.phone + "").split(" ").splice(1).join(""),
              address: login_data.address.join("%mmpadd%"),
              has_publisher: login_data.have_publisher,
              publisher: {
                resiter_with_BmiOrAscap:
                  login_data.publisher.resiter_with_BmiOrAscap,
                name: login_data.publisher.name,
                email: login_data.publisher.email,
                ipi_no: login_data.publisher.ipi_number,
              },
              recording_org:
                login_data.organization == "Others"
                  ? login_data.organization2
                  : login_data.organization,
              birth_date: login_data.birthdate,
              ssn: login_data.last_four_social_security_number
                .split("-")
                .join(""),
              // signature: login_data.signature,
              signature: login_data.signimg,
            },
            {
              headers: {
                authorization: login_data.token,
              },
            }
          )
          // kachra
          .then((response) => response.data)
          // kachra filter
          .then((response) => {
            if (response.err) {
              return callback({
                status: false,
              });
            }
            return callback({
              status: true,
              response,
            });
          })
      : ImageToLink(login_data.photo, "profileimages", (response) => {
          axios
            .post(
              `${base_url}${api.saveUser}`,
              {
                // photo: login_data.photo,
                photo: response.response.data,
                email: login_data.email,
                name: login_data.name,
                phone: login_data.phone.split(" ").splice(1).join(""),
                address: login_data.address.join("%mmpadd%"),
                has_publisher: login_data.have_publisher,
                publisher: {
                  resiter_with_BmiOrAscap:
                    login_data.publisher.resiter_with_BmiOrAscap,
                  name: login_data.publisher.name,
                  email: login_data.publisher.email,
                  ipi_no: login_data.publisher.ipi_number,
                },
                recording_org:
                  login_data.organization == "Others"
                    ? login_data.organization2
                    : login_data.organization,
                birth_date: login_data.birthdate,
                ssn: login_data.last_four_social_security_number
                  .split("-")
                  .join(""),
                // signature: login_data.signature,
                signature: login_data.signimg,
              },
              {
                headers: {
                  authorization: login_data.token,
                },
              }
            )
            // kachra
            .then((response) => response.data)
            // kachra filter
            .then((response) => {
              if (response.err) {
                return callback({
                  status: false,
                });
              }
              return callback({
                status: true,
                response,
              });
            });
          // catch banana he
        });
  }
};
export const checktoken = (callback) => {
  // console.log("work");
  axios
    .post(`${base_url}user/${api.checkToken}`)
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }

      return callback({
        status: true,
        response,
      });
    });
};
export const pendingticket = (data, callback) => {
  // console.log("work");
  axios
    .post(`${base_url}${api.pendingTicket}`, {
      id: data.id,
      pending: data.pending,
      data: {
        document: data.document,
      },
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response,
      });
    });
};
export const googleLogin = (idToken, callback) => {

  console.log(idToken);
  // return
  axios
    .post(`${base_url}${api.googlelogin}`, {
      google_token:idToken,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response,
      });
    });
};
export const checkadmintoken = (callback) => {
  // console.log("work");
  axios
    .post(`${base_url}admin/${api.checkToken}`)
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      console.log(response);
      return callback({
        status: true,
        response1: response,
      });
    });
};

export const cancelSub = (callback) => {
  // console.log("work");
  axios
    .post(`${base_url}${api.memberShipCancel}`)
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response1: response,
      });
    });
};

export const addsong = (page_values, callback) => {
  // ImageToLink(page_values.photo, "songimages", (response) => {
  const [SongPhoto, SongAudio, songSample] = [
    axios
      .post(
        `${base_url}${api.img_toLink}`,
        { base_64: page_values.photo, to: "songimages" },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return response.error;
        }
        return response;
      }),
    axios
      .post(
        `${base_url}${api.img_toLink}`,
        { base_64: page_values.audio_update, to: "songaudio" },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return response.error;
        }

        return response;
      }),
    axios
      .post(
        `${base_url}${api.img_toLink}`,
        { base_64: page_values?.audio_sample_update, to: "songsampleaudio" },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return response.error;
        }

        return response;
      }),
  ];
  Promise.all([SongPhoto, SongAudio, songSample]).then((values) => {
    // console.log(page_values);
    // return
    axios
      .post(`${base_url}${api.addSong}`, {
        photo: values[0].data,
        name: page_values.name_song,
        alternate_name: page_values.alternate_name,
        value: page_values.work_include_sample,
        release_date: page_values.releasedate_intendeddate,
        options: [
          page_values.work_film_product,
          page_values.work_public_domain_element,
          page_values.work_been_recorded,
          page_values.work_include_sample,
          page_values.work_concert,
          // page_values.recorded,
        ],
        generate_isrc: page_values.isrc_code_tick,
        record_label: page_values.record_label,
        ISRC_code: page_values.isrc_code,
        audio: values[1].data,
        sample_audio: values[2]?.data,
        creators_percentage: page_values.creater_percentage,
        original: page_values.original,
        public_domain: page_values.public_domain_title,
        recording_artist_name: page_values.record_artist_name,
        writers: [...page_values.writers],
      })
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
          });
        }
        return callback({
          status: true,
          response: response,
        });
      });
  });
  // });
};

export const editsong = (page_values, is_admin, callback) => {
  // console.log(page_values);
  // return
  // ImageToLink(page_values.photo, "songimages", (response) => {

  const [SongPhoto, SongAudio, songSample] = [
    page_values.photo.includes("amazonaws")
      ? page_values.photo
      : axios
          .post(
            `${base_url}${api.img_toLink}`,
            { base_64: page_values.photo, to: "songimages" },
            {}
          )
          .then((response) => response.data)
          .then((response) => {
            if (response.err) {
              return response.error;
            }
            return response;
          }),

    page_values.audio_update.includes("amazonaws")
      ? page_values.audio_update
      : axios
          .post(
            `${base_url}${api.img_toLink}`,
            { base_64: page_values.audio_update, to: "songaudio" },
            {}
          )
          .then((response) => response.data)
          .then((response) => {
            if (response.err) {
              return response.error;
            }

            return response;
          }),
    page_values.audio_sample_update.includes("amazonaws")
      ? page_values.audio_sample_update
      : axios
          .post(
            `${base_url}${api.img_toLink}`,
            {
              base_64: page_values?.audio_sample_update,
              to: "songsampleaudio",
            },
            {}
          )
          .then((response) => response.data)
          .then((response) => {
            if (response.err) {
              return response.error;
            }

            return response;
          }),
  ];
  Promise.all([SongPhoto, SongAudio, songSample]).then((values) => {
    axios
      .post(`${base_url}${is_admin ? api.AdmineditSong : api.editSong}`, {
        photo: page_values.photo.includes("amazonaws")
          ? page_values.photo
          : values[0].data,
        name: page_values.name_song,
        alternate_name: page_values.alternate_name,
        value: page_values.work_include_sample,
        release_date: page_values.releasedate_intendeddate,
        options: [
          page_values.work_film_product,
          page_values.work_public_domain_element,
          page_values.work_been_recorded,
          page_values.work_include_sample,
          page_values.work_concert,
          // page_values.recorded,
        ],
        record_label: page_values.record_label,
        ISRC_code: page_values.isrc_code,
        audio: page_values.audio_update.includes("amazonaws")
          ? page_values.audio_update
          : values[1].data,
        sample_audio: page_values.audio_sample_update.includes("amazonaws")
          ? page_values.audio_sample_update
          : values[2]?.data,
        creators_percentage: page_values.creater_percentage,
        original: page_values.original,
        public_domain: page_values.public_domain_title,
        recording_artist_name: page_values.record_artist_name,
        song_id: page_values.song_id,
      })
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
          });
        }
        return callback({
          status: true,
          response: response,
        });
      });
  });
};

export const findwriter = (user_id, is_admin, callback) => {
  // console.log(is_admin);
  axios
    .post(`${base_url}${is_admin ? api.Adminfindwriter : api.findwriter}`, {
      search: user_id,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const GetTicket = (callback) => {
  axios
    .post(`${base_url}${api.admin_get_ticket}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const GetUserContract = (callback) => {
  axios
    .post(`${base_url}${api.user_getcontract}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const EditTicket = (data, callback) => {
  // const [Document, DocumentSample] = [
  //   axios
  //     .post(
  //       `${base_url}${api.img_toLink}`,
  //       { base_64:data.document , to: "document" },
  //       {}
  //     )
  //     .then((response) => response.data)
  //     .then((response) => {
  //       if (response.err) {
  //         return response.error;
  //       }
  //       return response;
  //     }),
  //   axios
  //     .post(
  //       `${base_url}${api.img_toLink}`,
  //       { base_64:data.sampledoc, to: "sampledocument" },
  //       {}
  //     )
  //     .then((response) => response.data)
  //     .then((response) => {
  //       if (response.err) {
  //         return response.error;
  //       }

  //       return response;
  //     }),

  // ];
  // Promise.all([Document, DocumentSample]).then((values) => {
  axios
    .post(`${base_url}${api.edit_ticket}`, {
      id: data.id,
      data: {
        isrc_code: data.isrc_code,
      },
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
  // });
};
export const EditTicketStatus = (data, callback) => {
  axios
    .post(`${base_url}${api.edit_ticket_status}`, {
      id: data.id,
      type: data.type,
      data: {
        isrc: data.isrc_code,
      },
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
  // });
};
export const EditMiscStatus = (data, callback) => {
  axios
    .post(`${base_url}${api.edit_misc_status}`, {
      ...data,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
  // });
};
export const purchaseContract = (data, callback) => {
  //  console.log(data);
  //   return
  axios
    .post(`${base_url}${api.purchase_contract}`, {
      contract_list: data,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
  // });
};
export const AddAdminContract = (page_values, callback) => {
  const [Document, DocumentSample] = [
    axios
      .post(
        `${base_url}${api.img_toLink}`,
        { base_64: page_values.upload_doc, to: "document" },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return response.error;
        }
        return response;
      }),
    axios
      .post(
        `${base_url}${api.img_toLink}`,
        { base_64: page_values.uploadSample_doc, to: "sampledocument" },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return response.error;
        }

        return response;
      }),
  ];
  Promise.all([Document, DocumentSample]).then((values) => {
    // console.log(values);
    // return
    axios
      .post(`${base_url}${api.Add_contract}`, {
        name: page_values.name,
        doc_base64: values[0].data,
        doc_sample: values[1].data,
        price: page_values.price,
      })
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
          });
        }
        return callback({
          status: true,
          response: response,
        });
      });
  });
};
export const EditAdminContract = (page_values, callback) => {
  ImageToLink(page_values.upload_doc, "contracts", (response) => {
    axios
      .post(`${base_url}${api.edit_contract_admin}`, {
        id: page_values.id,
        name: page_values.name,
        doc_base64: response.response.data,
        doc_sample: response.response.data,
        price: page_values.price,
      })
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
          });
        }
        return callback({
          status: true,
          response: response,
        });
      });
  });
};
export const DeleteAdminContract = (id, callback) => {
  axios
    .post(`${base_url}${api.delete_contract}`, {
      id: id,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const MemberShip = (data, callback) => {
  // console.log(callback);
  // console.log(data);
  // return
  axios
    .post(`${base_url}${api.memberShip}`, {
      type: data.type,
      duration: data.duration,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
          response: response,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const CardDetail = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.card_detail}`, {
      card_number: page_values.card_number.split(" ").join(""),
      card_expiry_month: page_values.card_expiry_month,
      card_expiry_year: page_values.card_expiry_year,
      card_cvc: page_values.card_cvc,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const editsongwriter = ({ id, writers_array }, is_admin, callback) => {
  // console.log(writers_array);
  // return
  axios
    .post(
      `${base_url}${is_admin ? api.editAdminSongWriter : api.editSongWriter}`,
      {
        song_id: id,
        writers: [
          ...writers_array,
          // {
          //   user_id:page_values.user_id ,
          //   percentage:page_values.percentage,
          //   role: percentage.role,
          // },
        ],
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};

export const deletesong = (id, is_admin, callback) => {
  axios
    .post(`${base_url}${is_admin ? api.deleteSongAdmin : api.deleteSong}`, {
      song_id: id,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};

export const Songauth = ({ id, auth }, callback) => {
  axios
    .post(`${base_url}${api.songAuth}`, {
      song_id: id,
      auth: auth,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};

export const SongSplitSheet = (id, callback) => {
  axios
    .post(`${base_url}${api.songSplitSheet}`, {
      song_id: id,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
          response: response.err,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const SongSplitSheetAdmin = (id, callback) => {
  axios
    .post(`${base_url}${api.songSplitSheetAdmin}`, {
      song_id: id,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
          response: response.err,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};

export const GetSplitSheetPdf = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.getpdfsplitsheet}`, {
      emails: page_values.email,
      html: page_values.html,
      song_name: page_values.songname,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};

export const ClearCheckToken = (callback) => {
  axios
    .post(`${base_url}${api.clearCheckToken}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const GetServices = (callback) => {
  axios
    .post(`${base_url}${api.servicesMain}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminGetContract = (callback) => {
  axios
    .post(`${base_url}${api.admin_getContract}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminGetServices = (callback) => {
  axios
    .post(`${base_url}${api.admin_getService}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminGetmiscellaneous = (callback) => {
  axios
    .post(`${base_url}${api.miscellaneous_get}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminGetmiscellaneousUser = (callback) => {
  axios
    .post(`${base_url}${api.miscellaneous_get_user}`, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
// id, name, description, price, price_txt, show
export const AdminEditServices = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.admin_editService}`, {
      id: page_values.service_id,
      name: page_values.service_name,
      description: page_values.service_desc,
      price: page_values.service_price,
      price_txt: page_values.state_name,
      show: page_values.show,
      membership_type: page_values.membership_type,
      link: page_values.link,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminEditServicesApp = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.admin_editServiceapp}`, {
      id: page_values.service_id,
      name: page_values.service_name,
      description: page_values.service_desc,
      price: page_values.service_price,
      price_txt: page_values.state_name,
      show: page_values.show,
      membership_type: page_values.membership_type,
      link: page_values.link,
      min_fees: page_values.min_fees,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminAddServices = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.admin_addService}`, {
      id: page_values.service_id,
      name: page_values.service_name,
      description: page_values.service_desc,
      price: page_values.service_price,
      price_txt: page_values.state_name,
      show: page_values.show,
      membership_type: page_values.membership_type,
      link: page_values.link,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminMiscServicesAdd = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.service_miscellaneous_add}`, {
      name: page_values.name,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminAddMisc = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.miscellaneous_add}`, {
      name: page_values.name,
      business_name: page_values.businessname,
      service_type: page_values?.page_data?.user_filter_song,
      phone: page_values.phone_number.split(" ").splice(1).join(""),
      link: page_values.link,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
export const AdminEditMisc = (page_values, callback) => {
  // console.log(page_values);
  // return
  axios
    .post(`${base_url}${api.miscellaneous_edit}`, {
      id: page_values.modal_data.id,
      name: page_values.name,
      business_name: page_values.businessname,
      service_type: page_values?.page_data?.user_filter_song,
      phone: page_values.phone_number.split(" ").splice(1).join(""),
      link: page_values.link,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};
// export const ClearCheckTokenAdmin = (callback) => {
//   axios
//     .post(`${base_url}${api.clearCheckToken}`, {})
//     .then((response) => response.data)
//     .then((response) => {
//       if (response.err) {
//         return callback({
//           status: false,
//         });
//       }
//       return callback({
//         status: true,
//         response: response,
//       });
//     });
// };

export const editAccount = (page_values, callback) => {
  // console.log(page_values);
  // return
  page_values.image.includes("amazonaws")
    ? axios
        .post(`${base_url}${api.editUser}`, {
          photo: page_values.image,
          name: page_values.name_person,
          phone: page_values.phone_number.split(" ").splice(1).join(""),
          address: page_values.address.join("%mmpadd%"),
          has_publisher: page_values.have_publisher,
          publisher: {
            publisher_register: page_values.publisher.resiter_with_BmiOrAscap,
            name: page_values.publisher.name,
            email: page_values.publisher.email,
            ipi_no: page_values.publisher.ipi_number,
          },
          recording_org:
            page_values.organization == "Others"
              ? page_values.organization2
              : page_values.organization,
          birth_date: page_values.birth_date,
          ssn: page_values.social_security_number.split("-").join(""),
          signature: page_values.signature,
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.err) {
            return callback({
              status: false,
            });
          }
          return callback({
            status: true,
            response: response,
          });
        })
    : ImageToLink(page_values.image, "profileimages", (response) => {
        axios
          .post(`${base_url}${api.editUser}`, {
            photo: response.response.data,
            name: page_values.name_person,
            phone: page_values.phone_number.split(" ").splice(1).join(""),
            address: page_values.address.join("%mmpadd%"),
            has_publisher: page_values.have_publisher,
            publisher: {
              publisher_register: page_values.publisher.resiter_with_BmiOrAscap,
              name: page_values.publisher.name,
              email: page_values.publisher.email,
              ipi_no: page_values.publisher.ipi_number,
            },
            recording_org:
              page_values.organization == "Others"
                ? page_values.organization2
                : page_values.organization,
            birth_date: page_values.birth_date,
            ssn: page_values.social_security_number.split("-").join(""),
            signature: page_values.signature,
          })
          .then((response) => response.data)
          .then((response) => {
            if (response.err) {
              return callback({
                status: false,
              });
            }
            return callback({
              status: true,
              response: response,
            });
          });
      });
};

export const Advertisnment = (photo, callback) => {
  // console.log(page_values);
  // return
  ImageToLink(photo, "advertisnment", (response) => {
    axios
      .post(`${base_url}${api.add}`, {
        photo: response.response.data,
      })
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
          });
        }
        return callback({
          status: true,
          response: response,
        });
      });
  });
};
export const editCreator = (page_values, callback) => {
  // console.log(page_values);
  // console.log(page_values.image.includes("amazonaws"));
  // return
  page_values.image.includes("amazonaws")
    ? axios
        .post(`${base_url}${api.edit_creator}`, {
          photo: page_values.image,
          name: page_values.name_person,
          phone: page_values.phone_number.split(" ").splice(1).join(""),
          address: page_values.address.join("%mmpadd%"),
          has_publisher: page_values.have_publisher,
          publisher: {
            publisher_register: page_values.publisher.resiter_with_BmiOrAscap,
            name: page_values.publisher.name,
            email: page_values.publisher.email,
            ipi_no: page_values.publisher.ipi_number,
          },
          recording_org:
            page_values.organization == "Others"
              ? page_values.organization2
              : page_values.organization,
          birth_date: page_values.birth_date,
          ssn: page_values.social_security_number.split("-").join(""),
          id: page_values.id,
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.err) {
            return callback({
              status: false,
            });
          }
          return callback({
            status: true,
            response: response,
          });
        })
    : ImageToLink(page_values.image, "profileimages", (response) => {
        axios
          .post(`${base_url}${api.edit_creator}`, {
            photo: response.response.data,
            name: page_values.name_person,
            phone: page_values.phone_number.split(" ").splice(1).join(""),
            address: page_values.address.join("%mmpadd%"),
            has_publisher: page_values.have_publisher,
            publisher: {
              publisher_register: page_values.publisher.resiter_with_BmiOrAscap,
              name: page_values.publisher.name,
              email: page_values.publisher.email,
              ipi_no: page_values.publisher.ipi_number,
            },
            recording_org:
              page_values.organization == "Others"
                ? page_values.organization2
                : page_values.organization,
            birth_date: page_values.birth_date,
            ssn: page_values.social_security_number.split("-").join(""),
            id: page_values.id,
          })
          .then((response) => response.data)
          .then((response) => {
            if (response.err) {
              return callback({
                status: false,
              });
            }
            return callback({
              status: true,
              response: response,
            });
          });
      });
};
export const editAdminAccount = (page_values, callback) => {
  // console.log(page_values);
  // return
  page_values.image.includes("amazonaws")
    ? axios
        .post(`${base_url}${api.editUserAdmin}`, {
          photo: page_values.image,
          name: page_values.name_person,
          phone: page_values.phone_number.split(" ").splice(1).join(""),
          address: page_values.address.join("%mmpadd%"),
          has_publisher: page_values.have_publisher,
          publisher: {
            publisher_register: page_values.publisher.resiter_with_BmiOrAscap,
            name: page_values.publisher.name,
            email: page_values.publisher.email,
            ipi_no: page_values.publisher.ipi_number,
          },
          recording_org:
            page_values.organization == "Others"
              ? page_values.organization2
              : page_values.organization,
          birth_date: page_values.birth_date,
          ssn: page_values.social_security_number.split("-").join(""),
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.err) {
            return callback({
              status: false,
            });
          }
          return callback({
            status: true,
            response: response,
          });
        })
    : ImageToLink(page_values.image, "profileimages", (response) => {
        axios
          .post(`${base_url}${api.editUserAdmin}`, {
            photo: response.response.data,
            name: page_values.name_person,
            phone: page_values.phone_number.split(" ").splice(1).join(""),
            address: page_values.address.join("%mmpadd%"),
            has_publisher: page_values.have_publisher,
            publisher: {
              publisher_register: page_values.publisher.resiter_with_BmiOrAscap,
              name: page_values.publisher.name,
              email: page_values.publisher.email,
              ipi_no: page_values.publisher.ipi_number,
            },
            recording_org:
              page_values.organization == "Others"
                ? page_values.organization2
                : page_values.organization,
            birth_date: page_values.birth_date,
            ssn: page_values.social_security_number.split("-").join(""),
            signature: page_values.signature,
          })
          .then((response) => response.data)
          .then((response) => {
            if (response.err) {
              return callback({
                status: false,
              });
            }
            return callback({
              status: true,
              response: response,
            });
          });
      });
};

export const Add_User = (page_values, callback) => {
  ImageToLink(page_values.image, "profileimages", (response) => {
    axios
      .post(`${base_url}${api.addUser}`, {
        photo: response.response.data,
        name: page_values.name_person,
        phone: page_values.phone_number.split(" ").splice(1).join(""),
        address: page_values.address.join("%mmpadd%"),
        email: page_values.email_address,
      })
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
          });
        }

        return callback({
          status: true,
          response,
        });
      });
  });
};

export const CreateTicket = (data, id, callback) => {
  // console.log(data ,id );
  // return
  axios
    .post(`${base_url}${api.create_ticket}`, {
      id: id,
      data: data,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response: response,
      });
    });
};

export const get_random_image = (cb) => {
  fetch("https://picsum.photos/183/157")
    .then((res) => res.blob())
    .then((blob) => {
      // Read the Blob as DataURL using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        cb(reader.result);
      };
      reader.readAsDataURL(blob);
    });
};

// linkimg to base64

export const linktoBase64 = (link, cb) => {
  // console.log(link);
  fetch(link)
    // fetch("https://cdn.pixabay.com/photo/2015/04/23/21/59/tree-736877_150.jpg")
    .then((res) => res.blob())
    .then((blob) => {
      // Read the Blob as DataURL using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        cb(reader.result);
      };
      reader.readAsDataURL(blob);
    });
};
//https://pixabay.com/api/?key=33895625-4aa476326b6b911825874fb38&q=horse&image_type=photo

// export const get_file_size = async (file_link_arr) => {
//   var requestOptions = {
//     method: 'GET',
//     redirect: 'follow'
//   };

//   fetch(file_link_arr, requestOptions)
//     .then(response => response.text())
//     .then(result => console.log(result))
//     .catch(error => console.log('error', error));
//   // return axios
//   //     (file_link_arr , {method:"GET",mode: 'no-cors'})
//   //     .then(async (responce) => {
//   //       console.log(
//   //         "file in bytes ",
//   //         responce.headers["content-length"],
//   //         "of ",
//   //         file_link_arr

//   //       );
//   //       return {
//   //         link: file_link_arr,
//   //         size: responce.headers["content-length"],
//   //       };
//   //     })
//   //     .catch((err) => console.log("size ret6rieve err", err));
//   // let file_size_promise = file_link_arr.map((el) => {
//   //   return axios
//   //     .get(el)
//   //     .then(async (responce) => {
//   //       console.log(
//   //         "file in bytes ",
//   //         responce.headers["content-length"],
//   //         "of ",
//   //         el
//   //       );
//   //       return {
//   //         link: el,
//   //         size: responce.headers["content-length"],
//   //       };
//   //     })
//   //     .catch((err) => console.log("size ret6rieve err", err));
//   // });
//   // return Promise.all(file_size_promise)
//   //   .then((data) => {
//   //     console.log("promise all then");
//   //     return data;
//   //   })
//   //   .catch((data) => {
//   //     console.log("promise all catch");
//   //     return data;
//   //   });

// };

export const get_serch_img = (srchData, callback) => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "__cf_bm=vRS03Nx71qHIrVFgg7b_TcJlFc1JA00vUCOAUCMyeyw-1677749120-0-AREQ9BShuhAXh1BI7YsqylDfuOwScTz6weS9iyX2U9ou4dho6BgsbFg26dC9TfhDWiJcy2gfxtG0un8jhPSFxlg=; anonymous_user_id=None; csrftoken=fybqPLsuJDxfxqLQ9fgsKnp69ogfYn0OB0KNDyVMEQ1xCJwMGZfNziCwyOUrjHXB"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    `https://pixabay.com/api/?key=33895625-4aa476326b6b911825874fb38&q=${srchData}&image_type=photo`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) =>
      callback({
        status: true,
        response: JSON.parse(result).hits.map((el) => el.previewURL),
      })
    )
    .catch((error) =>
      callback({
        status: false,
      })
    );

  return;
  var config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://pixabay.com/api/?key=33895625-4aa476326b6b911825874fb38&q=horse&image_type=photo",
    headers: {
      Cookie:
        "__cf_bm=vRS03Nx71qHIrVFgg7b_TcJlFc1JA00vUCOAUCMyeyw-1677749120-0-AREQ9BShuhAXh1BI7YsqylDfuOwScTz6weS9iyX2U9ou4dho6BgsbFg26dC9TfhDWiJcy2gfxtG0un8jhPSFxlg=; anonymous_user_id=None; csrftoken=fybqPLsuJDxfxqLQ9fgsKnp69ogfYn0OB0KNDyVMEQ1xCJwMGZfNziCwyOUrjHXB",
    },
  };

  axios(config)
    .then(function (response) {
      // console.log("searchimg", JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log("searchimg", error);
    });
  // axios
  //   .get(
  //     `https://pixabay.com/api/?key=33895625-4aa476326b6b911825874fb38&q=${srchData}&image_type=photo`
  //   )
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
};

// ToDoWriter.reduce((prev,current)=>{current+=prev.percentage},0)
