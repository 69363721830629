import React, { useState } from "react";
import { Images } from "../Images/Images";

export const PremiumUltra = ({ inp_obj }) => {
  const Data = {
    plan_name: "",
    plan_amount: "",
    plan_type: "",
    selected_plan: "",
    current_selected : "",
  };
  // const [Check, setCheck] = useState(true)
  return (
    <>
      <div className="premium_ultra"   onClick={()=>{
          inp_obj.setCheck(inp_obj.plan_name)
        }} >
        {(inp_obj.plan_name || Data.plan_name) && (
          <div className="premium_ultra_title">
            {inp_obj.plan_name || Data.plan_name}
          </div>
        )}
        {(inp_obj.plan_amount || Data.plan_amount) && (
          <div className="premium_ultra_price">
            {inp_obj.plan_amount || Data.plan_amount}/
          </div>
        )}
        {(inp_obj.plan_type || Data.plan_type) && (
          <div className="premium_ultra_price_type">
            {inp_obj.plan_type || Data.plan_type}
          </div>
        )}
        {/* <input type="radio"  style={{display : "none"}}  id={Data.plan_name} name="fav_language" value="HTML"></input> */}
        <div className="cirlce_img_tick">
          <div >
          {inp_obj.Check === inp_obj.plan_name? <Images img_var="right" /> : ""}
          </div>
        </div>
      </div>
    </>
  );
};
