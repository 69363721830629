import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cancelSub, MemberShip } from "../../Common/Api/Api";
import { Images } from "../../Common/Images/Images";
import BillMonthly_Annually from "../../Common/PurchaseSuggested/BillMonthly_Annually";
import { Slider } from "../../Common/Slider/Slider";
import { Button } from "../../Custom/Button/Button";
import { toast } from "react-toastify";
const data1 = [
  {
    package_detail: "Splitsheet Agreements",
    activate: true,
  },
  {
    package_detail: "Register with PRO",
    activate: false,
  },
  {
    package_detail:
      "Encode/Fingerprint song into Soundscan/BDS/ GoogleID for audio",
    activate: false,
  },
  {
    package_detail:
      "Register with Content Aggregators (Apple Itunes, Spotify, etc)",
    activate: false,
  },
  {
    package_detail: "ISRC codes",
    activate: false,
  },
  {
    package_detail: "Copyright Submission ",
    activate: false,
  },
  {
    package_detail: "Song Registration",
    activate: false,
  },
  {
    package_detail: "Sample/Clearances /Licenses",
    activate: false,
  },
  {
    package_detail: "PRO Account Reviews",
    activate: false,
  },
  {
    package_detail: "Royalty Collection",
    activate: false,
  },
  {
    package_detail: "Black Box Collection",
    activate: false,
  },
  {
    package_detail: "Copyright Retrieval",
    activate: false,
  },
  {
    package_detail: "Catalog",
    activate: false,
  },
  {
    package_detail: "Unlimited Storage",
    activate: true,
  },
];

const data2 = [
  {
    package_detail: "Splitsheet Agreements",
    activate: true,
  },
  {
    package_detail: "Register with PRO",
    activate: true,
  },
  {
    package_detail:
      "Encode/Fingerprint song into Soundscan/BDS/ GoogleID for audio",
    activate: true,
  },
  {
    package_detail:
      "Register with Content Aggregators (Apple Itunes, Spotify, etc)",
    activate: true,
  },
  {
    package_detail: "ISRC codes",
    activate: true,
  },
  {
    package_detail: "Copyright Submission ",
    activate: true,
  },
  {
    package_detail: "Song Registration",
    activate: true,
  },
  {
    package_detail: "Sample/Clearances /Licenses",
    activate: true,
  },
  {
    package_detail: "PRO Account Reviews",
    activate: true,
  },
  {
    package_detail: "Royalty Collection",
    activate: true,
  },
  {
    package_detail: "Black Box Collection",
    activate: false,
  },
  {
    package_detail: "Copyright Retrieval",
    activate: false,
  },
  {
    package_detail: "Catalog",
    activate: false,
  },
  {
    package_detail: "Unlimited Storage",
    activate: true,
  },
];

const Monthly = [
  {
    package: "monthly",
    amount: "$49",
  },
  {
    package: "monthly",
    amount: "$99",
  },
];

const data3 = [
  {
    package_detail: "Splitsheet Agreements",
    activate: true,
  },
  {
    package_detail: "Register with PRO",
    activate: true,
  },
  {
    package_detail:
      "Encode/Fingerprint song into Soundscan/BDS/ GoogleID for audio",
    activate: true,
  },
  {
    package_detail:
      "Register with Content Aggregators (Apple Itunes, Spotify, etc)",
    activate: true,
  },
  {
    package_detail: "ISRC codes",
    activate: true,
  },
  {
    package_detail: "Copyright Submission ",
    activate: true,
  },
  {
    package_detail: "Song Registration",
    activate: true,
  },
  {
    package_detail: "Sample/Clearances /Licenses",
    activate: true,
  },
  {
    package_detail: "PRO Account Reviews",
    activate: true,
  },
  {
    package_detail: "Royalty Collection",
    activate: true,
  },
  {
    package_detail: "Black Box Collection",
    activate: true,
  },
  {
    package_detail: "Copyright Retrieval",
    activate: true,
  },
  {
    package_detail: "Catalog",
    activate: true,
  },
  {
    package_detail: "Unlimited Storage",
    activate: true,
  },
];

const Anually = [
  {
    package: "yearly",
    amount: "$499",
  },
  {
    package: "yearly",
    amount: "$999",
  },
];
export const MemberShipMobile = () => {
  const [MonthSwitch, setMonthSwitch] = useState(true);
  useEffect(() => {
    localStorage.setItem("SpecialForMobileSong", false);
  }, []);
  return (
    <>
      <div className="member_ship_mobile">
        <div className="member_ship_mobile_title">
          Simple, transparent pricing
        </div>
        {/* <div className="member_ship_mobile_title2">
          No contracts. No surprise fees
        </div> */}
      </div>
      <MembershipData
        is_monthly={!MonthSwitch}
        setMonthSwitch={setMonthSwitch}
      />
    </>
  );
};

export const MembershipData = ({ is_monthly, setMonthSwitch }) => {
  const current_store = useSelector((state) => state);
  const [current_showing_list, setcurrent_showing_list] = useState([
    ...Monthly,
  ]);
  const [DataBorderMembership, setDataBorderMembership] = useState("");
  const [membership_duration, setMembership_duration] = useState("");
  const [Check, setCheck] = useState("");
  useEffect(() => {
    setDataBorderMembership(
      current_store?.user?.data?.user_data?.membership_type
    );
    setMembership_duration(
      current_store?.user?.data?.user_data?.membership_duration
    );
  }, [current_store]);
  // console.log(current_store?.user?.data?.user_data?.membership_duration);
  useEffect(() => {
    if (membership_duration == "yearly") {
      setcurrent_showing_list([...Anually]);
      setCheck(true);
    } else {
      setcurrent_showing_list([...Monthly]);
    }
  }, [current_store, membership_duration]);
  // console.log(membership_duration);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [current_selected_plan, setcurrent_selected_plan] = useState("");
  useEffect(() => {
    setcurrent_selected_plan(
      current_store?.user?.data?.user_data?.membership_type
    );
  }, [current_store]);
  // }, [current_store, current_showing_list]);
  const notifyFail = (m) => toast.error(m);
  console.log(current_selected_plan);
  return (
    <div className="plan_group">
      <div className="inner_annually_monthlymem">
        <BillMonthly_Annually
          data11={Check}
          onevent={(is_active) => {
            // setselected_obj([]);
            if (is_active) {
              setcurrent_showing_list([...Anually]);
            } else {
              setcurrent_showing_list([...Monthly]);
            }
          }}
        />
      </div>
      <div className="plan_name_group">
        {["Basic", "Premium", "Ultra"].map((el) => (
          <div
            className={current_selected_plan === el ? "active" : ""}
            onClick={() => setcurrent_selected_plan(el)}
          >
            {el}
          </div>
        ))}
      </div>
      <Slider
        transform_by={["Basic", "Premium", "Ultra"].indexOf(
          current_selected_plan
        )}
        child={[
          <div className="plan_main">
            <div className="description">
              You can create as many splitsheets you want.
              <br /> You will have access to latest 5 splitsheets.
              <br /> Includes Advertisements.
            </div>
            <div className="paln_services_group">
              {data1.map((el, i) => (
                <div
                  style={el.activate ? {} : { opacity: 0.5 }}
                  className="service_item"
                  key={i}
                >
                  <div>
                    <Images
                      img_var={
                        current_store?.user?.data?.user_data?.membership_type ==
                        "Basic"
                          ? "check_tick_current"
                          : "check_tick"
                      }
                    />
                  </div>
                  <div>{el.package_detail}</div>
                </div>
              ))}
            </div>
            {/* <div className="plan_btn_group">
              <div>
                $1.99/<span>download</span>
              </div>
              <Button
                custclass="plan_btn"
                type="type1"
                btn_obj={{
                  txt:  current_store?.user?.data?.user_data?.membership_type ==
                  "Basic"
                    ? "Current Plan"
                    : "Subscribe Now",
                  onclick: () => {

                  },
                  loading: false,
                }}
              />
            </div> */}
          </div>,
          <div className="plan_main">
            <div className="description">
              No cost for split sheet creation and download. Stores unlimited
              song titles in dashboard.
              <br /> Includes Advertisements.
            </div>
            <div className="paln_services_group">
              {data2.map((el, i) => (
                <div
                  style={el.activate ? {} : { opacity: 0.5 }}
                  className="service_item"
                  key={i}
                >
                  <div>
                    <Images
                      img_var={
                        current_store?.user?.data?.user_data?.membership_type ==
                        "Premium"
                          ? "check_tick_current"
                          : "check_tick"
                      }
                    />
                  </div>
                  <div>{el.package_detail}</div>
                </div>
              ))}
            </div>
            {/* <div className="plan_btn_group">
              <div>
                $1.99/<span>download</span>
              </div>
              <Button
                custclass="plan_btn"
                type="type1"
                btn_obj={{
                  txt: "Current Plan",
                  onclick: () => {},
                  loading: false,
                }}
              />
            </div> */}
          </div>,
          <div className="plan_main">
            <div className="description">
              Ability to schedule a 15 min call per month with publishing
              administrator. You can chat and email as well. Does not include Advertisements.
            </div>
            <div className="paln_services_group">
              {data3.map((el) => (
                <div
                  style={el.activate ? {} : { opacity: 0.5 }}
                  className="service_item"
                >
                  <div>
                    <Images
                      img_var={
                        current_store?.user?.data?.user_data?.membership_type ==
                        "Ultra"
                          ? "check_tick_current"
                          : "check_tick"
                      }
                    />
                  </div>
                  <div>{el.package_detail}</div>
                </div>
              ))}
            </div>
            {/* <div className="plan_btn_group">
              <div>
                $1.99/<span>download</span>
              </div>
              <Button
                custclass="plan_btn"
                type="type1"
                btn_obj={{
                  txt: "Current Plan",
                  onclick: () => {},
                  loading: false,
                }}
              />
            </div> */}
          </div>,
        ]}
      />
      <Slider
        cust_class=" plan_pay_footer"
        transform_by={["Basic", "Premium", "Ultra"].indexOf(
          current_selected_plan
        )}
        child={[
          <div className="plan_btn_group">
            <div className="amount_decide">
              <div>$1.99</div>
              <div>download</div>
            </div>

            <Button
              custclass="plan_btn"
              type="type1"
              btn_obj={{
                txt:
                  current_store?.user?.data?.user_data?.membership_type ==
                  "Basic"
                    ? "Current Plan"
                    : `${DataBorderMembership} Plan Active`,
                onclick: () => {},
                loading: false,
              }}
            />
          </div>,
          <div className="plan_btn_group">
            {/* <div>
              {current_showing_list[0]?.amount}/
              <span>{current_showing_list[0]?.package}</span>
            </div> */}
            <div className="amount_decide">
              <div>{current_showing_list[0]?.amount}</div>
              <div>{current_showing_list[0]?.package}</div>
            </div>
            {current_store?.user?.data?.user_data?.membership_type ==
            "Premium" ? (
              <div
                className="plan_btn1"
                onClick={() => {
                  dispatch({
                    type: "loading",
                  });
                  cancelSub(({ status, reasponse }) => {
                    if (status) {
                      // dispatch({
                      //   type: "loaded",
                      // });
                      window.location.reload();
                    }
                  });
                }}
              >
                Cancel Subscription
              </div>
            ) : (
            <Button
              custclass="plan_btn"
              type="type1"
              btn_obj={{
                txt:
                  current_store?.user?.data?.user_data?.membership_type ==
                  "Premium"
                    ? "Cancel Subscription"
                    : DataBorderMembership == "Basic"
                    ? "Subscribe Now"
                    : `${DataBorderMembership} Plan Active`,
                onclick: () => {
                  !current_store?.user?.data?.user_data?.card?.card_token
                    ? localStorage.setItem("ModAccount", true) ||
                      navigate("/account")
                    : dispatch({
                        type: "loading",
                      });
                  DataBorderMembership == "Premium"
                    ? cancelSub(({ status, reasponse }) => {
                        if (status) {
                          // dispatch({
                          //   type: "loaded",
                          // });
                          window.location.reload();
                        }
                      })
                    : MemberShip(
                        {
                          type: "Premium",
                          duration: current_showing_list[1]?.package,
                        },
                        ({ status, response }) => {
                          if (status) {
                            // dispatch({
                            //   type: "loaded",
                            // });
                            window.location.reload();
                          } else {
                            if (response.data != "Card Not Found") {
                              notifyFail(response.data);
                            }
                            dispatch({
                              type: "loaded",
                            });
                          }
                        }
                      );
                },
                loading: false,
              }}
            />)}
          </div>,
          <div className="plan_btn_group">
            {/* <div>
              {current_showing_list[1]?.amount}/
              <span>{current_showing_list[1]?.package}</span>
            </div> */}
            <div className="amount_decide">
              <div>{current_showing_list[1]?.amount}</div>
              <div>{current_showing_list[1]?.package}</div>
            </div>
            {current_store?.user?.data?.user_data?.membership_type ==
            "Ultra" ? (
              <div
                className="plan_btn1"
                onClick={() => {
                  dispatch({
                    type: "loading",
                  });
                  cancelSub(({ status, reasponse }) => {
                    if (status) {
                      // dispatch({
                      //   type: "loaded",
                      // });
                      window.location.reload();
                    }
                  });
                }}
              >
                Cancel Subscription
              </div>
            ) : (
              <Button
                custclass="plan_btn"
                type="type1"
                btn_obj={{
                  txt: `Subscribe Now`,
                  onclick: () => {
                    !current_store?.user?.data?.user_data?.card?.card_token
                      ? localStorage.setItem("ModAccount", true) ||
                        navigate("/account")
                      : dispatch({
                          type: "loading",
                        });
                    DataBorderMembership == "Ultra"
                      ? cancelSub(({ status, reasponse }) => {
                          if (status) {
                            // dispatch({
                            //   type: "loaded",
                            // });
                            window.location.reload();
                          }
                        })
                      : MemberShip(
                          {
                            type: "Ultra",
                            duration: current_showing_list[1]?.package,
                          },
                          ({ status, response }) => {
                            if (status) {
                              // dispatch({
                              //   type: "loaded",
                              // });
                              window.location.reload();
                            } else {
                              if (response.data != "Card Not Found") {
                                notifyFail(response.data);
                              }
                              dispatch({
                                type: "loaded",
                              });
                            }
                          }
                        );
                  },
                  loading: false,
                }}
              />
            )}
          </div>,
        ]}
      />
    </div>
  );
};
