const test_modal = {
  songcolabauth: {
    modal_isopen: true,
    modal_type: "SongColabAuth",
    modal_data: {
      img: "",
      deAuthorize: false,
      name: "",
      percentage: 0,
      authorization_list: [],
    },
    modal_page_data: {
      name: "",
      email: "",
      role: "",
      percentage_share: "0",
      notes: "",
    },
  },
};

const default_modaldata = {
  modal_isopen: false,
  modal_type: "",
  modal_data: {},
  modal_page_data: {},
};
export const modaldata = {
  ...default_modaldata,
};

const ModalData = (state = modaldata, { type, payload }) => {
  // console.log('login_reducer', type, payload);
  switch (type) {
    case "setmodal":
      return {
        ...state,
        modal_isopen: payload.modal_isopen,
        modal_type: payload.modal_type,
        modal_data: { ...default_modaldata.modal_data, ...payload.modal_data },
        modal_page_data: {
          ...default_modaldata.modal_page_data,
          ...payload.modal_page_data,
        },
      };
    case "closemodal":
      return { ...state, modal_isopen: false };
    case "dropmodal":
      return { ...state, ...default_modaldata };
    default:
      return state;
  }
};
export default ModalData;
