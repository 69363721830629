import React from "react";
import { useState } from "react";
import { Images } from "../../../Common/Images/Images";
import BillMonthly_Annually from "../../../Common/PurchaseSuggested/BillMonthly_Annually";
import { PremiumUltra } from "../../../Common/PurchaseSuggested/PremiumUltra";
const Anually = [
  {
    package: "anually",
    amount: "$499",
  },
  {
    package: "anually",
    amount: "$999",
  },
];
const Monthly = [
  {
    package: "monthly",
    amount: "$49",
  },
  {
    package: "monthly",
    amount: "$99",
  },
];
export default function AlertForPremiumPlan() {
  const [current_showing_list, setcurrent_showing_list] = useState([
    ...Monthly,
  ]);
  const [selected_obj, setselected_obj] = useState([]);
  const [Check, setCheck] = useState(true);
  return (
    <>
      <div className="main_alert_basic_plan">
        <div className="inner_alert_upgrade">
          <div className="inner_title_alert_basic">
            <span className="inner_title_alert_basic1">
              <span>
                <Images img_var="king_crown" />
              </span>
              <span>Upgrade your plan to avail</span>
            </span>
            chat and email support and much more!!!
          </div>
          <div className="inner_title_alert_main_basic">
            <div>
              <Images img_var="check_tick" />
            </div>
            Calendly, Schedule online 15minutes free appointment
          </div>
          <div className="inner_title_alert_main_basic">
            <div>
              <Images img_var="check_tick" />
            </div>
            Access to Music supervisors, dj’s
          </div>
          <div className="inner_title_alert_main_basic">
            <div>
              <Images img_var="check_tick" />
            </div>
            Chat support
          </div>
          <div className="inner_title_alert_main_basic">
            <div>
              <Images img_var="check_tick" />
            </div>
            Email support
          </div>
          <div className="inner_title_alert_main_basic">
            <div>
              <Images img_var="check_tick" />
            </div>
            Unlimited storage
          </div>
          <div className="inner_title_alert_main_basic1">No advertisements</div>
        </div>
        <div className="inner_alert_upgrade1">
          <div className="inner_alert_upgrade_btn">
          <BillMonthly_Annually
            onevent={(is_active) => {
              setselected_obj([]);
              if (is_active) {
                setcurrent_showing_list([...Anually]);
              } else {
                setcurrent_showing_list([...Monthly]);
              }
            }}
            />
          </div>
          <div className="inner_premium_tag">
          <PremiumUltra
              inp_obj={{
                plan_name: "Ultra",
                plan_amount:  current_showing_list[1]?.amount,
                plan_type: current_showing_list[1]?.package,
                selected_plan: true,
                setCheck,
                Check
              }}
            />
          </div>
          <div className="conotrol_premiun_normal_package">
            <div className="inner_premium_month">Continue with Ultra</div>
            <div className="inner_member_ship_page">
              Check more services. Go to Membership Page{" "}
              <Images img_var="right_sign" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
