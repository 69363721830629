import React from "react";
import { useState } from "react";
import { CustInput } from "../../../Custom/CustInput/CustInput";
import { Button } from "../../../Custom/Button/Button";
import { AdminMiscServicesAdd } from "../../../Common/Api/Api";
import { useDispatch } from "react-redux";


const AddServiceDropDown = ({ close }) => {
  const dispatch = useDispatch();
  const [page_values, setpage_values] = useState({
    name: "",
  });
  const [InputError, setInputError] = useState({
    name: "",
  });

  return (
    <>
      <div className="miscel_modal_main">
        <div className="mis_title">Add Utilities</div>
        <CustInput
          //   custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Name",
            value: page_values.name,
            error: InputError.name,
            on_event: ({ target }) => {
              //   toast(target.value);
              // console.log(
              //   target.value.match(
              //     /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
              //   )
              // );
              setpage_values((prev) => ({
                ...prev,
                name: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                name: target.value === "",
              }));
            },
          }}
        />

        <div className="mis_footer">
          <Button
            type="type1"
            custclass=""
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass=""
            btn_obj={{
              txt: "Save",
              onclick: () => {
                if(page_values.name == ""){
                  setInputError((prev) => ({
                    ...prev,
                    name : page_values.name == ""
                  }))
                  return
                }
                dispatch({
                  type: "loading",
                });
                AdminMiscServicesAdd(page_values,({status,response})=>{
                  if(status){
                    window.location.reload()
                  }
                })
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default AddServiceDropDown;
