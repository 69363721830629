import React from "react";
import { useState } from "react";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";
import { Images } from "../../../Common/Images/Images";
import { CreateTicket } from "../../../Common/Api/Api";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const ForAllThegeneralServices = ({ close }) => {
  const current_store = useSelector((state) => state);
  const [page_values, setpage_values] = useState({
    requirement: "",
  });
  const [SendId, setSendId] = useState("");
  useEffect(() => {
    if (!current_store?.user?.data?.songs) return;
    try {
      // setpage_data((prev) => ({
      //   ...prev,
      //   song_list: [...current_store?.user?.data?.songs],
      // }));
      setSendId(current_store?.modaldata?.modal_data?.id);
    } catch (error) {
      // setSampleClearance(false);
    }
  }, [current_store]);
  return (
    <>
      <div className="main-thank-you2">
        <div className="inner-thank-you">
          Thank you!
          <div>
            <Images img_var={"like"} />
          </div>
        </div>

        <div className="inner-thank-you4">
          Please enter details about your requirement
        </div>
        <CustInput
          custclass="textare_for_modal_copyright"
          type="textarea"
          inp_obj={{
            title: "",
            placeholder: "",
            value: page_values.requirement,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                requirement: target.value,
              }));
            },
          }}
        />
        <div className="inner-thank-you4">
        YOU WILL RECEIVE A FOLLOW UP EMAIL WITHIN 2 DAYS. CHECK OUT SOME OF OTHER SERVICES HERE.
        </div>

        <Button
          type="type1"
          custclass="Okay-btn"
          btn_obj={{
            txt: "Okay",
            onclick: () => {
              CreateTicket(
                [
                  {
                    requirement: page_values.requirement,
                  },
                ],
                SendId,
                ({ status, response }) => {
                  
                  if (status) {
                    window.location.reload()
                    // close();
                  }
                }
              );
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
      </div>
    </>
  );
};
