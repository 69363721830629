import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./style/main.css";
import "./style/service_page.css";
import "./style/service_page1.css";
import "./style/switchbtn.css";
import "./style/songs1.css";
import "./style/pages/users/users_main.css"
import "react-image-crop/dist/ReactCrop.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    // {/* <BrowserRouter> */}
    <App />
    // {/* </BrowserRouter> */}
  // {/* </React.StrictMode> */}
);
