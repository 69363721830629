import React from 'react'
import { Button } from '../../../Custom/Button/Button'


function FirstTimeISRCCode({close}) {
  return (
    <>
        <div className='main-thank-you2' >
            <div className="inner-thank-you">
                Thank you!
            </div>
            <div className="inner-thank-you2">
            You will receive the code in your email. Please check your inbox.
            </div>
            <Button
          type="type1"
          custclass="Okay-btn"
          btn_obj={{
            txt: "Okay",
            onclick: () => {
              close()
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
        </div>      

      
    </>
  )
}

export default FirstTimeISRCCode