import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { modaldata } from "../../../../store/modaldata";
import {
  AdminEditServices,
  AdminEditServicesApp,
  AdminGetContract,
} from "../../../Common/Api/Api";
import { Slider } from "../../../Common/Slider/Slider";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";
import AdminAddService from "./AdminAddServices";
import { AdminEditContractAlert2 } from "./AdminEditContractAlert2";
import { AdminEditContractFirstLaert } from "./AdminEditContractFirstLaert";

// const data123 = [
//   {
//     name: "Contract 1",
//     upload_doc: "File Upload",
//     price: "50.00",
//     show: true,
//   },
//   {
//     name: "Contract 2",
//     upload_doc: "File Upload",
//     price: "150.00",
//     show: true,
//   },
//   {
//     name: "Contract 3",
//     upload_doc: "File Upload",
//     price: "250.00",
//     show: true,
//   },
//   {
//     name: "Contract 4",
//     upload_doc: "File Upload",
//     price: "350.00",
//     show: true,
//   },
// ];
const AdminEditService = ({ close, adminService }) => {
  const [currentpage, setCurrentpage] = useState(true);
  const [currentpage1, setCurrentpage1] = useState();
  const [currentpageView, setcurrentpageView] = useState(false);
  // console.log(currentpage);
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [page_values, setpage_values] = useState({
    service_name: "",
    service_desc: "",
    service_price: "",
    min_fees: "",
    service_price_txt: "",
    service_id: "",
    show: "",
    link: "",
    membership_type: "",
    state_name: "onwards",
    state_list: [],
  });
  const [InputError, setInputError] = useState({
    service_name: "",
    service_desc: "",
    service_price: "",
    min_fees: "",
    service_price_txt: "",
    service_id: "",
    show: "",
    link: "",
    membership_type: "",
    state_name: "onwards",
    state_list: [],
  });
  useEffect(() => {
    setpage_values({
      service_id: adminService?.id,
      service_name: adminService?.name,
      service_desc: adminService?.description,
      service_price: adminService?.price,
      service_price_txt: adminService?.price_txt,
      show: true,
      min_fees: adminService?.min_fees,
      state_name: adminService?.price_txt,
      link: adminService?.link,
      state_list: [],
      membership_type: adminService.membership_type,
    });
  }, [adminService]);
  // useEffect(() => {
  // console.log(page_values.membership_type);

  //   }, [adminService]);
  let fordata = [
    "63da3f8e29cc9bb616d21209",
    "63da3f23c5ac5a71c38434d2",
    "63da3f202f19ed98c845f757",
    "63da3f10e67feebc673809b0",
    "63da3f9be59efa1316996dd6",
    "63da3f9d465767622d203a5e",
  ];
  return (
    <>
      <Slider
        transform_by={currentpage ? 0 : 1}
        child={[
          <div className="main_admin_service_edit" key={0}>
            <div className="title_edit_modal">Edit Service</div>
            <CustInput
              custclass="inner-input_modal"
              type="text"
              inp_obj={{
                title: "Enter service name",
                placeholder: "",
                value: page_values.service_name,
                error: InputError.service_name,
                on_event: ({ target }) => {
                  // console.log(target.value);
                  setpage_values((prev) => ({
                    ...prev,
                    service_name: target.value,
                  }));
                  setInputError((prev) => ({
                    ...prev,
                    service_name: !target.value,
                  }));
                },
              }}
            />
            <CustInput
              custclass="inner-input_modal"
              type="textarea"
              inp_obj={{
                title: "Enter service description",
                placeholder: "",
                value: page_values.service_desc,

                on_event: ({ target }) => {
                  // console.log(target.value);
                  setpage_values((prev) => ({
                    ...prev,
                    service_desc: target.value,
                  }));
                },
              }}
            />

            {page_values.service_id === "63da3f8e29cc9bb616d21209" ? (
              <>
                <CustInput
                  custclass="plan_admin_telleer"
                  type="button_select"
                  inp_obj={{
                    title: "Select Membership Type",
                    // readonly: current_login_page !== current_page,
                    button_type: "horizontal",
                    selection: "single",
                    to_show_data: [
                      {
                        key: "Basic",
                        value: "Basic",
                      },
                      {
                        key: "Premium",
                        value: "Premium",
                      },
                      {
                        key: "Ultra",
                        value: "Ultra",
                      },
                    ],
                    value: page_values.membership_type,
                    // error: InputError.organization,
                    on_event: (value) => {
                      setpage_values((prev) => ({
                        ...prev,
                        membership_type: value,
                      }));
                      // setInputError((prev) => ({
                      //   ...prev,
                      //   organization: value === "",
                      // }));
                    },
                  }}
                />
                <AdminEditContractFirstLaert
                  currentpage={currentpage}
                  setCurrentpage={setCurrentpage}
                  setCurrentpage1={setCurrentpage1}
                  currentpage1={currentpage1}
                  setcurrentpageView={setcurrentpageView}
                  currentpageView={currentpageView}
                  // data={data123}
                />
              </>
            ) : (
              <div style={{ width: "100%" }}>
                <CustInput
                  custclass="inner-input_modal"
                  type="number"
                  inp_obj={{
                    title: "Enter service price",
                    placeholder: "",
                    value: page_values.service_price,
                    error: InputError?.service_price,
                    on_event: ({ target }) => {
                      // console.log(target.value);
                      setpage_values((prev) => ({
                        ...prev,
                        service_price: target.value,
                      }));
                      setInputError((prev) => ({
                        ...prev,
                        service_price: target.value === "",
                        min_fees:
                          Number(target.value) < Number(page_values.min_fees),
                      }));
                    },
                  }}
                />
                {console.log(page_values.min_fees, page_values.service_price)}
                {fordata.includes(page_values.service_id) ? (
                  <CustInput
                    custclass="inner-input_modal"
                    type="number"
                    inp_obj={{
                      title: "Enter Minimum Fees",
                      placeholder: "",
                      value: page_values.min_fees,
                      error: InputError?.min_fees,
                      on_event: ({ target }) => {
                        // console.log(target.value);
                        setpage_values((prev) => ({
                          ...prev,
                          min_fees: target.value,
                        }));
                        setInputError((prev) => ({
                          ...prev,
                          min_fees:
                            target.value === "" ||
                            Number(target.value) >
                              Number(page_values.service_price) ||
                            Number(target.value) <= 0,
                        }));
                      },
                    }}
                  />
                ) : (
                  ""
                )}

                {/* <CustInput
                  custclass="inner-input_modal"
                  type="text"
                  inp_obj={{
                    title: "Enter service price text",
                    placeholder: "",
                    value: page_values.service_price_txt,
                    on_event: ({ target }) => {
                      // console.log(target.value);
                      setpage_values((prev) => ({
                        ...prev,
                        service_price_txt: target.value,
                      }));
                    },
                  }}
                /> */}
                <CustInput
                  custclass="inner-input_modal"
                  type="text"
                  inp_obj={{
                    title: "Expert call link",
                    placeholder: "",
                    value: page_values.link,
                    on_event: ({ target }) => {
                      // console.log(target.value);
                      setpage_values((prev) => ({
                        ...prev,
                        link: target.value,
                      }));
                    },
                  }}
                />
                <div className="inner-input_modal custom_drop_down_title">
                  <div className="serviceprice">Enter service price text</div>
                  <CustInput
                    custclass=""
                    type="dropdown"
                    inp_obj={{
                      options: [
                        {
                          option_key: "onwards",
                          option_value: "onwards",
                        },
                        {
                          option_key: "one time fee",
                          option_value: "one time fee",
                        },
                      ],
                      value: page_values.state_name,
                      on_event: (data) => {
                        setpage_values((prev) => ({
                          ...prev,
                          state_name: data,
                          state_list: [],
                        }));
                      },
                    }}
                  />
                </div>

                <CustInput
                  custclass="plan_admin_telleer"
                  type="button_select"
                  inp_obj={{
                    title: "Select Membership Type",
                    // readonly: current_login_page !== current_page,
                    button_type: "horizontal",
                    selection: "single",
                    to_show_data: [
                      {
                        key: "Basic",
                        value: "Basic",
                      },
                      {
                        key: "Premium",
                        value: "Premium",
                      },
                      {
                        key: "Ultra",
                        value: "Ultra",
                      },
                    ],
                    value: page_values.membership_type,
                    // error: InputError.organization,
                    on_event: (value) => {
                      setpage_values((prev) => ({
                        ...prev,
                        membership_type: value,
                      }));
                      // setInputError((prev) => ({
                      //   ...prev,
                      //   organization: value === "",
                      // }));
                    },
                  }}
                />
              </div>
            )}
            <div
              className={
                page_values.service_id === "63da3f8e29cc9bb616d21209"
                  ? "btn_handler_inedit_modal1"
                  : "btn_handler_inedit_modal"
              }
            >
              <Button
                type="type1"
                custclass="btn2"
                btn_obj={{
                  txt: "Cancel",
                  onclick: () => {
                    close();
                  },
                  loading: false,
                  show_type: "",
                }}
              ></Button>
              <Button
                type="type1"
                custclass="btn2"
                btn_obj={{
                  txt: "Save",
                  onclick: () => {
                    if (fordata.includes(page_values.service_id)) {
                      if (
                        Number(page_values.service_price) <
                          Number(page_values.min_fees) ||
                        Number(page_values.min_fees) <= 0
                      ) {
                        setInputError((prev) => ({
                          ...prev,
                          min_fees:
                            Number(page_values.service_price) <
                              Number(page_values.min_fees) ||
                            Number(page_values.min_fees) <= 0,
                        }));
                        return;
                      }
                      AdminEditServicesApp(
                        { ...page_values },
                        ({ status, response }) => {
                          dispatch({
                            type: "loading",
                          });
                          if (status) {
                            // dispatch({
                            //   type: "loaded",
                            // })
                            window.location.reload();
                          }
                        }
                      );
                    } else {
                      AdminEditServices(
                        { ...page_values },
                        ({ status, response }) => {
                          dispatch({
                            type: "loading",
                          });
                          if (status) {
                            window.location.reload();
                          }
                        }
                      );
                    }
                  },
                  loading: false,
                  show_type: "",
                }}
              ></Button>
            </div>
          </div>,
          <div className="main_admin_service_edit" key={1}>
            <AdminEditContractAlert2
              currentpage={currentpage}
              // data={data123}
              setcurrentpageView={setcurrentpageView}
              currentpageView={currentpageView}
              close={close}
              setCurrentpag={setCurrentpage}
              currentpage1={currentpage1}
            />
          </div>,
        ]}
      />
    </>
  );
};

export default AdminEditService;
