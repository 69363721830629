import React from "react";

const Accordian = ({
  acc_title,
  acc_body,
  current_selected,
  setcurrent_selected,
  edit_body,
  writer_edit_group,
}) => {
  // console.log(acc_body);
  return (
    <div className="cust_accordian">
      {acc_title ? <div className="acc_title">{acc_title}</div> : ""}
      {acc_body ? (
        <div className="acc_body">
          {acc_body.map((el) => (
            <div
              className={
                "acc_item" +
                (current_selected.user_id === el.user_id ? " active" : "")
              }
            >
              <div
                className="acc_header"
                onClick={() => {
                  setcurrent_selected(
                    current_selected.user_id === el.user_id ? {} : { ...el }
                  );
                }}
              >
                <div className="item_img">
                  {/* {el.img ? ( */}
                    <img
                      src={
                        el.img ||
                        "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                      }
                      onError={(e) => {
                        e.target.onError = null;
                        // console.log("work");
                        e.target.src =
                          "https://julietteimages.s3.amazonaws.com/profileimages/default.png";
                      }}
                      alt="writer img"
                    />
                  {/* ) : (
                    ""
                  )} */}
                </div>
                <div>
                  <div className="acc_name">{el.name}</div>
                  <div>
                    {Number(el.percentage)
                      ? `${Number(el.percentage).toFixed(2)}%`
                      : "-"}
                  </div>
                </div>
                <div className="acc_arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.5303 0.969668C9.8232 1.26256 9.8232 1.73744 9.5303 2.0303L5.5303 6.0303C5.2374 6.3232 4.7626 6.3232 4.4697 6.0303L0.469668 2.0303C0.176777 1.73744 0.176777 1.26256 0.469668 0.969668C0.762558 0.676777 1.23744 0.676777 1.53033 0.969668L5 4.4393L8.4697 0.969668C8.7626 0.676777 9.2374 0.676777 9.5303 0.969668Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              <div
                className="writer_edit_group"
                style={{
                  height:
                    current_selected?.user_id === el?.user_id
                      ? writer_edit_group?.current?.getBoundingClientRect()
                          ?.height
                      : 0,
                }}
              >
                {edit_body}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Accordian;
