import React from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "../../Pages/test/canvasPreview.ts";
import { useDebounceEffect } from "../../Pages/test/useDebounceEffect.ts";
import { useState } from "react";
import { useRef } from "react";
import { Images } from "../Images/Images";
import { Button } from "../../Custom/Button/Button";
import { Advertisnment } from "../Api/Api";
import { useDispatch } from "react-redux";
// import { Button } from "@mui/material";
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
export const ModalAdver = ({ close }) => {
  const [page_values, setpage_values] = useState({});
  const dispatch = useDispatch();
  const [image, setimage] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [crop_modal, setcrop_modal] = useState(false);
  const previewCanvasRefAccount = useRef();
  const imgRef = useRef();
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, rotate, aspect] = [1, 0, 890.06 / 132.98];
  const [edit_image, setEdit_image] = useState("");
  const [show_confirm, setshow_confirm] = useState(false);
  const [ImgHandlerByRefresh, setImgHandlerByRefresh] = useState(false);
  const onSelectFile = (e) => {
    setcrop_modal(true);
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setImgHandlerByRefresh(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };
  useDebounceEffect(
    async () => {
      setshow_confirm(false);
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRefAccount.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRefAccount.current,
          completedCrop,
          scale,
          rotate,
          (data) => {
            setEdit_image(data);
            setpage_values((prev) => ({
              ...prev,
              photo: data,
            }));
            setshow_confirm(true);
          }
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  return (
    <div className="main_advertisnment">
      <div className="inner_advertisnment">
        <label
          htmlFor="inp_img_add"
          style={
            image
              ? {
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  aspectRatio: aspect,
                  //   width:"100%"
                  height: "unset",
                }
              : {}
          }
          className="profile_img_1review1234"
        >
          {image || edit_image ? (
            ""
          ) : (
            <Button
              type="type1"
              // custclass="btn_handler_view_dashboard"
              btn_obj={{
                txt: "Edit Advertisnment",
                onclick: () => {
                  //   dispatch({
                  //     type: "setmodal",
                  //     payload: {
                  //       modal_isopen: true,
                  //       modal_type: "Advertisnment",
                  //       modal_data: {},
                  //     },
                  //   });
                },
                loading: false,
                show_type: "",
              }}
            />
          )}

          <input
            type="file"
            className={"add_img_senior"}
            accept=".png,.jpg,.jpeg"
            onChange={onSelectFile}
            id="inp_img_add"
          />
        </label>
        {crop_modal && (
          <div className="edit_image_main">
            <div className="edit_image_arrow"></div>
            <div className="image_group">
              {!!imgSrc ? (
                <ReactCrop
                  crop={crop}
                  onChange={(init_crop, percentCrop) => {
                    setCrop(percentCrop);
                  }}
                  onComplete={(c) => {
                    setCompletedCrop(c);
                  }}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              ) : (
                <div>Loading...</div>
              )}
              {!!completedCrop ? (
                <canvas
                  ref={previewCanvasRefAccount}
                  style={{
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div>Loading...</div>
              )}
            </div>
            <div className="edit_image_btn_group">
              <Button
                custclass="save_img"
                type="type1"
                btn_obj={{
                  txt: "Confirm",
                  onclick: () => {
                    setimage(edit_image);
                    dispatch({
                      type: "loading",
                    });
                    Advertisnment(edit_image, ({ status, response }) => {
                      if (status) {
                        close();
                        window.location.reload();
                        // dispatch({
                        //   type: "loaded",
                        // });
                      }
                    });
                    setcrop_modal(false);
                  },
                  loading: false,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
