import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AdminGetContract, DeleteAdminContract } from "../../../Common/Api/Api";
import { Images } from "../../../Common/Images/Images";
import { Slider } from "../../../Common/Slider/Slider";
import { CustInput } from "../../../Custom/CustInput/CustInput";

export const AdminEditContractFirstLaert = ({
  setCurrentpage,
  currentpage,
  setCurrentpage1,
  currentpage1,
  currentpageView,
  setcurrentpageView
  // data
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    AdminGetContract(({ status, response }) => {
      // console.log(response);
      if (status) {
        // console.log();
        setData(response.data);
      }
    });
  }, []);
  const dispatch = useDispatch()

  // if(page_values.service_id == "63da3f8e29cc9bb616d21209"){
  // }
  // console.log(data);
  return (
    <>
      <div className="main_contract_edit_alert">
        {data?.map((e, i) => {
          return (
            <>
              <div className="inner_contract_edit_alert" key={i}>
                <div>{e.name}</div>
                <div className="inner_contract_edit_alert1">
                  <div
                    className="view_handler_design"
                    onClick={() => {
                      setCurrentpage(!currentpage);
                      setcurrentpageView(!currentpageView);
                      setCurrentpage1(e);
                    }}
                    >
                    <Images img_var={"view"} />
                  </div>
                  <div
                    onClick={() => {
                      // setcurrentpageView(!currentpageEdit);
                      setCurrentpage(!currentpage);
                      setCurrentpage1(e);
                    }}
                  >
                    <Images img_var={"edit"} />
                  </div>
                  <div className="view_handler_design" onClick={
                    ()=>{
                      dispatch({
                        type: "loading",
                      });
                      DeleteAdminContract(e.id,({status,response})=>{
                        if(status){
                          window.location.reload()
                        }
                      })
                    }
                  }>
                    <Images img_var={"delete"} />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
