import React, { useState } from "react";
import { useEffect } from "react";

export const Button = ({ btn_obj, type, custclass }) => {
  const current_btn_obj = {
    txt: "",
    onclick: () => "",
    loading: false,
    show_type: "",
  };
  switch (type) {
    case "type1":
      return (
        <div
          className={
            "cust_btn bg_black f_color_white" +
            (custclass ? ` ${custclass}` : "") +
            (btn_obj?.show_type
              ? btn_obj.show_type === "hide"
                ? " btn_hidden"
                : ""
              : "")
          }
          // style={{backgroundColor : "#C50D6D"}}
          onClick={btn_obj.onclick || current_btn_obj.onclick}
        >
          <div>
            {btn_obj.img ? btn_obj.img : btn_obj.txt || current_btn_obj.txt}
          </div>
        </div>
      );
    case "type2":
      return (
        <div
          className={
            "cust_btn" +
            (custclass ? ` ${custclass}` : "") +
            (btn_obj?.show_type
              ? btn_obj.show_type === "hide"
                ? " btn_hidden"
                : ""
              : "")
          }
          onClick={btn_obj.onclick || current_btn_obj.onclick}
        >
          {btn_obj.txt || current_btn_obj.txt}
        </div>
      );

    default:
      break;
  }
  return "insufficient data";
};

export const SwitchBtn = ({ width, height, onevent ,data11}) => {
  // props:{width:height}
  const [data, setData] = useState(true);
  function btnSwitch() {
    setData(!data);
    onevent(data);
    // console.log(data)
  }
  useEffect(() => {
    if (data11) {
      btnSwitch()
    }
  }, [data11]);
  // console.log(data11);

  return (
    <>
      <div
        className="switch-btn"
        onClick={() => btnSwitch()}
        style={{
          "--btn_height": height,
          "--btn_width": width,
        }}
      >
        {/* {width:height} */}
        <div
          className={
            data ? `switch-btn-circle left ` : `switch-btn-circle right`
          }
        >
          {/* {width==height>initial div ka height} */}
        </div>
      </div>
    </>
  );
};
