import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardDetail } from "../../Common/Api/Api";
import { Button } from "../../Custom/Button/Button";
import { CustInput } from "../../Custom/CustInput/CustInput";
import { Images } from "../../Common/Images/Images";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
export const CardDetailEditAccount = ({ close }) => {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log(current_store);
  const [page_values, setpage_values] = useState({
    card_number: "",
    card_number1: "",
    card_expiry_year: "",
    card_expiry_month: "",
    card_cvc: "",
    brand: "",
  });
  const [InputError, setInputError] = useState({
    card_number: "",
    card_expiry_year: false,
    card_expiry_month: false,
    card_cvc: "",
    brand: "",
  });

  const [page_data, setpage_data] = useState({
    card_expiry_year: "",
    // card_expiry_year: new Date().getFullYear(),
    expiry_date_list: [],
    card_expiry_month: "",
    expiry_date_month_list: [],
  });
  const [card_type, setcard_type] = useState("");
  const [cardno, setcardno] = useState("");
  const [ErrorHandler, setErrorHandler] = useState({
    card_number: "",
    card_cvc: "",
    card_expiry_month: "",

  });
  const set_value_cardno = (current) => {
    // checks for a number as a input
    console.log(Number(current.substr(-1)));
    if (Number(current.substr(-1)) == current.substr(-1)) {
      //   // checks for the length of value to be less than 20
      if (current.split(" ").join("").length <= 19) {
        // console.log(true);
        //     // puts spaces between 4 digits eg. 1234 1234 1234 1234
        if (
          [5, 10, 15, 20].indexOf(current.length) > -1 &&
          cardno.length <= current.length
        ) {
          setcardno(cardno + " " + current.slice(-1));
          console.log(true);
        } else {
          console.log(false);
          setcardno(current);
        }
        let data = (current + "").split(" ").join("");
        if (data) {
          if (/^4[0-9]{6,}$/i.test(Number(data))) {
            setcard_type("visa");
            return;
          }
          if (
            /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/i.test(
              Number(data)
            )
          ) {
            setcard_type("mastercard");
            return;
          }
          if (/^3[47][0-9]{5,}$/i.test(Number(data))) {
            setcard_type("americanexp");
            return;
          }
          if (/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/i.test(Number(data))) {
            setcard_type("dinersclub");
            return;
          }
          if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/i.test(Number(data))) {
            setcard_type("discover");
            return;
          }
          if (/^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/i.test(Number(data))) {
            setcard_type("jcb");
            return;
          }
          if (/^(62[0-9]{14,17})$/i.test(Number(data))) {
            setcard_type("unionpay");
            return;
          } else {
            setcard_type("");
          }
        }
        // if (numberValidation.card) {
        //   console.log("here");
        //   if (numberValidation.card.type == "jcb") {
        //     setcard_type("jcb");
        //     console.log("jcb");
        //   } else if (numberValidation.card.type == "american-express") {
        //     console.log("amex");
        //     setcard_type("amex");
        //   } else if (
        //     numberValidation.card.type == "visa" ||
        //     `^4[0-9]{6,}$`.includes(data)
        //   ) {
        //     console.log("visa");
        //     setcard_type("visa");
        //   } else if (numberValidation.card.type == "mastercard") {
        //     setcard_type("mastercard");
        //   } else if (numberValidation.card.type == "discover") {
        //     setcard_type("discover");
        //   } else if (numberValidation.card.type == "diners-club") {
        //     setcard_type("diners");
        //   } else if (numberValidation.card.type == "unionpay") {
        //     setcard_type("unionpay");
        //   } else {
        //     setcard_type("");
        //   }
        // } else {
        //   setcard_type("");
        // }
      }
    }
  };

  return (
    <>
      <div className="card_edit_cection">
        <div
          className="new_card"
          style={{
            width: window.innerWidth <= 500 ? "100%" : "500px",
          }}
          type={"visa"}
          onClick={() => {
            dispatch({
              type: "setmodal",
              payload: {
                modal_isopen: true,
                modal_type: "CardDetailEditFill",
                modal_data: {},
              },
            });
          }}
        >
          <div className="card_header_logo">
            <div className="logo_controll logowhite">
              {/* <img src="https://imgur.com/RZSy9j6.png" />
               */}
              <Images img_var={"cardlogo"} />
            </div>
            <Images img_var={"sleepwifi"} />
          </div>
          <div className="card_body_detail_amime">
            {[..."1111111111111111111".split("")].map((el, i) => {
              // console.log(page_values.card_number.split("").length);

              return (
                <div className="card_number_anim_main">
                  {/* <div className={([...page_values.card_number.split("")].map((el,i1)=> i1).length - 1) == i? "to_anim" : ""} style={{color :[4,9,14].includes(i)? "transparent":"" */}
                  <div
                    className={
                      page_values.card_number.split("").length >= i + 1
                        ? "to_anim"
                        : ""
                    }
                    style={{
                      color: [4, 9, 14].includes(i) ? "transparent" : "",
                    }}
                  >
                    {"#"}
                  </div>
                  <div
                    style={{
                      color: [4, 9, 14].includes(i) ? "transparent" : "",
                    }}
                  >
                    {i > 14 ? page_values.card_number[i] : "*"}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="card_footer_detail">
            <div className="card_expery">
              <div className="expiry_title">Expires</div>
              <div className="expiry_date_mod">
                <div className="card_body_detail_amime">
                  <div className="card_number_anim_main">
                    <div
                      className={page_data.card_expiry_month ? "to_anim" : ""}
                    >
                      MM
                    </div>
                    <div>{page_data.card_expiry_month}</div>
                  </div>
                </div>
                /
                <div className="card_body_detail_amime">
                  <div className="card_number_anim_main">
                    <div
                      className={page_data.card_expiry_year ? "to_anim" : ""}
                    >
                      YY
                    </div>
                    <div>{page_data.card_expiry_year.toString().slice(2)}</div>
                  </div>
                </div>
                {/* <div>{page_data.card_expiry_month || "MM"}</div>/
                <div>
                  {page_data.card_expiry_year.toString().slice(2) || "YY"}
                </div> */}
              </div>
            </div>
            <div className="card_cvv">
              <div className="card_cvv_title">CVV</div>
              <div className="card_cvv_num_mod">
                {[..."1111".split("")].map((el, i) => {
                  return (
                    <div className="card_number_anim_main">
                      <div
                        className={
                          page_values.card_cvc.split("").length >= i + 1
                            ? "to_anim"
                            : ""
                        }
                      >
                        {"#"}
                      </div>
                      <div>{page_values.card_cvc[i]}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="card_brand">
              {card_type ? <Images img_var={card_type} /> : ""}
            </div>
          </div>
        </div>

        <div className="card_editor">
          <CustInput
            custclass="card_editor_number"
            type="text"
            inp_obj={{
              title: "Card Number",
              placeholder: "**** **** **** ****",
              value: page_values.card_number,
              error: InputError.card_number,
              error_str: ErrorHandler.card_number,
              on_event: ({ target }) => {
                if (Number.isNaN(Number(target.value.slice(-1)))) {
                  return;
                }
                set_value_cardno(target.value);
                setInputError((prev) => ({
                  ...prev,
                  card_number: !target.value,
                }));
                setErrorHandler({
                  card_number:
                    target.value === "" ? "Card Number Required" : "",
                });
                if (
                  Number(target.value.slice(-1)).toString() == "NaN" ||
                  target.value.length > 19 ||
                  (target.value.slice(-1) == " " &&
                    ![5, 10, 15].some((el) => el == target.value.length))
                ) {
                  return;
                }

                switch (target.value.length) {
                  case 5:
                    if (target.value.slice(-1) == " ") {
                      setpage_values((prev) => ({
                        ...prev,
                        card_number: target.value.slice(0, 4),
                      }));
                      return;
                    }
                    setpage_values((prev) => ({
                      ...prev,
                      card_number:
                        target.value.slice(0, 4) + " " + target.value.slice(-1),
                    }));
                    return;
                  case 10:
                    if (target.value.slice(-1) == " ") {
                      setpage_values((prev) => ({
                        ...prev,
                        card_number: target.value.slice(0, 9),
                      }));
                      return;
                    }
                    setpage_values((prev) => ({
                      ...prev,
                      card_number:
                        target.value.slice(0, 9) + " " + target.value.slice(-1),
                    }));
                    return;
                  case 15:
                    if (target.value.slice(-1) == " ") {
                      setpage_values((prev) => ({
                        ...prev,
                        card_number: target.value.slice(0, 14),
                      }));
                      return;
                    }
                    setpage_values((prev) => ({
                      ...prev,
                      card_number:
                        target.value.slice(0, 14) +
                        " " +
                        target.value.slice(-1),
                    }));
                    return;
                  default:
                    setpage_values((prev) => ({
                      ...prev,
                      card_number: target.value,
                    }));
                    return;
                }
              },
            }}
          />
          <CustInput
            custclass="card_editor_cvv"
            type="text"
            inp_obj={{
              title: "CVV",
              placeholder: "****",
              value: page_values.card_cvc,
              error: InputError.card_cvc,
              error_str: ErrorHandler.card_cvc,
              on_event: ({ target }) => {
                // console.log(target.value);
                if (Number(target.value) == target.value) {
                  if (target.value.length >= 5) {
                    return;
                  }
                  setpage_values((prev) => ({
                    ...prev,
                    card_cvc: target.value,
                  }));

                  setInputError((prev) => ({
                    ...prev,
                    card_cvc: !target.value,
                  }));
                  setErrorHandler({
                    card_cvc: target.value === "" ? "CVV Required" : "",
                  });
                }
              },
            }}
          />
          <div className="expiry_date">
            <div className="card_edit_date_title">Expiration Date</div>
            <div className="card_edit_date_body">
              {/* <FormControl sx={{ m: 1, minWidth: 150 }} > */}
              {/* <FormControl sx={{ m: 1, minWidth: 150 }} error> */}
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  className={"exp_month_date" + (InputError.card_expiry_month?" fieldset_error":"")}
                  value={page_data.card_expiry_month || "MM"}
                  error={InputError.card_expiry_month}
                  renderValue={(selected) => (
                    <div>{page_data.card_expiry_month || "MM"}</div>
                  )}
                  onChange={(data) => {
                    // console.log(data.target.value);
                    setpage_data((prev) => ({
                      ...prev,
                      card_expiry_month: data.target.value,
                      // expiry_date_month_list: [],
                    }));
                    setInputError((prev) => ({
                      ...prev,
                      card_expiry_month: !data.target.value,
                    }));
                  }}
                  autoWidth
                >
                  {[..."111111111111".split("")].map((el, i) => {
                    return (
                      <MenuItem value={i + 1 < 10 ? `0${i + 1}` : i + 1}>
                        {i + 1 < 10 ? `0${i + 1}` : i + 1}
                      </MenuItem>
                    );
                  })}
                </Select>
                { InputError.card_expiry_month? (
                  <FormHelperText>{ErrorHandler.card_expiry_month}</FormHelperText>
                ) : (
                  ""
                )}
              {/* </FormControl> */}
              {/* <FormControl sx={{ m: 1, minWidth: 150 }}> */}
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  className={"exp_month_date"+ (InputError.card_expiry_year?" fieldset_error":"")}
                  error={InputError.card_expiry_year}
                  value={page_data.card_expiry_year || "YY"}
                  renderValue={(selected) => (
                    <div>{page_data.card_expiry_year || "YY"}</div>
                  )}
                  onChange={(data) => {
                    setpage_data((prev) => ({
                      ...prev,
                      card_expiry_year: data.target.value,
                    }));
                    setInputError((prev) => ({
                      ...prev,
                      card_expiry_year: !data.target.value,
                    }));
                  }}
                  autoWidth
                >
                  {[..."1111111111".split("")].map((el, i) => {
                    return <MenuItem value={i + 2023}>{i + 23}</MenuItem>;
                  })}
                </Select>
              {/* </FormControl> */}
              {/* <CustInput
                // custclass="datainex"
                type="dropdown"
                inp_obj={{
                  options: [
                    {
                      option_key: 1,
                      option_value: "01",
                    },
                    {
                      option_key: 2,
                      option_value: "02",
                    },
                    {
                      option_key: 3,
                      option_value: "03",
                    },
                    {
                      option_key: 4,
                      option_value: "04",
                    },
                    {
                      option_key: 5,
                      option_value: "05",
                    },
                    {
                      option_key: 6,
                      option_value: "06",
                    },
                    {
                      option_key: 7,
                      option_value: "07",
                    },
                    {
                      option_key: 8,
                      option_value: "08",
                    },
                    {
                      option_key: 9,
                      option_value: "09",
                    },
                    {
                      option_key: 10,
                      option_value: "10",
                    },
                    {
                      option_key: 11,
                      option_value: "11",
                    },
                    {
                      option_key: 12,
                      option_value: "12",
                    },
                  ],
                  value: page_data.card_expiry_month,
                  on_event: (data) => {
                    // console.log(data);
                    setpage_data((prev) => ({
                      ...prev,
                      card_expiry_month: data,
                      expiry_date_month_list: [],
                    }));
                  },
                }}
              /> */}
              {/* <CustInput
                // custclass="datainex"
                type="dropdown"
                inp_obj={{
                  options: [
                    ..."1111111111".split("").map((el, i) => ({
                      option_key: new Date().getFullYear() + i,
                      option_value: (new Date().getFullYear() + i)
                        .toString()
                        .slice(2),
                    })),
                  ],
                  value: page_data.card_expiry_year,
                  on_event: (data) => {
                    // console.log(data);
                    setpage_data((prev) => ({
                      ...prev,
                      card_expiry_year: data,
                      expiry_date_list: [],
                    }));
                  },
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="btn_handler_inedit_modal">
          <Button
            type="type1"
            custclass="btn123"
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
                // console.log(1);
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass="btn123"
            btn_obj={{
              txt: "Save",
              onclick: () => {
             
                if (
                  ![3, 4].includes(page_values.card_cvc.length) ||
                  page_values.card_number.length != 19 ||
                  page_values.card_number === "" ||
                  page_data.card_expiry_month === "" ||
                  // page_data.card_expiry === "" ||
                  page_data.card_expiry_year === ""
                ) {
                  // setErrorHandler("Invalid Card Details");
                  // setErrorHandler((prev)=>({
                  //   ...prev,
                  //   card_number: "Invalid Card Details",
                  //   card_cvc: "Invalid Card Details",
                  //   card_expiry_month: "Invalid Card Details",
                  // }));
                  setInputError((prev) => ({
                    ...prev,
                    card_number:
                      page_values.card_number === "" ||
                      page_values.card_number.length != 19,
                    // card_expiry_year: page_values.card_expiry_year === "",
                    card_expiry_month:page_data.card_expiry_month == ""?true:false,
                    card_expiry_year : page_data.card_expiry_year == ""?true:false,
                    card_cvc: page_values.card_cvc === "",
                  }));
                  return;
                }
                dispatch({
                  type: "loading",
                });
                CardDetail(
                  {
                    ...page_values,
                    card_expiry_month: page_data.card_expiry_month,
                    card_expiry_year: page_data.card_expiry_year,
                  },
                  ({ status, response }) => {
                    dispatch({
                      type: "loaded",
                    });
                    if (status) {
                      window.location.reload();
                    } else {
                      dispatch({
                        type: "loaded",
                      });
                      // setErrorHandler();
                      setErrorHandler({
                        card_number: "Card is Not Valid",
                        card_cvc: "Card is Not Valid",
                      });
                    }
                  }
                );
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>

      {/* <div className="main_card_detail">
        <div
          className="card_detail_account_main_temp"
          style={{
            width: window.innerWidth <= 500 ? "100%" : "500px",
          }}
          type={"visa"}
        
        >
          <div className="first_section_card_chip_touchpay">
            <div className="chip_handler">
              <img src="https://my-music-publisher.s3.us-west-1.amazonaws.com/profileimages/1680672505648"></img>
            </div>
            <div className="touchpay">
              <Images img_var={"sleepwifi"} />
            </div>
          </div>
          <div className="card_number_temp">
         
          </div>
          <div className="mainVlaid">
            <div className="valid_from23">
             
              <div className="valid_from233">
               
                <CustInput
                  custclass="datainex"
                  type="dropdown"
                  inp_obj={{
                    options: [
                      {
                        option_key: 1,
                        option_value: "01",
                      },
                      {
                        option_key: 2,
                        option_value: "02",
                      },
                      {
                        option_key: 3,
                        option_value: "03",
                      },
                      {
                        option_key: 4,
                        option_value: "04",
                      },
                      {
                        option_key: 5,
                        option_value: "05",
                      },
                      {
                        option_key: 6,
                        option_value: "06",
                      },
                      {
                        option_key: 7,
                        option_value: "07",
                      },
                      {
                        option_key: 8,
                        option_value: "08",
                      },
                      {
                        option_key: 9,
                        option_value: "09",
                      },
                      {
                        option_key: 10,
                        option_value: "10",
                      },
                      {
                        option_key: 11,
                        option_value: "11",
                      },
                      {
                        option_key: 12,
                        option_value: "12",
                      },
                    ],
                    value: page_data.card_expiry_month,
                    on_event: (data) => {
                      // console.log(data);
                      setpage_data((prev) => ({
                        ...prev,
                        card_expiry_month: data,
                        expiry_date_month_list: [],
                      }));
                    },
                  }}
                />
                <div className="slash_account">/</div>
                <CustInput
                  custclass="datainex"
                  type="dropdown"
                  inp_obj={{
                    options: [
                      ..."1111111111".split("").map((el, i) => ({
                        option_key: new Date().getFullYear() + i,
                        option_value: (new Date().getFullYear() + i)
                          .toString()
                          .slice(2),
                      })),
                    ],
                    value: page_data.card_expiry_year,
                    on_event: (data) => {
                      // console.log(data);
                      setpage_data((prev) => ({
                        ...prev,
                        card_expiry_year: data,
                        expiry_date_list: [],
                      }));
                    },
                  }}
                />
                
              </div>
            </div>
            <div className="valid_from_account23">
             
              <div className="valid_from2dd">
              
                <CustInput
                  custclass="cvvvv"
                  type="text"
                  inp_obj={{
                    title: "",
                    placeholder: "****",
                    value: page_values.card_cvc,
                    error: InputError.card_cvc,
                    on_event: ({ target }) => {
                    
                      if (Number(target.value) == target.value) {
                        if (target.value.length >= 5) {
                          return;
                        }
                        setpage_values((prev) => ({
                          ...prev,
                          card_cvc: target.value,
                        }));
                        setInputError((prev) => ({
                          ...prev,
                          card_cvc: target.value,
                        }));
                      }
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className="bottom_card">
            <div className="accountname">
              {page_values?.name_person?.toUpperCase()}
            </div>
            <div className="card_type_account"></div>
          </div>
        </div>
        <div className="btn_handler_inedit_modal">
          <Button
            type="type1"
            custclass="btn123"
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
                
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass="btn123"
            btn_obj={{
              txt: "Save",
              onclick: () => {
               
                if (
                  ![3, 4].includes(page_values.card_cvc.length) ||
                  page_values.card_number.length != 19

             
                ) {
                  alert("Invalid Card Details");
                  setInputError((prev) => ({
                    ...prev,
                    card_number: page_values.card_number === "",
              
                    card_cvc: page_values.card_cvc === "",
                  }));
                  return;
                }
                dispatch({
                  type: "loading",
                });
                CardDetail(
                  {
                    ...page_values,
                    card_expiry_month: page_data.card_expiry_month,
                    card_expiry_year: page_data.card_expiry_year,
                  },
                  ({ status, response }) => {
                    dispatch({
                      type: "loaded",
                    });
                    if (status) {
                      window.location.reload();
                    } else {
                      dispatch({
                        type: "loaded",
                      });
                      alert("Card is Not Valid");
                    }
                  }
                );
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div> */}
    </>
  );
};
