import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardDetail } from "../../Common/Api/Api";
import { Button } from "../../Custom/Button/Button";
import { CustInput } from "../../Custom/CustInput/CustInput";

export const CardDetailAccount = ({ close }) => {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log(current_store);
  const [page_values, setpage_values] = useState({
    card_number: "",
    card_expiry_year: "",
    card_expiry_month: "",
    card_cvc: "",
    brand: "",
  });
  const [InputError, setInputError] = useState({
    card_number: "",
    card_expiry_year: "",
    card_expiry_month: "",
    card_cvc: "",
    brand: "",
  });

  const [page_data, setpage_data] = useState({
    card_expiry_year: 2023,
    expiry_date_list: [],
    card_expiry_month: 1,
    expiry_date_month_list: [],
  });
  // console.log(page_data);
  useEffect(() => {
    setpage_values({
      card_number:
        "**** **** **** " + (current_store?.user.data?.user_data?.card?.number || "****"),
      card_expiry_year: current_store?.user?.data?.user_data?.card?.year,
      card_expiry_month: current_store?.user?.data?.user_data?.card?.month,
      card_cvc: current_store?.user?.data?.user_data?.card?.cvv,
      brand: current_store?.user?.data?.user_data?.card?.brand,
    });
    setpage_data({
      card_expiry_year: current_store?.user?.data?.user_data?.card?.year,
      card_expiry_month: current_store?.user?.data?.user_data?.card?.month,
    });
  }, [current_store]);
  // console.log(current_store?.user?.data?.user_data?.card?.year);
  return (
    <>
      <div className="main_card_detail">
        <div className="card_detail_account_main" style={{margin : "0"}} type={"visa"}>
          <div className="card_number_account">
            <CustInput
              custclass="account_side90"
              type="text"
              inp_obj={{
                title: "",
                placeholder: "",
                value: page_values.card_number,
                error: InputError.card_number,
                on_event: ({ target }) => {
                  if (Number.isNaN(Number(target.value.slice(-1)))) {
                    return;
                  }
                  setpage_values((prev) => ({
                    ...prev,
                    card_number: target.value,
                  }));
                  setInputError((prev) => ({
                    ...prev,
                    card_number: target.value,
                  }));
                },
              }}
            />
            <CustInput
              custclass="account_side90"
              type="number"
              inp_obj={{
                title: "",
                placeholder: "",
                value: page_values.card_cvc,
                error: InputError.card_cvc,
                on_event: ({ target }) => {
                  // console.log(target.value);
                  setpage_values((prev) => ({
                    ...prev,
                    card_cvc: target.value,
                  }));
                  setInputError((prev) => ({
                    ...prev,
                    card_cvc: target.value,
                  }));
                },
              }}
            />
          </div>
          <div className="card_date_account">
           
            <CustInput
              custclass="datainex"
              type="dropdown"
              inp_obj={{
                options: [
                  {
                    option_key: 1,
                    option_value: "1",
                  },
                  {
                    option_key: 2,
                    option_value: "2",
                  },
                  {
                    option_key: 3,
                    option_value: "3",
                  },
                  {
                    option_key: 4,
                    option_value: "4",
                  },
                  {
                    option_key: 5,
                    option_value: "5",
                  },
                  {
                    option_key: 6,
                    option_value: "6",
                  },
                  {
                    option_key: 7,
                    option_value: "7",
                  },
                  {
                    option_key: 8,
                    option_value: "8",
                  },
                  {
                    option_key: 9,
                    option_value: "9",
                  },
                  {
                    option_key: 10,
                    option_value: "10",
                  },
                  {
                    option_key: 11,
                    option_value: "11",
                  },
                  {
                    option_key: 12,
                    option_value: "12",
                  },
                ],
                value: page_data.card_expiry_month,
                on_event: (data) => {
                  // console.log(data);
                  setpage_data((prev) => ({
                    ...prev,
                    card_expiry_month: data,
                    expiry_date_month_list: [],
                  }));
                },
              }}
            />
            
            <CustInput
              custclass="datainex"
              type="dropdown"
              inp_obj={{
                options: [
                  
                  ..."1111111111".split("").map((el, i) => ({
                    option_key: new Date().getFullYear() + i,
                    option_value: (new Date().getFullYear() + i)
                      .toString()
                      .slice(2),
                  })),
                ],
                value: page_data.card_expiry_year,
                on_event: (data) => {
                  // console.log(data);
                  setpage_data((prev) => ({
                    ...prev,
                    card_expiry_year: data,
                    expiry_date_list: [],
                  }));
                },
              }}
            />
          </div>
          <div className="card_type_account">{page_values.brand}</div>
        </div>

        <div className="btn_handler_inedit_modal">
          <Button
            type="type1"
            custclass="btn1"
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
                // console.log(1);
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass="btn1"
            btn_obj={{
              txt: "Save",
              onclick: () => {
                if (page_values.card_expiry_year === "") {
                  setInputError((prev) => ({
                    ...prev,
                    card_expiry_year: page_values.card_expiry_year === "",
                  }));
                  // console.log("addsongerror", page_values.photo);
                  return;
                }
                if (
                  page_values.card_cvc === "" ||
                  // page_data.card_expiry_month === "" ||
                  // page_data.card_expiry === "" ||
                  page_values.card_number === ""
                ) {
                  setInputError((prev) => ({
                    ...prev,
                    card_number: page_values.card_number === "",
                    // card_expiry_year: page_values.card_expiry_year === "",
                    // card_expiry_month: page_values.card_expiry_month === "",
                    card_cvc: page_values.card_cvc === "",
                  }));
                  return;
                }
                dispatch({
                  type: "loading",
                });
                CardDetail(
                  {
                    ...page_values,
                    card_expiry_month: page_data.card_expiry_month,
                    card_expiry_year: page_data.card_expiry_year,
                  },
                  ({ status, response }) => {
                    dispatch({
                      type: "loaded",
                    });
                    if (status) {
                      window.location.reload();
                    }
                  }
                );
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};
