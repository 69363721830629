import { combineReducers, createStore } from "redux";
import AppData from "./appdata";
// import Admin from "./adminreducer";
import User from "./loginreducer";
import ModalData from "./modaldata";
// import RequestPickupData from "./pickupreducer";
// import Table1 from "./table1reducer";
// import Table2 from "./table2reducer";

// import Current_tab from "./reducer/CurrentTab";
// import QIN_reducer from "./reducer/QIN_reducer";
// import theme_reducer from "./reducer/theme_reducer";

const rootReducer = combineReducers({
  user: User,
  appdata: AppData,
  modaldata: ModalData,

});

const store = createStore(rootReducer);

export default store;
