import React, { useRef } from "react";

export const SVG_Graph = ({
  plot,
  viewbox,
  linecolor,
  gap,
  line_width,
  linecap,
  cust_class,
}) => {
  // Properties of a line
  // I:  - pointA (array) [x,y]: coordinates
  //     - pointB (array) [x,y]: coordinates
  // O:  - (object) { length: l, angle: a }: properties of the line

  const line = (pointA, pointB) => {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    };
  };

  // Position of a control point
  // I:  - current (array) [x, y]: current point coordinates
  //     - previous (array) [x, y]: previous point coordinates
  //     - next (array) [x, y]: next point coordinates
  //     - reverse (boolean, optional): sets the direction
  // O:  - (array) [x,y]: a tuple of coordinates
  const controlPoint = (current, previous, next, reverse) => {
    // When 'current' is the first or last point of the array
    // 'previous' or 'next' don't exist.
    // Replace with 'current'
    const p = previous || current;
    const n = next || current;
    // The smoothing ratio
    const smoothing = 0.2;
    // Properties of the opposed-line
    const o = line(p, n);
    // If is end-control-point, add PI to the angle to go backward
    const angle = o.angle + (reverse ? Math.PI : 0);
    const length = o.length * smoothing;
    // The control point position is relative to the current point
    const x = current[0] + Math.cos(angle) * length;
    const y = current[1] + Math.sin(angle) * length;
    return [x, y];
  };
  // Create the bezier curve command
  // I:  - point (array) [x,y]: current point coordinates
  //     - i (integer): index of 'point' in the array 'a'
  //     - a (array): complete array of points coordinates
  // O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
  const bezierCommand = (point, i, a) => {
    // start control point
    const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point);
    // end control point
    const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true);
    return `C ${cpsX.toFixed(2)},${cpsY.toFixed(2)} ${cpeX.toFixed(
      2
    )},${cpeY.toFixed(2)} ${point[0]},${point[1]}`;
  };
  const svgPath = (points, command) => {
    // build the d attributes by looping over the points
    const d = points.reduce(
      (acc, point, i, a) =>
        i === 0
          ? // if first point
            `M ${point[0]},${point[1]}`
          : // else
            `${acc} ${command(point, i, a)}`,
      ""
    );
    return (
      <path
        d={`${d}`}
        fill="none"
        stroke={linecolor}
        strokeWidth={line_width}
        strokeLinecap={
          ["round", "butt", "square"].includes(linecap) ? linecap : "round"
        }
      />
    );
  };
  return (
    <svg
      viewBox={`0 0 ${viewbox[0] + (gap || 0) + line_width / 2} ${
        viewbox[1] + (gap || 0) + line_width / 2
      }`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={cust_class || ""}
      x={`${gap ? gap / 2 : 0}px`}
      y={`${gap ? gap / 2 : 0}px`}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "rotateX(180deg)",
        overflow: "visible",
      }}
    >
      {svgPath(plot, bezierCommand)}
    </svg>
  );
};
