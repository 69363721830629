import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AddAdminContract } from "../../../Common/Api/Api";
import { Images } from "../../../Common/Images/Images";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";

const AdminAddService = ({ close }) => {
  const [page_values, setpage_values] = useState({
    name: "",
    upload_doc: "",
    uploadSample_doc: "",
    price: "",
  });
  const [InputError, setInputError] = useState({
    name: "",
    upload_doc: "",
    uploadSample_doc: "",
    price: "",
  });
  const [FileDocum, setFileDocum] = useState("");
  const [FileName, setFileName] = useState("");
  const [SampleFileName, setSampleFileName] = useState("");
  const dispatch = useDispatch();
  // console.log(FileDocum);
  const onSelectFile = (e) => {
    // console.log(e.target.files[0]);
    setFileName(e.target.files[0].name);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setpage_values((prev) => ({
          ...prev,
          upload_doc: reader.result?.toString() || "",
        }));
        e.target.value = null;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onSelectFile1 = (e) => {
    // console.log(e.target.files[0]);
    setSampleFileName(e.target.files[0].name);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setpage_values((prev) => ({
          ...prev,
          uploadSample_doc: reader.result?.toString() || "",
        }));
        e.target.value = null;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <div className="main_admin_service_edit1">
        <div className="title_edit_modal">Add Service</div>
        <CustInput
          custclass="iner_price_contract"
          type="text"
          inp_obj={{
            title: "Name",
            placeholder: "",
            value: page_values.name,
            error: InputError.name,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                name: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                name: target.value === "",
              }));
            },
          }}
        />
        {/* <CustInput
          custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Upload Document",
            placeholder: "",
            value: page_values.upload_doc,
            on_event: ({ target }) => {
              console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                upload_doc: target.value,
              }));
            },
          }}
        /> */}

        <label htmlFor="tricket_file">
          {FileName ? (
            <>
              <div className="addcontract_data">
                <CustInput
                  custclass="inner_input_admin_service"
                  type="text"
                  inp_obj={{
                    title: "File",
                    placeholder: "",
                    value: FileName,
                    readonly: true,
                    on_event: ({ target }) => {
                      // console.log(target.value);
                    },
                  }}
                />
                <div className="deletr_contract">
                  <Images img_var={"delete"} />
                </div>
              </div>
            </>
          ) : (
            <div className="ser_btn_up">
              <Button
                type="type1"
                custclass="btn_uploaddoc"
                btn_obj={{
                  txt: "Upload Document",
                  onclick: () => "",
                  loading: false,
                  show_type: "",
                }}
              ></Button>
            </div>
          )}

          <input
            type="file"
            className={"add_img_senior"}
            accept=".pdf,.docx,.doc"
            onChange={onSelectFile}
            id="tricket_file"
          />
        </label>
        <label htmlFor="tricket_file1">
          {SampleFileName ? (
            <>
            
              <div className="addcontract_data">
                <CustInput
                  custclass="inner_input_admin_service"
                  type="text"
                  inp_obj={{
                    title: "Sample File",
                    placeholder: "",
                    value: SampleFileName,
                    readonly: true,
                    on_event: ({ target }) => {
                      // console.log(target.value);
                    },
                  }}
                />
                <div className="deletr_contract">
                  <Images img_var={"delete"} />
                </div>
              </div>
            </>
          ) : (
            <div className="ser_btn_up2">
              <Button
                type="type1"
                custclass="btn_uploaddoc"
                btn_obj={{
                  txt: "Upload Sample Document",
                  onclick: () => "",
                  loading: false,
                  show_type: "",
                }}
              ></Button>
            </div>
          )}

          <input
            type="file"
            className={"add_img_senior"}
            accept=".pdf,.docx,.doc"
            onChange={onSelectFile1}
            id="tricket_file1"
          />
        </label>

        {/* <div className="handler_shower_in_modal"> */}
        <CustInput
          custclass="iner_price_contract"
          type="text"
          inp_obj={{
            title: "Price",
            placeholder: "",
            error: InputError.price,
            value: page_values.price,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                price: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                price: target.value === "",
              }));
            },
          }}
        />
        {/* <div className="inner-input_modal1">
            <div className="tag">Status</div>
            <CustInput
              custclass="inuit"
              type="checkbox"
              inp_obj={{
                title: "Show",
                placeholder: "",
                value: page_values.show,
                on_event: ({ target }) => {
                  console.log(target.value);
                  setpage_values((prev) => ({
                    ...prev,
                    show: !prev.show,
                  }));
                },
              }}
            />
          </div> */}
        {/* </div> */}

        <div className="btn_handler_inedit_modal">
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
                // console.log(1);
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Save",
              onclick: () => {
                if (
                  page_values.name === "" ||
                  page_values.upload_doc === "" ||
                  page_values.price === ""
                ) {
                  setInputError((prev) => ({
                    ...prev,
                    name: page_values.name === "",
                    upload_doc: page_values.upload_doc === "",
                    price: page_values.price === "",
                  }));
                  return;
                }
                dispatch({
                  type: "loading",
                });
                AddAdminContract(page_values, ({ status, response }) => {
                  if (status) {
                    window.location.reload();
                  } else {
                    dispatch({
                      type: "loaded",
                    });
                  }
                });
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default AdminAddService;
