import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
// import { Calendar } from "react-date-range";
import { Images } from "../../Common/Images/Images";
import { calc_horizontal } from "../../Common/utils/utils";

import { Calendar } from "../../Packages/react-date-range";
import "../../Packages/react-date-range/dist/styles.css";
import "../../Packages/react-date-range/dist/theme/default.css";
import { MenuItem, Select } from "@mui/material";
let lastId = 0;

const month_arr = [
  ["Jan", "January"],
  ["Feb", "February"],
  ["Mar", "March"],
  ["Apr", "April"],
  ["May", "May"],
  ["June", "June"],
  ["July", "July"],
  ["Aug", "August"],
  ["Sept", "September"],
  ["Oct", "October"],
  ["Nov", "November"],
  ["Dec", "December"],
];
const weekday_arr = [
  ["Sun", "Sunday"],
  ["Mon", "Monday"],
  ["Tue", "Tuesday"],
  ["Wed", "Wednesday"],
  ["Thur", "Thursday"],
  ["Fri", "Friday"],
  ["Sat", "Saturday"],
];
export const date_formatter = (date, format) => {
  // console.log(date);
  let temp_date = date;
  // console.log(temp_date);
  // if (temp_date == "Invalid Date") {
  // // return "Invalid Date";
  //   temp_date = new Date()
  // }
  temp_date = {
    date: temp_date.getDate(),
    month: temp_date.getMonth(),
    year: temp_date.getFullYear(),
    weekday: temp_date.getDay(),
  };
  let temp_date_data = {
    month: temp_date.month + 1,
    month:
      temp_date.month < 9 ? `0${temp_date.month + 1}` : temp_date.month + 1,
    month_short: month_arr[temp_date.month][0],
    month_long: month_arr[temp_date.month][1],

    date: temp_date.date < 10 ? `0${temp_date.date}` : temp_date.date,

    year_short: temp_date.year % 100,
    year_long: temp_date.year,

    weekday_short: weekday_arr[temp_date.weekday][0],
    weekday_long: weekday_arr[temp_date.weekday][1],
  };
  Object.keys(temp_date_data).map(
    (el) => (format = format.replaceAll(el, temp_date_data[el]))
  );
  return format;
};

export const CustInput = ({ custclass, type, inp_obj }) => {
  const current_inp_obj = {
    error: false,
    title: "",
    value: "",
    on_blur: () => "",
    placeholder: "",
    on_event: () => "",
    on_keydown: () => "",
    error_str: "",
    checked: false,
    readonly: false,
    button_type: "horizontal",
    selection: "single",
    to_show_data: [],
    // note include time also in date_formatter() -> dev
    date_format: "month_short / date / year_long weekday_long",
    options: [],
  };
  lastId++;
  const [values, setvalues] = useState({
    calender_height: 0,
    calender_is_above: false,
    calender_is_open: false,
  });

  const calender = useRef();
  const calender_main = useRef();
  // console.log(inp_obj?.error);
  useLayoutEffect(() => {
    if (calender.current) {
      setvalues({
        ...values,
        calender_is_above:
          window.innerHeight -
            calender_main?.current?.getBoundingClientRect()?.bottom >
          calender_main?.current?.getBoundingClientRect()?.top
            ? false
            : true,
        calender_height: calender.current.children[0].scrollHeight,
      });
    }
  }, [inp_obj, values.calender_is_open]);

  switch (type) {
    case "text":
      return (
        <div
          className={"text_group cust_inp" + (custclass ? ` ${custclass}` : "")}
        >
          <label htmlFor={`inp_id${lastId}`}>
            {(inp_obj.title || current_inp_obj.title) && (
              <div className="inp_title">
                {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                {inp_obj.title || current_inp_obj.title}
              </div>
            )}
            <input
              error={(inp_obj?.error || current_inp_obj.error).toString()}
              type="text"
              onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
              value={inp_obj.value || current_inp_obj.value}
              onChange={inp_obj.on_event || current_inp_obj.on_event}
              readOnly={inp_obj.readonly || current_inp_obj.readonly}
              placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
              id={`inp_id${lastId}`}
              onKeyDown={inp_obj.on_keydown || current_inp_obj.on_keydown}
            />
          </label>
          <div
            className="error_otp_not_match"
            style={{
              padding: "4px 0 0 0",
              transform:
                inp_obj.error_str || current_inp_obj.error_str
                  ? "scaley(1)"
                  : "scaley(0)",
              transition: "transform 0.1s linear",
            }}
          >
            {inp_obj.error_str}
          </div>
        </div>
      );
    // case "audio":
    //   return (
    //     <div
    //       className={"text_group cust_inp" + (custclass ? ` ${custclass}` : "")}
    //     >
    //       <label htmlFor={`inp_id${lastId}`}>
    //         {(inp_obj.title || current_inp_obj.title) && (
    //           <div className="inp_title">
    //             {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
    //             {inp_obj.title || current_inp_obj.title}
    //           </div>
    //         )}
    //         <input
    //           error={(inp_obj?.error || current_inp_obj.error).toString()}
    //           type="text"
    //           value={inp_obj.value || current_inp_obj.value}
    //           onChange={inp_obj.on_event || current_inp_obj.on_event}
    //           readOnly={inp_obj.readonly || current_inp_obj.readonly}
    //           placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
    //           id={`inp_id${lastId}`}
    //         />
    //       </label>
    //     </div>
    //   );
    case "textarea":
      return (
        <div
          className={"text_group cust_inp" + (custclass ? ` ${custclass}` : "")}
        >
          <label htmlFor={`inp_id${lastId}`}>
            {(inp_obj.title || current_inp_obj.title) && (
              <div className="inp_title">
                {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                {inp_obj.title || current_inp_obj.title}
              </div>
            )}
            <textarea
              rows="3"
              style={{ resize: "none", outline: "0", width: "100%" }}
              error={(inp_obj?.error || current_inp_obj.error).toString()}
              value={inp_obj.value || current_inp_obj.value}
              onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
              onChange={inp_obj.on_event || current_inp_obj.on_event}
              readOnly={inp_obj.readonly || current_inp_obj.readonly}
              placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
              id={`inp_id${lastId}`}
            />
          </label>
        </div>
      );
    case "search":
      return (
        <div
          className={
            "search_group cust_inp" + (custclass ? ` ${custclass}` : "")
          }
        >
          <label htmlFor={`inp_id${lastId}`}>
            <Images img_var="search" />
            <input
              type="text"
              value={inp_obj.value || current_inp_obj.value}
              onChange={inp_obj.on_event || current_inp_obj.on_event}
              readOnly={inp_obj.readonly || current_inp_obj.readonly}
              placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
              id={`inp_id${lastId}`}
            />
          </label>
        </div>
      );
    case "number":
      return (
        <div
          className={"text_group cust_inp" + (custclass ? ` ${custclass}` : "")}
        >
          <label htmlFor={`inp_id${lastId}`}>
            {(inp_obj.title || current_inp_obj.title) && (
              <div className="inp_title">
                {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                {inp_obj.title || current_inp_obj.title}
              </div>
            )}
            <input
              error={(inp_obj?.error || current_inp_obj.error).toString()}
              type="number"
              onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
              value={inp_obj.value || current_inp_obj.value}
              onChange={inp_obj.on_event || current_inp_obj.on_event}
              readOnly={inp_obj.readonly || current_inp_obj.readonly}
              placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
              id={`inp_id${lastId}`}
            />
          </label>
        </div>
      );
    case "checkbox":
      return (
        <div
          className={
            "checkbox_group cust_inp" + (custclass ? ` ${custclass}` : "")
          }
          onClick={inp_obj.on_event || current_inp_obj.on_event}
        >
          <div className="cust_checkbox_group">
            <div
              className={
                "cust_checkbox" +
                (inp_obj.value || current_inp_obj.value ? " active" : "")
              }
            >
              <Images img_var={"tick"} />
            </div>
          </div>
          {(inp_obj.title || current_inp_obj.title) && (
            <div className="inp_title">
              {inp_obj.title || current_inp_obj.title}
            </div>
          )}
        </div>
      );
    case "button_select":
      return (
        <div
          className={
            "buttonSelect_group cust_inp" + (custclass ? ` ${custclass}` : "")
          }
        >
          {(inp_obj.title || current_inp_obj.title) && (
            <div className="inp_title">
              {inp_obj.title || current_inp_obj.title}
            </div>
          )}
          <div className="cust_buttonSelect_group">
            {[...(inp_obj.to_show_data || current_inp_obj.to_show_data)].map(
              (el) => {
                let active_values = inp_obj.value || "";
                let is_values_array = Array.isArray(active_values);
                let is_current_active = false;
                if (is_values_array) {
                  is_current_active = active_values.includes(el.key);
                } else {
                  is_current_active = active_values === el.key;
                }
                return (
                  <div
                    className={
                      "buttonSelect_btn" + (is_current_active ? " active" : "")
                    }
                    hover_highlight="true"
                    onClick={() => {
                      if (inp_obj.on_event) {
                        // console.log(inp_obj.value);
                        if (inp_obj.selection === "multiple") {
                          if (is_values_array) {
                            if (inp_obj.value.includes(el.key)) {
                              let temp_value = [...inp_obj.value].filter(
                                (el1) => el1 !== el.key
                              );
                              inp_obj.on_event(temp_value);
                            } else {
                              inp_obj.on_event([...inp_obj.value, el.key]);
                            }
                          } else {
                            inp_obj.on_event([el.key]);
                          }
                          // let temp_active_values = [...active_values];
                          // if (temp_active_values.includes(el.key)) {
                          //   temp_active_values = temp_active_values.filter(
                          //     (el1) => el1 !== el.key
                          //   );
                          //   console.log(temp_active_values);
                          //   inp_obj.onchange({
                          //     target: { value: [...temp_active_values] },
                          //   });
                          // } else {
                          //   inp_obj.onchange({ target: { value: [] } });
                          // }
                        } else if (inp_obj.selection === "single") {
                          if (is_values_array) {
                            inp_obj.on_event(el.key);
                          } else {
                            if (inp_obj.value === el.key) {
                              inp_obj.on_event("");
                            } else {
                              inp_obj.on_event(el.key);
                            }
                          }
                        }
                      }
                    }}
                  >
                    {el.value}
                  </div>
                );
              }
            )}
          </div>
        </div>
      );
    case "date_selector":
      return (
        <div
          className={
            "cust_datepicker cust_inp" + (custclass ? ` ${custclass}` : "")
          }
          style={{
            "--calender_height": `${values.calender_height}px`,
            ...(values.calender_is_above
              ? {
                  "--calender_bottom": "100%",
                  "--calender_top": "unset",
                }
              : {
                  "--calender_bottom": "unset",
                  "--calender_top": "100%",
                }),
          }}
          onMouseLeave={() => {
            setvalues((prev) => ({ ...prev, calender_is_open: false }));
          }}
        >
          {(inp_obj.title || current_inp_obj.title) && (
            <div className="inp_title">
              {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
              {inp_obj.title || current_inp_obj.title}
            </div>
          )}
          <div
            ref={calender_main}
            className="datepicker_view"
            onClick={() =>
              setvalues((prev) => ({
                ...prev,
                calender_is_open: !prev.calender_is_open,
              }))
            }
            style={{ borderColor: inp_obj?.error ? "red" : "" }}
          >
            {inp_obj.value
              ? date_formatter(
                  inp_obj.value,
                  inp_obj.date_format || current_inp_obj.date_format
                )
              : inp_obj.placeholder || current_inp_obj.placeholder}
            {/* {console.log(inp_obj.placeholder)} */}
          </div>
          <div
            ref={calender}
            className={
              "cust_calender_main" + (values.calender_is_open ? " active" : "")
            }
            onClick={() => {
              // console.log("clicked inside calender");
              setTimeout(() => {
                setvalues({
                  ...values,
                  calender_height: calender.current.children[0].scrollHeight,
                });
              }, 100);
            }}
          >
            <Calendar
              className="cust_calender"
              onChange={(item) => {
                // setdate(item);
                if (inp_obj.on_event) {
                  inp_obj.on_event(item);
                }
                // setvalue(item?.toString().split(" ").slice(1, 4).join(" "));
              }}
              date={inp_obj.value || null}
            />
          </div>
        </div>
      );
    // <DatePicker
    //   calendarClassName={"cust_datepicker cust_inp"}
    //   onChange={inp_obj.on_event || current_inp_obj.on_event}
    //   value={inp_obj.value || current_inp_obj.value}
    // />
    case "img":
      return (
        <div
          className={"text_group cust_inp" + (custclass ? ` ${custclass}` : "")}
        >
          <label htmlFor={`inp_id${lastId}`}>
            {(inp_obj.title || current_inp_obj.title) && (
              <div className="inp_title">
                {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                {inp_obj.title || current_inp_obj.title}
              </div>
            )}
            <input
              type="text"
              value={inp_obj.value || current_inp_obj.value}
              onChange={inp_obj.on_event || current_inp_obj.on_event}
              readOnly={inp_obj.readonly || current_inp_obj.readonly}
              placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
              id={`inp_id${lastId}`}
            />
          </label>
        </div>
      );
    case "dropdown":
      return <Dropdown {...{ inp_obj, custclass }} />;
    case "autocomplete":
      return <AutoComplete {...{ inp_obj, current_inp_obj, custclass }} />;
    case "search_with_filter":
      return (
        <div
          className={
            "search_group with_filter cust_inp" +
            (custclass ? ` ${custclass}` : "")
          }
        >
          {(inp_obj.title || current_inp_obj.title) && (
            <div
              className="inp_title"
              style={{ marginBottom: "10px", fontSize: "20px" }}
            >
              {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
              {inp_obj.title || current_inp_obj.title}
            </div>
          )}
          <label htmlFor={`inp_id${lastId}`}>
            <Images img_var="search" />
            <input
              type="text"
              value={inp_obj.value || current_inp_obj.value}
              onChange={inp_obj.on_event || current_inp_obj.on_event}
              readOnly={inp_obj.readonly || current_inp_obj.readonly}
              placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
              id={`inp_id${lastId}`}
            />

            {inp_obj?.filter ? (
              <>
                <div onClick={() => inp_obj?.filter_onevent() || ""}>
                  <Images img_var="slider" />
                </div>
                {inp_obj?.filter}
              </>
            ) : (
              ""
            )}
          </label>
        </div>
      );
    case "multi-dropSel":
      return <MultiDropdownSelect {...{ inp_obj, custclass ,current_inp_obj}} />;
    case "single-dropSel":
      return <SingleDropdownSelect {...{ inp_obj, custclass }} />;
    default:
      break;
  }

  return <div>insufficient data</div>;
};

const Dropdown = ({ inp_obj, custclass }) => {
  const [is_open, setis_open] = useState(false);
  return (
    <div
      className={"cust_dropdown cust_inp" + (custclass ? ` ${custclass}` : "")}
      onClick={() => setis_open((prev) => !prev)}
      // onMouseLeave={() => setis_open(false)}
    >
      <div
        className="dropdown_main"
        style={{
          "--dropdown_height": calc_horizontal(43),
          height: is_open
            ? `calc( var(--dropdown_height) * ${inp_obj.options.length})`
            : "var(--dropdown_height)",
        }}
      >
        <div
          className="dropdown_submain"
          style={{
            transform: is_open
              ? `translateY(0)`
              : `translateY(calc(var(--dropdown_height) * -${
                  inp_obj.options.reduce(
                    (prev, curr) =>
                      prev[1]
                        ? prev
                        : [prev[0] + 1, curr.option_key === inp_obj.value],

                    [0, false]
                  )[0] - 1
                }))`,
          }}
        >
          {inp_obj.placeholder && (
            <div className="dropdown_items">{inp_obj.placeholder}</div>
          )}
          {inp_obj.options.map((el, i) => (
            <div
              key={i}
              className={
                "dropdown_items" +
                (el.option_key === inp_obj.value && is_open ? " active" : "") +
                (el.option_key === inp_obj.value ? " selected" : "")
              }
              onClick={() => is_open && inp_obj.on_event(el.option_key)}
            >
              {el.option_value}
            </div>
          ))}
        </div>
        <div
          className="dropdown_arrow"
          svg_invert={
            is_open && inp_obj?.first_item_value === inp_obj.value
              ? "yes"
              : "no"
          }
          test={(
            is_open.toString() +
            "---,---" +
            inp_obj?.first_item_value +
            "---,---" +
            inp_obj.value
          ).toString()}
        >
          <Images img_var="arrow_down" />
        </div>
      </div>
    </div>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const MultiDropdownSelect = ({ inp_obj, custclass,current_inp_obj }) => {
  // console.log(inp_obj?.error);
  return (
    <>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        error={inp_obj?.error || current_inp_obj.error} 
        style={{ width: "100%" }}
        className={
          "multidropdown cust_inp" + (custclass ? ` ${custclass}` : "")
        }
        value={inp_obj.value}
        onChange={({ target }) => {
          // console.log(target);
          
          let temperaryarray = target.value.filter((el) => el != "Select One");
          temperaryarray = temperaryarray.length
            ? temperaryarray
            : ["Select One"];
          console.log(
            inp_obj.options.filter((el) =>
              temperaryarray.includes(el.option_key)
            ),
            "here"
          );
          inp_obj.on_event(temperaryarray);
        }}
        renderValue={(selected) => {
          // console.log(selected);
          return (
            <div
              style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
              className="multidropdown"
            >
              {selected.length == 1 && selected[0] == "Select One" ? (
                <div>Select One</div>
              ) : (
                selected.map((value, i) => (
                  <>
                    <div className="chips" key={i}>
                      {
                        inp_obj?.options?.filter(
                          (el) => el.option_key == value
                        )[0]?.option_value
                      }
                    </div>
                  </>
                ))
              )}
            </div>
          );
        }}
        MenuProps={MenuProps}
      >
        {inp_obj?.options?.map((el) => (
          <MenuItem key={el.option_key} value={el.option_key}>
            {el.option_value}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const SingleDropdownSelect = ({ inp_obj, custclass }) => {
  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={inp_obj.value}
        style={{ width: "100%" }}
        onChange={(event) => {
          inp_obj.on_event(event.target.value);
        }}
        MenuProps={MenuProps}

      >
        {inp_obj?.options?.map((el) => (
          <MenuItem key={el.option_key} value={el.option_key}>
            {el.option_value}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const AutoComplete = ({ inp_obj, current_inp_obj, custclass }) => {
  const [dropdown_style, setdropdown_style] = useState({});
  const dropdown_main = useRef();
  useLayoutEffect(() => {
    let get_rect = dropdown_main?.current?.getBoundingClientRect();
    // console.log(window.innerHeight, get_rect);
    if (window.innerHeight < get_rect.bottom) {
      if (get_rect.top < 200) {
        setdropdown_style({
          top: "unset",
          bottom: "110%",
        });
        // console.log("working");
        return;
      }

      // console.log(get_rect.height - (get_rect.bottom - window.innerHeight));
      setdropdown_style({
        overflowY: "scroll",
        "--maxHeight": `calc(${
          get_rect.height
          //  - (get_rect.bottom - window.innerHeight)
        }px)`,
      });
    } else {
      setdropdown_style({
        overflowY: "scroll",
        // border: "1px solid red",
        "--maxHeight": `calc(${
          100
          //  - (get_rect.bottom - window.innerHeight)
        }px)`,
      });
    }
    return () => {};
  }, []);
  return (
    <div
      className={
        "text_group autocomplete_group cust_inp" +
        (custclass ? ` ${custclass}` : "")
      }
    >
      <label htmlFor={`inp_id${lastId}`}>
        {(inp_obj.title || current_inp_obj.title) && (
          <div className="inp_title">
            {inp_obj.title || current_inp_obj.title}
          </div>
        )}
        <input
          type="text"
          error={(inp_obj?.error || current_inp_obj.error).toString()}
          value={inp_obj.value || current_inp_obj.value}
          onChange={inp_obj.on_event || current_inp_obj.on_event}
          readOnly={inp_obj.readonly || current_inp_obj.readonly}
          placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
          id={`inp_id${lastId}`}
        />
        <div className="autocomplete_dropdown" style={{ ...dropdown_style }}>
          <div ref={dropdown_main}>
            {(
              inp_obj.autocomplete_list || current_inp_obj.autocomplete_list
            ).filter((el) =>
              el
                .toLowerCase()
                .match((inp_obj.value || current_inp_obj.value).toLowerCase())
            ).length === 0 ? (
              <div className="dropdown_options">No Matching items found</div>
            ) : (
              (inp_obj.autocomplete_list || current_inp_obj.autocomplete_list)
                .filter((el) =>
                  el
                    .toLowerCase()
                    .match(
                      (inp_obj.value || current_inp_obj.value).toLowerCase()
                    )
                )
                .map((el) => (
                  <div
                    className="dropdown_options"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      inp_obj.on_event({ target: { value: el } }) ||
                        current_inp_obj.on_event({ target: { value: el } });
                    }}
                  >
                    {el}
                  </div>
                ))
            )}
          </div>
        </div>
        <div
          className={
            "clear_inp" +
            ((inp_obj.value || current_inp_obj.value).length > 0
              ? " active"
              : "")
          }
          onClick={() => {
            inp_obj.on_event({ target: { value: "" } }) ||
              current_inp_obj.on_event({ target: { value: "" } });
          }}
        >
          <div></div>
        </div>
      </label>
    </div>
  );
};
