const default_appdata = {
  loading: true,
  goback: false,
  static: {
    page_animation_data: {
      initial: {
        x: "-100%",
        transition: { duration: 0.3 },
        position: "fixed",
        top: 0,
        right: 0,
      },
      animate: {
        x: 0,
        transition: { duration: 0.3 },
        position: "fixed",
        top: 0,
        right: 0,
      },
      exit: {
        x: "100%",
        transition: { duration: 0.3 },
        position: "fixed",
        top: 0,
        right: 0,
      },
    },
    navbar_data: [
      // {
      //   item: "Login",
      //   logo: "n/a",
      //   link_to: "/",
      // },
      // {
      //   item: "Account",
      //   logo: "account",
      //   link_to: "/account",
      //   page_title: "MMP-Account",
      // },
      {
        item: "Dashboard",
        logo: "dashboard",
        link_to: "/dashboard",
        page_title: "MMP-Dashboard",
      },
      // {
      //   item: "TDashboard",
      //   logo: "dashboard",
      //   link_to: "/test-dashboard",
      //   page_title: "MMP-Dashboard",
      // },
      // {
      //   item: "Documents",
      //   logo: "documents",
      //   link_to: "/documents",
      //   page_title: "MMP-Documents",
      // },
      // {
      //   item: "TDocuments",
      //   logo: "documents",
      //   link_to: "/test-documents",
      //   page_title: "MMP-Documents",
      // },
      {
        item: "Services",
        logo: "services",
        link_to: "/services",
        page_title: "MMP-Services",
      },
      {
        item: "Songs",
        logo: "songs",
        link_to: "/songs",
        page_title: "MMP-Songs",
      },
      {
        item: "Membership",
        logo: "membership",
        link_to: "/membership",
        page_title: "MMP-Membership",
      },
      // {
      //   item: "TMembership",
      //   logo: "membership",
      //   link_to: "/test-membership",
      //   page_title: "MMP-Membership",
      // },
      {
        item: "Add User",
        logo: "add-user",
        link_to: "/add-user",
        page_title: "MMP-AddUser",
      },

      
      // {
      //   item: "About Us",
      //   logo: "about-us",
      //   link_to: "/about-us",
      //   page_title: "MMP-AboutUs",
      // },
    ],
    admin_data: [
      // {
      //   item: "Account",
      //   logo: "account",
      //   link_to: "/account",
      //   page_title: "MMP-Account",
      // },
      {
        item: "Dashboard",
        logo: "dashboard",
        link_to: "/dashboard",
        page_title: "MMP-Dashboard",
      },
      {
        item: "Users",
        logo: "account",
        link_to: "/Users",
        page_title: "MMP-Users",
      },
      {
        item: "Services",
        logo: "services",
        link_to: "/services",
        page_title: "MMP-Services",
      },
      {
        item: "Tickets",
        logo: "ticket",
        link_to: "/Tickets",
        page_title: "MMP-Tickets",
      },
      // {
      //   item: "Miscellaneous",
      //   logo: "ticket",
      //   link_to: "/miscellaneous",
      //   page_title: "MMP-Miscellaneous",
      // },
      {
        item: "Songs",
        logo: "songs",
        link_to: "/songs",
        page_title: "MMP-Songs",
      },
    ],
  },
};
export const appdata = {
  ...default_appdata,
};

const AppData = (state = appdata, { type, payload }) => {
  // console.log('login_reducer', type, payload);
  switch (type) {
    case "get":
      return {
        ...state,

        // admin: false,
      };
    case "set":
      return { ...state, ...default_appdata };
    case "loading":
      return { ...state, loading: true };
    case "loaded":
      return { ...state, loading: false };
    case "gobackactive":
      return { ...state, goback: true };
    case "gobacknotactive":
      return { ...state, goback: false };
    default:
      return state;
  }
};
export default AppData;
