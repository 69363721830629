import React, { useEffect, useLayoutEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { PageHeader } from "../Components/Common/PageHeader/PageHeader";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
import { Images } from "../Components/Common/Images/Images";
import { Button } from "../Components/Custom/Button/Button";
import { CustInput } from "../Components/Custom/CustInput/CustInput";
import {
  checkadmintoken,
  checktoken,
  get_file_size,
} from "../Components/Common/Api/Api";
import { useNavigate } from "react-router-dom";
import { calc_horizontal } from "../Components/Common/utils/utils";

const default_document_list = [
  {
    type: "splitsheet",
    title: "SPLIT SHEETS",
    documents: [
      {
        name: "Song Title One",
        download_link: "Pay to download",
      },
      {
        name: "Song Title Two",
        download_link: "Pay to download",
      },
      {
        name: "Song Title Three",
        download_link: "Download",
      },
      {
        name: "Song Title Four",
        download_link: "Download",
      },
      {
        name: "Song Title Five",
        download_link: "Download",
      },
    ],
  },
  {
    type: "songregistration",
    title: "Song Registration",
    documents: [
      {
        name: "song-1",
        id: "0000001",
        doc_aggrrements: [
          {
            name: "aggrement-1.1",
            download_link: "link",
          },
          {
            name: "aggrement-1.2",
            download_link: "link",
          },
        ],
      },
      {
        name: "song-2",
        id: "0000002",
        doc_aggrrements: [
          {
            name: "aggrement-2.1",
            download_link: "link",
          },
        ],
      },
    ],
  },
  {
    type: "isrc",
    title: "ISRC",
    download_link: "link",
  },
  {
    type: "splitsheet",
    title: "SPLIT SHEETS",
    documents: [
      {
        name: "Song Title One",
        download_link: "Pay to download",
      },
      {
        name: "Song Title Two",
        download_link: "Pay to download",
      },
      {
        name: "Song Title Three",
        download_link: "Download",
      },
    ],
  },
  {
    type: "songregistration",
    title: "Song Registration",
    documents: [
      {
        name: "song-1",
        id: "0000001",
        doc_aggrrements: [
          {
            name: "aggrement-1.1",
            download_link: "link",
          },
          {
            name: "aggrement-1.2",
            download_link: "link",
          },
        ],
      },
      {
        name: "song-2",
        id: "0000002",
        doc_aggrrements: [
          {
            name: "aggrement-2.1",
            download_link: "link",
          },
        ],
      },
    ],
  },
  {
    type: "isrc",
    title: "ISRC",
    download_link: "link",
  },
  {
    type: "splitsheet",
    title: "SPLIT SHEETS",
    documents: [
      {
        name: "Song Title One",
        download_link: "Pay to download",
      },
      {
        name: "Song Title Two",
        download_link: "Pay to download",
      },
      {
        name: "Song Title Three",
        download_link: "Download",
      },
    ],
  },
  {
    type: "songregistration",
    title: "Song Registration",
    documents: [
      {
        name: "song-1",
        id: "0000001",
        doc_aggrrements: [
          {
            name: "aggrement-1.1",
            download_link: "link",
          },
          {
            name: "aggrement-1.2",
            download_link: "link",
          },
        ],
      },
      {
        name: "song-2",
        id: "0000002",
        doc_aggrrements: [
          {
            name: "aggrement-2.1",
            download_link: "link",
          },
        ],
      },
    ],
  },
  {
    type: "isrc",
    title: "ISRC",
    download_link: "link",
  },
];
const requirement = [
  {
    name: "Writer One",
    percentage_share: "2.00%",
    authorized: true,
  },
  {
    name: "Writer One",
    percentage_share: "2.00%",
    authorized: true,
  },
  {
    name: "Writer One",
    percentage_share: "2.00%",
    authorized: true,
  },
  {
    name: "Writer One",
    percentage_share: "2.00%",
    authorized: true,
  },
];
export const Documents = ({ handelnav_active }) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  const [page_values, setpage_values] = useState({
    // user_name: current_store.user?.data?.name || "",
    first_name: current_store.user?.data?.name || "",
    // points: current_store.user?.data?.songs_analytics?.graph_plot || [],
    // songs_count: current_store.user?.data?.songs_analytics?.count || "-",
    // songs_percentile_last_month:
    //   current_store.user?.data?.songs_analytics?.since_last_month || "-",
    // pending_song_collaboration:
    //   current_store.user?.data?.pending_song_collaboration || "-",
    // membershin_ends_in: current_store.user?.data?.membershin_ends_in || "-",
  });
  // console.log(current_store?.user?.data?.user_data?.documents || "");
  const [page_data, setpage_data] = useState({
    document_list: [],
  });
  const dispatch = useDispatch();
  const [FilterDataDocument, setFilterDataDocument] = useState([]);
  const [current_active_document, setcurrent_active_document] = useState(0);
  useLayoutEffect(() => {
    handelnav_active(true);
    return () => {
      // handelnav_active(false);
    };
  }, []);
  useEffect(() => {
    setFilterDataDocument(
      Object.entries(
        page_data.document_list.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.service_id]: prev[curr.service_id]
              ? [...prev[curr.service_id], curr]
              : [curr],
          };
        }, {})
      ).map((el) => ({
        name: page_data.service_list.filter((el1) => el1.id == el[0])[0].name,
        document: el[1],
      }))
    );
    //   page_data.document_list.map((el)=>el.service_id).filter((el) => el == el)

    //   for (let i = 0 ; i < page_data.document_list ; i++){

    //   }
  }, [page_data]);

  useEffect(() => {
    setpage_values({
      user_name: current_store.user?.data?.user_data?.name || "-",
      name: current_store.user?.data?.user_data?.name || "-",
    });
  }, [current_store]);
  useEffect(() => {
    setpage_data({
      document_list: [
        ...(current_store?.user?.data?.user_data?.documents || ""),
      ],
      service_list: [...(current_store?.user?.data?.user_data?.services || "")],
    });
  }, [current_store]);

  useEffect(() => {
    // return;
    if (current_store.user.token == "") {
      const local_token = localStorage.getItem("MMP");
      // console.log(local_token);
      // document.cookie = "mmp=" + local_token;

      if (JSON.stringify(local_token)) {
        dispatch({
          type: "loading",
        });

        checktoken(({ status, response }) => {
          // console.log(response);
          if (status) {
            dispatch({
              type: "login",
              payload: {
                admin: false,
                data: { ...response.app_data },
                token: local_token,
              },
            });
            dispatch({
              type: "loaded",
            });
          } else {
            checkadmintoken(({ status, response1 }) => {
              // console.log(response1);
              if (status) {
                dispatch({
                  type: "login",
                  payload: {
                    admin: true,
                    data: { ...response1.app_data },
                    token: response1.token,
                  },
                });
                dispatch({
                  type: "loaded",
                });
              } else {
                // document.cookie = "mmp=";
                localStorage.setItem("MMP", "");
                dispatch({
                  type: "loaded",
                });
                navigate("/");
              }
            });
          }
        });
      } else {
        navigate("/");
      }
    }
    // else{
    //   // navigate("/")
    // }
  }, []);
  useEffect(() => {
    localStorage.setItem("SpecialForMobileSong", false);
  }, []);
  return (
    <motion.div
      className="not_navbar documents_page"
      {...(current_store.appdata.static.page_animation_data || {})}
    >
      {window.innerWidth <= 500 ? (
        <DocumentsMobileChange />
      ) : (
        <>
          <PageHeader
            user_name={page_values.name}
            current_page={"Documents"}
            show_logo={true}
          />

          <div className="document_main">
            <div className="title">
              Best Music Publishing Resource For Songwriters & Musicians
            </div>
            <div className="description1">
              Create Music, Agree to Ownership Rights, Input Necessary Song &
              Collaborator data into an Electronic Song Split Sheet to record
              songwriter, musician and producer participation in new music
              creation.
            </div>
            <div className="description2">
              This tool assures proper credit and payment to song creators and
              record labels.
            </div>
            <div className="document_group">
              {FilterDataDocument.length <= 0
                ? <div style={{textAlign : 'center'}}>No Document</div>
                : FilterDataDocument.map((el, i) => (
                    <DocumentCard
                      card_data={el}
                      key={i}
                      cust_key={i}
                      is_active={current_active_document}
                      setis_active={(key) => setcurrent_active_document(key)}
                      page_data={page_data}
                    />
                  ))}
            </div>
            {/* <Button
              custclass="splitsheet_btn"
              type="type1"
              btn_obj={{
                txt: "Song Colab Auth",
                // img: <Images img_var="date_clear" />,

                onclick: () => {
                  dispatch({
                    type: "setmodal",
                    payload: {
                      modal_isopen: true,
                      modal_type: "SongColabAuth",
                      modal_data: {},
                    },
                  });
                },
                loading: false,
                show_type: "",
              }}
            />
            <Button
              custclass="splitsheet_btn"
              type="type1"
              btn_obj={{
                txt: "Require Signature",
                // img: <Images img_var="date_clear" />,

                onclick: () => {
                  dispatch({
                    type: "setmodal",
                    payload: {
                      modal_isopen: true,
                      modal_type: "RequireSignature",
                      modal_data: { ...requirement },
                    },
                  });
                },
                loading: false,
                show_type: "",
              }}
            />
            <Button
              custclass="splitsheet_btn"
              type="type1"
              btn_obj={{
                txt: "Song Writer List",
                // img: <Images img_var="date_clear" />,

                onclick: () => {
                  dispatch({
                    type: "setmodal",
                    payload: {
                      modal_isopen: true,
                      modal_type: "Accordian",
                      modal_data: { ...requirement },
                    },
                  });
                },
                loading: false,
                show_type: "",
              }}
            /> */}
          </div>
        </>
      )}
    </motion.div>
  );
};
const DocumentCard = ({
  card_data,
  page_data,
  cust_key,
  is_active,
  setis_active,
}) => {
  // const [is_active, setis_active] = useState(false);
  const navigate = useNavigate();
  const card_body_main = useRef();

  // let name = page_data?.service_list
  //   .filter((el) => el.id.includes(card_data.service_id))
  //   .map((l) => l.name);
  // console.log(len, "hghghghghghg");
  return (
    <div className="document_card">
      <div
        className="card_header"
        onClick={() => {
          // setis_active((prev) => (prev === cust_key ? "" : cust_key))
          // localStorage.setItem("SplitSheetDirection", true);
          // navigate("/songs");
        }}
      >
        <Images img_var="box_tick" />
        <div className="card_name">{card_data.name}</div>
        {card_data.document && (
          <div className="card_document_count">
            {card_data.document.length} document
            {card_data.document.length > 1 && "s"}
          </div>
        )}
        {/* <div className="card_arrow">
          <Images img_var="arrow_down" />
        </div>  */}
      </div>
      <div
        // className={"card_body" + (is_active === cust_key ? " active" : "")}
        className={"card_body"}
        // style={{
        //   height:
        //     is_active === cust_key
        //       ? card_body_main?.current?.getBoundingClientRect().height || 0
        //       : 0,
        // }}
        // type={card_data.type}
      >
        <ul>
          {card_data.document.map((el, i) => {
            // console.log(el.created_at || "");
            let date = el.created_at
              ? el.created_at.split("T")[0].split("-")
              : "";
            return (
              <>
                <div className="documentworking">
                  {/* <ol> */}
                  <li style={{ listStyleType: "none" }}>
                    {i + 1}. {card_data.name + " "}{" "}
                  </li>
                  {/* </ol> */}
                  {/* </ol> */}
                  <span>
                    {date ? `${date[1]} / ${date[2]} / ${date[0]}` : "-"}
                  </span>
                  <a href={el.document_link} target="_blank">
                    <Images img_var={"documentdownload"} />
                  </a>
                </div>
              </>
            );
          })}
        </ul>
        {/* {card_data.document && (
          <div className="card_body_main" ref={card_body_main}>
            {card_data.type == "splitsheet" ? (
              <SplitsheetsBody data={card_data.document} />
            ) : // ""
            card_data.type == "songregistration" ? (
              <SongregistrationBody data={card_data.document} />
            ) : (
              ""
            )}
          </div>
        )} */}
      </div>
      {/* lllllllllllllllllllllllllll */}
    </div>
  );
};

const SplitsheetsBody = ({ data }) => {
  return (
    <>
      {data.map((el, i) => (
        <div key={i} className="document_items">
          <div>{el.name}</div>
          {el.download_link == "Pay to download" ? (
            <Button
              custclass="splitsheet_btn"
              type="type2"
              btn_obj={{
                txt: "Pay to Download",
                // img: <Images img_var="date_clear" />,
                onclick: () => {},
                loading: false,
                show_type: "",
              }}
            />
          ) : (
            <Button
              custclass="splitsheet_btn"
              type="type2"
              btn_obj={{
                txt: "Download",
                // img: <Images img_var="date_clear" />,
                onclick: () => {},
                loading: false,
                show_type: "",
              }}
            />
          )}
        </div>
      ))}
    </>
  );
};
const SongregistrationBody = ({ data }) => {
  return (
    <>
      {data.map((el, i) => (
        <div key={i} className="document_items">
          <div>{el.name}</div>
          {el.download_link == "Pay to download" ? (
            <Button
              custclass="splitsheet_btn"
              type="type2"
              btn_obj={{
                txt: "Pay to Download",
                // img: <Images img_var="date_clear" />,
                onclick: () => {},
                loading: false,
                show_type: "",
              }}
            />
          ) : (
            <Button
              custclass="splitsheet_btn"
              type="type2"
              btn_obj={{
                txt: "Download",
                // img: <Images img_var="date_clear" />,
                onclick: () => {},
                loading: false,
                show_type: "",
              }}
            />
          )}
        </div>
      ))}
    </>
  );
};

export const DocumentsMobile = () => {
  const current_store = useSelector((state) => state);
  const [FilterDataDocument, setFilterDataDocument] = useState([]);
  const [page_data, setpage_data] = useState({
    song_filter: "",
    search: "",
    document_list: [],
  });

  const [filter_isOpen, setfilter_isOpen] = useState(false);
  const [ListOfDocument, setListOfDocument] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilterDataDocument(
      Object.entries(
        page_data.document_list.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.service_id]: prev[curr.service_id]
              ? [...prev[curr.service_id], curr]
              : [curr],
          };
        }, {})
      ).map((el) => ({
        name: page_data.service_list.filter((el1) => el1.id == el[0])[0].name,
        document: el[1],
      }))
    );
    //   page_data.document_list.map((el)=>el.service_id).filter((el) => el == el)

    //   for (let i = 0 ; i < page_data.document_list ; i++){

    //   }
  }, [page_data]);
  useEffect(() => {
    setpage_data({
      song_filter: "",
      search: "",
      document_list: [
        ...(current_store?.user?.data?.user_data?.documents || ""),
      ],
      service_list: [...(current_store?.user?.data?.user_data?.services || "")],
    });
  }, [current_store]);

  return (
    <>
      <div
        className=""
        style={{
          height: "100vh",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          background: "#fff",
        }}
      >
        <div style={{ flexGrow: 1, padding: "20px 0 0 0" }}>
          <CustInput
            custclass="song_filter_search"
            type="search_with_filter"
            inp_obj={{
              title: "All your documents are safe!",
              placeholder: "Search by service name",
              value: page_data.search,
              on_event: ({ target }) => {
                setpage_data((prev) => ({
                  ...prev,
                  search: target.value,
                }));
              },
            }}
          />
          <MobileSongFilter
            {...{
              song_filter: page_data.song_filter,
              search: page_data.search,
              show_cards: [
                {
                  title: "",
                  arr: [...(FilterDataDocument || "")].map((el) => ({
                    ...el,
                    btn: (
                      <div
                        className="btn_mobile_song_handler"
                        onClick={() => {
                          // console.log(el);
                          if (el.document) {
                            dispatch({
                              type: "setmodal",
                              payload: {
                                modal_isopen: true,
                                modal_type: "ListOfDocument",
                                modal_data: {
                                  data: el,
                                },
                              },
                            });
                          }
                        }}
                      >
                        {el ? (
                          el?.document?.length
                        ) : (
                          <Images img_var={"edit_pencil"} />
                        )}
                      </div>
                    ),
                  })),
                },
              ],
            }}
          />
        </div>
      </div>
      {/* <IndiVidualDocumentPage /> */}
    </>
  );
};

const MobileSongFilter = ({ song_filter, search, show_cards }) => {
  const current_store = useSelector((state) => state);

  const [show_all, setshow_all] = useState(false);
  const [height, setheight] = useState(0);
  const [height_final, setheight_final] = useState({});
  useEffect(() => {
    setshow_all(false);
  }, []);
  // let name = current_store?.user?.data?.user_data?.services
  //   .filter((el) => el.id.includes())
  //   .map((l) => l.name);
  // console.log(show_cards[0].arr);

  useLayoutEffect(() => {
    // console.log(
    //   document
    //     ?.getElementById("get_height_song_subitem")
    //     ?.getBoundingClientRect()?.height || 0
    // );
    setheight(
      document
        ?.getElementById("get_height_song_subitem1")
        ?.getBoundingClientRect()?.height || 0
    );
    let temp_obj = {};
    show_cards[0].arr.map((el) => {
      // console.log(
      //   document
      //     ?.getElementById("get_height_" + el.title.split(" ").join(""))
      //     ?.getBoundingClientRect()?.height
      // );
      temp_obj[el.title] =
        document
          ?.getElementById("get_height_" + el.title.split(" ").join(""))
          ?.getBoundingClientRect()?.height || 0;
    });
    // console.log(temp_obj);
    setheight_final(temp_obj);
    setheight_final({});
  }, []);
  // const song_item = useRef();
  if (song_filter) {
    return (
      <div className="mobile_song_filter">
        {show_cards
          .filter((el) => el.title === song_filter)
          .map((el, i) => {
            let filtered_arr = el.arr.filter((el) =>
              Object.values(el)
                .join(", ")
                .toLowerCase()
                .match(search.toLowerCase())
            );
            // console.log(filtered_arr);
            // if (filtered_arr.length === 0) {
            //   return "";
            // }
            return (
              <div className="song_items">
                <div className="song_items_header">
                  <div>{el.title}</div>
                  <div
                    className="see_all"
                    onClick={() => {
                      setshow_all((prev) =>
                        prev === `show${i}` ? "" : `show${i}`
                      );
                    }}
                  >
                    {/* {filtered_arr.length > 3
                      ? show_all === `show${i}`
                        ? "See Less"
                        : "See All"
                      : ""} */}
                  </div>
                </div>
                <div
                  className="items_group_main"
                  style={{
                    height: "auto",
                    // show_all === `show${i}` ? height_final[el.title] : height,
                  }}
                >
                  <div
                    className="items_group"
                    id={"get_height_" + el.title.split(" ").join("")}
                  >
                    {filtered_arr.map((el1, i1) =>
                      console.log(el1)(i1 === 3 && i === 0) ? (
                        <div id="get_height_song_subitem1">{el1.name}</div>
                      ) : (
                        <div>{el1.name}</div>
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
  return (
    <div className="mobile_song_filter">
      {show_cards.map((el, i) => {
        let filtered_arr = el?.arr?.filter((el) =>
          Object.values(el)
            ?.join(", ")
            ?.toLowerCase()
            .match(search.toLowerCase())
        );

        if (filtered_arr.length === 0) {
          return "";
        }
        return (
          <div className="song_items">
            {/* {console.log(el.arr)} */}
            <div className="song_items_header">
              <div>{el.title}</div>
              <div
                className="see_all"
                onClick={() => {
                  setshow_all((prev) =>
                    prev === `show${i}` ? "" : `show${i}`
                  );
                }}
              >
                {/* {filtered_arr.length > 3
                  ? show_all === `show${i}`
                    ? "See Less"
                    : "See All"
                  : ""} */}
              </div>
            </div>
            <div
              className="items_group_main"
              style={{
                height: "auto",
              }}
            >
              <div
                className="items_group_document"
                id={"get_height_" + el.title.split(" ").join("")}
              >
                {filtered_arr.map((el1, i1) =>
                  i1 === 0 && i === 0 ? (
                    <>
                      <div
                        id="get_height_song_subitem1"
                        className="handler_for_btn_overall"
                      >
                        {/* {console.log(el.arr)} */}
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "100px",
                            color: "white",
                          }}
                        >
                          {el1.name}
                        </span>
                        {el1.btn || ""}
                        {/* KKK */}
                        {/* <div className="btn_mobile_song_handler">X</div> */}
                      </div>
                    </>
                  ) : (
                    <div className="handler_for_btn_overall">
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "100px",
                          color: "white",
                        }}
                      >
                        {el1.name}
                      </span>
                      {el1.btn || ""}
                      {/* <div className="btn_mobile_song_handler">X</div> */}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const IndiVidualDocumentPage = ({ data, close }) => {
  const current_store = useSelector((state) => state);

  // console.log(current_store?.user?.data?.user_data?.photo);
  return (
    <>
      <div className="listofDocument">
        <div className="inner_list_Document">
          <div className="inner_list_Document_list">
            <div
              onClick={() => {
                close();
              }}
              className=""
            >
              <Images img_var="left_side_arrow" />
            </div>
            <div className="inner_list_Document_list1">Split sheet</div>
            <div
              className="inner_list_Document_photo"
              style={{
                backgroundImage: `url(${
                  current_store?.user?.data?.user_data?.photo || ""
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
          <div className="inner_list_document_title">
            List of Documents ({data.document.length})
          </div>
          {data.document.map((e, i) => {
            // console.log(data.name);
            return (
              <>
                <div key={i} className="service_handler_main_mobile">
                  <div>
                    {data?.name} {i + 1}
                  </div>
                  <div className="edit_btn_media">
                    {/* {i < 3 ? ( */}
                    <a href={e.document_link} target="_blank">
                      <Images img_var="download" />
                    </a>
                    {/* ) : ( */}
                    {/* <Images img_var="paymentcard" /> */}
                    {/* )} */}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const DocumentsMobileChange = () => {
  const current_store = useSelector((state) => state);
  const [FilterDataDocument, setFilterDataDocument] = useState([]);
  const [page_data, setpage_data] = useState({
    song_filter: "",
    search: "",
    document_list: [],
  });
  useEffect(() => {
    localStorage.setItem("SpecialForMobileSong", false);
  }, []);
  const [filter_isOpen, setfilter_isOpen] = useState(false);
  const [ListOfDocument, setListOfDocument] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilterDataDocument(
      // Object.entries(
      [...page_data.document_list]
      // .reduce((prev, curr) => {
      // return {
      //   ...prev,
      //   [curr.service_id]: prev[curr.service_id]
      //     ? [...prev[curr.service_id], curr]
      //     : [curr],
      // };
      //   }, {})
      // ).map((el) => ({
      //   name: page_data.service_list.filter((el1) => el1.id == el[0])[0].name,
      //   document: el[1],
      // }))
    );
    //   page_data.document_list.map((el)=>el.service_id).filter((el) => el == el)

    //   for (let i = 0 ; i < page_data.document_list ; i++){

    //   }
  }, [page_data]);
  // console.log(FilterDataDocument);
  useEffect(() => {
    setpage_data({
      song_filter: "",
      search: "",
      document_list: [
        ...(current_store?.user?.data?.user_data?.documents || ""),
      ],
      service_list: [...(current_store?.user?.data?.user_data?.services || "")],
    });
  }, [current_store]);
  // console.log(page_data.service_list);
  return (
    <>
      <div
        className=""
        style={{
          height: "100vh",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          background: "#fff",
        }}
      >
        <div style={{ flexGrow: 1, padding: "20px 0 0 0" }}>
          <CustInput
            custclass="song_filter_search"
            type="search_with_filter"
            inp_obj={{
              title: "All your documents are safe!",
              placeholder: "Search by service name",
              value: page_data.search,
              on_event: ({ target }) => {
                setpage_data((prev) => ({
                  ...prev,
                  search: target.value,
                }));
              },
            }}
          />
          <MobileSongFilterChange
            {...{
              song_filter: page_data.song_filter,
              search: page_data.search,
              service_name: page_data.service_list,
              show_cards: [
                {
                  title: "",
                  arr: [...(FilterDataDocument || "")].map((el) => ({
                    ...el,
                    btn: (
                      <div
                        className="btn_mobile_song_handler"
                        onClick={() => {
                          // console.log(el);
                          if (el.document) {
                            dispatch({
                              type: "setmodal",
                              payload: {
                                modal_isopen: true,
                                modal_type: "ListOfDocument",
                                modal_data: {
                                  data: el,
                                },
                              },
                            });
                          }
                        }}
                      >
                        {el ? (
                          el?.document?.length
                        ) : (
                          <Images img_var={"edit_pencil"} />
                        )}
                      </div>
                    ),
                  })),
                },
              ],
            }}
          />
        </div>
      </div>
      {/* <IndiVidualDocumentPage /> */}
    </>
  );
};

const MobileSongFilterChange = ({
  song_filter,
  search,
  show_cards,
  service_name,
}) => {
  const current_store = useSelector((state) => state);

  const [show_all, setshow_all] = useState(false);
  const [height, setheight] = useState(0);
  const [height_final, setheight_final] = useState({});
  useEffect(() => {
    setshow_all(false);
  }, []);
  // let name = current_store?.user?.data?.user_data?.services
  //   .filter((el) => el.id.includes())
  //   .map((l) => l.name);
  // console.log(show_cards[0].arr);

  useLayoutEffect(() => {
    // console.log(
    //   document
    //     ?.getElementById("get_height_song_subitem")
    //     ?.getBoundingClientRect()?.height || 0
    // );
    setheight(
      document
        ?.getElementById("get_height_song_subitem1")
        ?.getBoundingClientRect()?.height || 0
    );
    let temp_obj = {};
    show_cards[0].arr.map((el) => {
      // console.log(
      //   document
      //     ?.getElementById("get_height_" + el.title.split(" ").join(""))
      //     ?.getBoundingClientRect()?.height
      // );
      temp_obj[el.title] =
        document
          ?.getElementById("get_height_" + el.title.split(" ").join(""))
          ?.getBoundingClientRect()?.height || 0;
    });
    // console.log(temp_obj);
    setheight_final(temp_obj);
    setheight_final({});
  }, []);
  // const song_item = useRef();
  // console.log(song_filter);
  if (song_filter) {
    return (
      <div className="mobile_song_filterChange">
        {show_cards
          .filter((el) => el.title === song_filter)
          .map((el, i) => {
            let filtered_arr = el.arr.filter((el) =>
              Object.values(el)
                .join(", ")
                .toLowerCase()
                .match(search.toLowerCase())
            );
            // console.log(filtered_arr);
            // if (filtered_arr.length === 0) {
            //   return "";
            // }
            return (
              <div className="song_items">
                <div className="song_items_header">
                  <div>{el.title}</div>
                  <div
                    className="see_all"
                    onClick={() => {
                      setshow_all((prev) =>
                        prev === `show${i}` ? "" : `show${i}`
                      );
                    }}
                  >
                    {/* {filtered_arr.length > 3
                      ? show_all === `show${i}`
                        ? "See Less"
                        : "See All"
                      : ""} */}
                  </div>
                </div>
                <div
                  className="items_group_main"
                  style={{
                    height: "auto",
                    // show_all === `show${i}` ? height_final[el.title] : height,
                  }}
                >
                  <div
                    className="items_group"
                    id={"get_height_" + el.title.split(" ").join("")}
                  >
                    {filtered_arr.map((el1, i1) =>
                      console.log(el1)(i1 === 3 && i === 0) ? (
                        <div id="get_height_song_subitem1">{el1.name}</div>
                      ) : (
                        <div>{el1.name}</div>
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
  return (
    <div className="mobile_song_filterChange">
      {show_cards.map((el, i) => {
        let filtered_arr = el?.arr?.filter((el) =>
          Object.values(el)
            ?.join(", ")
            ?.toLowerCase()
            .match(search.toLowerCase())
        );

        if (filtered_arr.length === 0) {
          return "";
        }
        return (
          <div className="song_items">
            <div className="song_items_header">
              <div>{el.title}</div>
              <div
                className="see_all"
                onClick={() => {
                  setshow_all((prev) =>
                    prev === `show${i}` ? "" : `show${i}`
                  );
                }}
              >
                {/* {filtered_arr.length > 3
                  ? show_all === `show${i}`
                    ? "See Less"
                    : "See All"
                  : ""} */}
              </div>
            </div>
            <div
              className="items_group_main"
              style={{
                height: "auto",
                paddingBottom: calc_horizontal(20),
              }}
            >
              <div
                className="items_group_document"
                id={"get_height_" + el.title.split(" ").join("")}
              >
                {filtered_arr.map((el1, i1) =>
                  i1 === 0 && i === 0 ? (
                    <>
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href={el1.document_link}
                      >
                        <div
                          id="get_height_song_subitem1"
                          className="handler_for_btn_overall"
                        >
                          {/* <div className="inner_items_group_document"> */}
                          {/* {console.log(el.arr)} */}
                          <div>
                            <div className="inner_item_date">
                              {el1?.created_at?.split("T")[0].split("-")[1]}/
                              {el1?.created_at?.split("T")[0].split("-")[2]}/
                              {el1?.created_at?.split("T")[0].split("-")[0]}
                            </div>
                            <div className="inner_item_name">
                              {
                                service_name.filter(
                                  (el) => el.id == el1.service_id
                                )[0].name
                              }
                            </div>
                            <div>
                              {/* {get_file_size(el1.document_link, ({ status, response }) => {
                              if (status) {
                                console.log(response);
                              }
                            })} */}
                            
                            </div>
                          </div>
                          {/* {el1.btn || ""} */}
                          {/* KKK */}
                          <div className="btn_mobile_song_handler">
                            <a href={el1.document_link} target="_blank">
                              <Images img_var={"download"} />
                            </a>
                          </div>
                          {/* </div> */}
                        </div>
                      </a>
                    </>
                  ) : (
                    <a
                      style={{ textDecoration: "none", color: "black" }}
                      href={el1.document_link}
                    >
                      <div className="handler_for_btn_overall">
                        {/* <div className="inner_items_group_document"> */}
                        {/* <span>{el1}</span> */}
                        {/* <span */}
                        {/* // style={{
                          //   overflow: "hidden",
                          //   textOverflow: "ellipsis",
                          //   whiteSpace: "nowrap",
                          //   width: calc_horizontal(200),
                        //   // color:"white"
                        // }}
                      // > */}

                        <div>
                          <div className="inner_item_date">
                            {el1?.created_at?.split("T")[0].split("-")[1]}/
                            {el1?.created_at?.split("T")[0].split("-")[2]}/
                            {el1?.created_at?.split("T")[0].split("-")[0]}
                          </div>
                          <div className="inner_item_name">
                            {
                              service_name.filter(
                                (el) => el.id == el1.service_id
                              )[0].name
                            }
                          </div>
                          <div>
                            
                          </div>
                        </div>
                        {/* </span> */}
                        {/* {el1.btn || ""} */}
                        {/* </div> */}
                        <div className="btn_mobile_song_handler">
                          <a href={el1.document_link} target="_blank">
                            <Images img_var={"download"} />
                          </a>
                        </div>
                      </div>
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
