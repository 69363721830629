import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ImageToLink } from "../../../Common/Api/Api";
import { calc_horizontal } from "../../../Common/utils/utils";
import { Button } from "../../../Custom/Button/Button";

export const CommonData = ({
  inp_obj,
  HandlerAccountEdit,
  setHandlerAccountEdit,
  setHandlerAccountEditArray,
  setViewUser,
  ViewUser,setCurrent_main_page
}) => {
  const current_inp_obj = {
    photo_link: "",
    name: "",
    email: "",
    created_by: "",
    tickets: "",
    link: "",
  };

  // useEffect(() => {
  //   if(FileDocum){
  //     ImageToLink(FileDocum ,"profileimages",({status, response})=>{
  //       console.log(response);
  //       if(status){
  //         console.log(response);
  //       }
  //     })
  //   }
  // }, [FileDocum])
  // useEffect(() => {
  //   // localStorage.getItem("navTicket")
  //  if(localStorage.getItem("navTicket") == "true"){
  //   dispatch({
  //     type: "setmodal",
  //     payload: {
  //       modal_isopen: true,
  //       modal_type: "TicketModal",
  //       modal_data: {},
  //     },
  //   });
  //   localStorage.setItem("navTicket" ,false)
  //  }
  // }, [])

  const dispatch = useDispatch();
  // console.log(FileDocum);
  // console.log(inp_obj);
  // console.log(new Date(inp_obj.created_by));
  const month = inp_obj.created_by.split("T")[0].split("-")[1] || "-";
  const year = inp_obj.created_by.split("T")[0].split("-")[0] || "-";
  const day = inp_obj.created_by.split("T")[0].split("-")[2] || "-";
  return (
    <>
      <div className="alluser_main">
        <div className="main_img_holder">
          {(inp_obj.photo_link || current_inp_obj.photo_link) && (
            <img
              src={inp_obj.photo_link || current_inp_obj.photo_link}
              className="img_holder"
            />
          )}
        </div>

        <div className="name_email_holder">
          {(inp_obj.name || current_inp_obj.name) && (
            <div className="name_tag">
              {inp_obj.name || current_inp_obj.name}
            </div>
          )}

          {(inp_obj.email || current_inp_obj.email) && (
            <div className="email_tag">
              {inp_obj.email || current_inp_obj.email}
            </div>
          )}
        </div>
        {(inp_obj.created_by || current_inp_obj.created_by) && (
          <div className="sign_up_date">
            {month + "/" + day + "/" + year || current_inp_obj.created_by}
          </div>
        )}
        {/* {console.log(month + "/" + day + "/" + year)} */}
        {/* {new Date(inp_obj.created_by)} */}
        {inp_obj.type == "tickets" ? (
          <>
            {/* <label htmlFor="tricket_file">  */}
            <Button
              type="type1"
              custclass="edit_btn"
              btn_obj={{
                txt: inp_obj.link.document ? "View" : "Upload",
                onclick: () => {
                  dispatch({
                    type: "setmodal",
                    payload: {
                      modal_isopen: true,
                      modal_type: "TicketModal",
                      modal_data: { ...inp_obj },
                    },
                  });
                },
                loading: false,
                show_type: "",
              }}
            />
            {/* <input
                type="file"
                className={"add_img_senior"}
                accept=".pdf,.docx,.doc"
                onChange={onSelectFile}
                id="tricket_file"
              />
            </label> */}
            {/* <Button
            type="type1"
            custclass="edit_btn"
            btn_obj={{
              txt: "View",
              onclick: () => {
               
              },
              loading: false,
              show_type: "",
            }}
          /> */}
          </>
        ) : (
          <Button
            type="type1"
            custclass="edit_btn"
            btn_obj={{
              txt: "View",
              onclick: () => {
                setViewUser(true);
                setCurrent_main_page(true)
                // setHandlerAccountEdit(!HandlerAccountEdit);
                setHandlerAccountEditArray(inp_obj);
                // console.log(inp_obj);
              },
              loading: false,
              show_type: "",
            }}
          />
        )}
      </div>
    </>
  );
};

export const CommonDataDashboard = ({ inp_obj }) => {
  const current_inp_obj = {
    photo_link: "",
    name: "",
    email: "",
    created_by: "",
  };
  const navigate = useNavigate();
  // console.log(inp_obj);
  // console.log(new Date(inp_obj.created_by));
  const month = inp_obj.created_by.split("T")[0].split("-")[1] || "-";
  const year = inp_obj.created_by.split("T")[0].split("-")[0] || "-";
  const day = inp_obj.created_by.split("T")[0].split("-")[2] || "-";
  return (
    <>
      <div className="alluser_main">
        <div className="main_img_holder">
          {(inp_obj.photo_link || current_inp_obj.photo_link) && (
            <img
              src={inp_obj.photo_link || current_inp_obj.photo_link}
              className="img_holder"
            />
          )}
        </div>
        <div className="name_email_holder">
          {(inp_obj.name || current_inp_obj.name) && (
            <div className="name_tag">
              {inp_obj.name || current_inp_obj.name}
            </div>
          )}

          {(inp_obj.email || current_inp_obj.email) && (
            <div className="email_tag">
              {inp_obj.email || current_inp_obj.email}
            </div>
          )}
        </div>
        {(inp_obj.created_by || current_inp_obj.created_by) && (
          <div className="sign_up_date">
            {month + "/" + day + "/" + year || current_inp_obj.created_by}
          </div>
        )}
        {/* {console.log(month + "/" + day + "/" + year)} */}
        {/* {new Date(inp_obj.created_by)} */}
        <Button
          type="type1"
          custclass="edit_btn"
          btn_obj={{
            txt: "view",
            onclick: () => {
              localStorage.setItem("pathUserView", true);
              localStorage.setItem("UserViewData", JSON.stringify(inp_obj.id));
              navigate("/Users");
            },
            loading: false,
            show_type: "",
          }}
        />
      </div>
    </>
  );
};

export const CommonData1 = ({ inp_obj }) => {
  const current_inp_obj = {
    name: "",
    email: "",
    signudate: "",
    id: "",
    // link: "",
    // email : "",
    // created_by : "",
  };
  const navigate = useNavigate();
  // console.log(inp_obj);
  const month = inp_obj.signudate.split("T")[0].split("-")[1] || "-";
  const year = inp_obj.signudate.split("T")[0].split("-")[0] || "-";
  const day = inp_obj.signudate.split("T")[0].split("-")[2] || "-";
  return (
    <>
      <div className="alluser_main1">
        <div className="name_email_holder1">
          {(inp_obj.name || current_inp_obj.name) && (
            <div
              className="name_tag"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: calc_horizontal(150),
              }}
            >
              {inp_obj.name || current_inp_obj.name}
            </div>
          )}

          {(inp_obj.email || current_inp_obj.email) && (
            <div className="email_tag">
              {inp_obj.email || current_inp_obj.email}
            </div>
          )}
        </div>
        {(inp_obj.signudate || current_inp_obj.signudate) && (
          <div className="sign_up_date1">
            {month + "/" + day + "/" + year || current_inp_obj.signudate}
          </div>
        )}
        <a href={inp_obj.link} style={{ textDecoration: "none" }}>
          <Button
            type="type1"
            custclass="edit_btn"
            btn_obj={{
              txt: "Upload",
              onclick: () => {
                localStorage.setItem("navTicket", true);
                localStorage.setItem("navTicketID", inp_obj.id);
                navigate("/Tickets");
              },
              loading: false,
              show_type: "",
            }}
          />
        </a>
      </div>
    </>
  );
};
