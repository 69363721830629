import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editsongwriter } from "../../Common/Api/Api";
import { Button } from "../Button/Button";
import { CustInput } from "../CustInput/CustInput";
import Accordian from "./Accordian";

export const MainAccordian = ({ close }) => {
  const [current_selected, setcurrent_selected] = useState({});
  // console.log(current_selected);
  const current_store = useSelector((state) => state);

  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const [dataSong, setdataSong] = useState({});
  const [songEdit, setsongEdit] = useState(false);
  const [GetTheIDtoDelete, setGetTheIDtoDelete] = useState("");
  var percentage = "%";
  useEffect(() => {
    let data1 = JSON.parse(localStorage.getItem("MMPADDSONG"));
    setdata([...data1?.ToDoWriter]);
    setdataSong({ ...data1?.page_values });
    setsongEdit(data1?.page_type == "edit");
  }, []);
  console.log(data);
  const [DataInSave, setDataInSave] = useState("");

  useEffect(() => {
    setDataInSave(localStorage.getItem("accordianWriterSave"));
  }, [DataInSave]);
  const [InputError, setInputError] = useState({
    percentage: "",
    role: "",
  });
  console.log(GetTheIDtoDelete);
  const deleteTodo = (index) => {
    // const newTodos = [...todos];
    data.splice(index, 1);
    editsongwriter(
      {
        id: dataSong.song_id,
        writers_array: data,
      },
      current_store.user?.admin,
      ({ status, response }) => {
        if (status) {
          close();
          console.log(response);
        }
      }
    );
    localStorage.setItem(
      "MMPADDSONG",
      JSON.stringify({
        page_values: dataSong,
        page_type: "edit",
        ToDoWriter: data,
      })
    );
    close();
    localStorage.setItem("WriterChange", true);
  };
  const writer_edit_group = useRef();
  return (
    <div className="graph_test" style={{ width: 446, margin: "0 auto" }}>
      <Accordian
        acc_title={"List of Song Writers"}
        acc_body={[
          ...data.map((el, i) => ({
            user_id: el.user_id,
            img: el.img || el.photo_link,
            name: el.name,
            email: el.email,
            role: el.role,
            percentage: el.percentage || el.enter_percentage,
            note: el.note,
          })),
        ]}
        current_selected={current_selected}
        setcurrent_selected={setcurrent_selected}
        writer_edit_group={writer_edit_group}
        edit_body={
          <div ref={writer_edit_group}>
            <CustInput
              type="text"
              custclass="edit_writer_input"
              inp_obj={{
                title: "Name",
                value: current_selected.name,
                on_event: (data) => {
                  // setcurrent_selected((prev) => ({
                  //   ...prev,
                  //   name: data.target.value,
                  // }));
                },
                placeholder: "",
              }}
            />
            <CustInput
              type="text"
              custclass="edit_writer_input"
              inp_obj={{
                title: "Email",
                value: current_selected.email,
                on_event: (data) => {
                  // setcurrent_selected((prev) => ({
                  //   ...prev,
                  //   email: data.target.value,
                  // }));
                },
                placeholder: "",
              }}
            />
            <CustInput
              custclass="edit_writer_input"
              type="autocomplete"
              inp_obj={{
                title: "Enter Role",
                placeholder: "",
                autocomplete_list:
                  DataInSave == "true"
                    ? []
                    : [
                        "Writer",
                        "Performing Vocal Artist",
                        "Background Vocal Artist",
                        "Vocal Producer",
                        "Music Producer",
                        "Music Arranger",
                        "Recording Engineer",
                        "Mixing Engineer",
                        "Mastering Engineer",
                        "Music Director",
                        "Studio Musician",
                        "Composer",
                        "Publisher",
                        "Record Label",
                        "Artist Manager",
                        "Participatory Intrest",
                      ],
                value: current_selected.role,
                error: InputError.role,
                on_event: (data) => {
                  if (DataInSave != "true") {
                    setcurrent_selected((prev) => ({
                      ...prev,
                      role: data.target.value,
                    }));
                    setInputError((prev) => ({
                      ...prev,
                      role: data.target.value == "",
                    }));
                  }
                },
              }}
            />

            <CustInput
              type="text"
              custclass="edit_writer_input"
              inp_obj={{
                title: "Percentage Share",
                // value: current_selected.percentage + "sss",
                value: Number(current_selected.percentage)
                  ? `${Number(current_selected.percentage).toFixed(0)}%`
                  : "",
                error: InputError.percentage,
                on_event: ({ target }) => {
                  if (DataInSave != "true") {
                    console.log("dddd");
                    let currentValue = target.value.split(percentage).join("");
                    if (Number(currentValue) != currentValue) return;
                    if (
                      target.value.length > 0 &&
                      target.value.length <
                        (current_selected.percentage + "%").length &&
                      !target.value.match("%")
                    ) {
                      setInputError((prev) => ({
                        ...prev,
                        percentage: target.value.length === "",
                      }));
                      // console.log("here");
                      return setcurrent_selected((prev) => ({
                        ...prev,
                        percentage: Number(Math.floor(currentValue / 10)) || "",
                      }));
                    }
                    if (
                      currentValue > 100
                      // - current_selected.percentage ||
                      // currentValue > 100 - Number(current_selected.percentage)
                    ) {
                      return setcurrent_selected((prev) => ({
                        ...prev,
                        // percentage: 100 - Number(current_selected.percentage),
                        percentage: 100,
                      }));
                    }
                    setcurrent_selected((prev) => ({
                      ...prev,
                      percentage:
                        Number(currentValue) < 1 || !currentValue
                          ? ""
                          : currentValue,
                    }));
                    setInputError((prev) => ({
                      ...prev,
                      percentage: target.value.length === "",
                    }));
                  }
                  // setcurrent_selected((prev) => ({
                  //   ...prev,
                  //   percentage:target.value
                  //     // Number(currentValue) < 1 || !currentValue
                  //     //   ? ""
                  //     //   : currentValue,

                  // }));
                },
                placeholder: "",
              }}
            />
            <CustInput
              type="text"
              custclass="edit_writer_input"
              inp_obj={{
                title: "Note",
                value: current_selected.note,
                on_event: (data) => {
                  if (DataInSave != "true") {
                    setcurrent_selected((prev) => ({
                      ...prev,
                      note: data.target.value,
                    }));
                  }
                },
                placeholder: "",
              }}
            />
            {DataInSave == "true" ? (
              ""
            ) : (
              <Button
                custclass=""
                type="type1"
                btn_obj={{
                  txt: "Delete",
                  onclick: () => {
                    // console.log(current_selected.user_id);
                    deleteTodo(
                      data
                        .map((el, i) => current_selected.user_id == el.user_id)
                        .indexOf(true)
                    );
                    // setGetTheIDtoDelete(current_selected.user_id)
                  },
                  loading: false,
                }}
              />
            )}
          </div>
        }
      />
      {/* {console.log(data)} */}
      <div className="btn_group123">
        <Button
          custclass=""
          type="type1"
          btn_obj={{
            txt: "Cancel",
            onclick: () => {
              close();
            },
            loading: false,
          }}
        />
        {/* {console.log(current_selected)} */}
        {DataInSave == "true" ? (
          ""
        ) : (
          <Button
            custclass=""
            type="type1"
            btn_obj={{
              txt: "Save",
              onclick: () => {
                // console.log(current_selected);
                if (
                  current_selected.percentage == "" ||
                  current_selected.percentage == undefined ||
                  current_selected.role == "" ||
                  current_selected.role == undefined
                ) {
                  setInputError((prev) => ({
                    ...prev,
                    percentage:
                      current_selected.percentage === "" ||
                      current_selected.percentage === undefined,
                    role:
                      current_selected.role === "" ||
                      current_selected.role === undefined,
                    // note: page_values.note === "",
                  }));
                  return;
                }
                if (songEdit) {
                  editsongwriter(
                    {
                      id: dataSong.song_id,
                      writers_array: data.map((el) =>
                        current_selected.user_id == el.user_id
                          ? {
                              user_id: el.user_id,
                              authorised: el.authorised,
                              photo_link: el.img,
                              role: current_selected.role,
                              percentage: current_selected.percentage,
                              note: current_selected.note,
                              email: el.email,
                              name: el.name,
                            }
                          : {
                              ...el,
                            }
                      ),
                    },
                    current_store.user?.admin,
                    ({ status, response }) => {
                      if (status) {
                        close();
                      }
                    }
                  );
                }
                localStorage.setItem(
                  "MMPADDSONG",
                  JSON.stringify({
                    page_values: dataSong,
                    page_type: "edit",
                    ToDoWriter: data.map((el) =>
                      el.user_id == current_selected.user_id
                        ? {
                            authorised: el.authorised,
                            user_id: el.user_id,
                            img: el.img || el.photo_link,
                            name: el.name,
                            email: el.email,
                            role: current_selected.role,
                            percentage: current_selected.percentage,
                            note: current_selected.note,
                            data: "dhiraj",
                          }
                        : {
                            ...el,
                          }
                    ),
                  })
                );
                localStorage.setItem("WriterChange", true);
                close();
              },
              loading: false,
            }}
          />
        )}
      </div>
    </div>
  );
};
