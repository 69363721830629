import React from "react";
import { Button } from "../../../Custom/Button/Button";
import { EditTicket, EditTicketStatus, GetTicket } from "../../../Common/Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { CustInput } from "../../../Custom/CustInput/CustInput";

function ISRCModal({ close, inp_obj }) {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log(inp_obj.modal_data.name);
  // console.log(current_store);
  const [TicketData, setTicketData] = useState([]);
  const [DataISthere, setDataISthere] = useState();
  const [IdgetThreLoc, setIdgetThreLoc] = useState("");
  useEffect(() => {
    GetTicket(({ status, response }) => {
      if (status) {
        setTicketData(response.data);
      }
    });
  }, []);
  const [page_values, setpage_values] = useState({
    name: "",
    isrc_code: ""
  });
  useEffect(() => {
    setpage_values({
      name:
        inp_obj?.modal_data?.name ||
        inp_obj?.modal_data?.name_title ||
        TicketData.filter((el) => el.id == IdgetThreLoc).map(
          (el) => el.service_data.name
        ),
      id: inp_obj.modal_data.id || IdgetThreLoc,
      link:
        inp_obj?.modal_data?.link ||
        TicketData.filter((el) => el.id == IdgetThreLoc).map(
          (el) => el.pending.document
        )[0],
    });
    setDataISthere(
      inp_obj?.modal_data?.link?.document ||
        TicketData.filter((el) => el.id == IdgetThreLoc).map(
          (el) => el.pending.document
        )[0]
    );
    console.log(
      TicketData.filter((el) => el.id == IdgetThreLoc).map(
        (el) => el.pending.document
      )[0]
    );
  }, [current_store, TicketData]);
  return (
    <div className="main_admin_service_edit1">
      <div className="title_edit_modal">ISRC Code</div>
      {/* <div style={{ textAlign: "center" }}>{page_values.name}</div> */}
      {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
        </div> */}

      <CustInput    
        custclass="inner-input_modal"
        type="text"
        inp_obj={{
          title: "Enter ISRC Code",
          placeholder: "",
          value: page_values.isrc_code,
          on_event: ({ target }) => {
            // console.log(target.value);
            setpage_values((prev) => ({
              ...prev,
              isrc_code: target.value,
            }));
          },
        }}
      />

      <div className="btn_handler_inedit_modalTicket">
        <Button
          type="type1"
          custclass="btn2"
          btn_obj={{
            txt: "Cancel",
            onclick: () => {
              close();
              // dispatch({
              //   type: "loading",
              // });
              // console.log(1);
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
        <Button
          type="type1"
          custclass="btn2"
          btn_obj={{
            txt: "Save",
            onclick: () => {
              dispatch({
                type: "loading",
              });
              // ImageToLink(FileDocum, "document", ({ status, response }) => {
              // console.log(response);

              //   if (status) {
                EditTicket({ id: page_values.id , isrc_code : page_values.isrc_code}, ({ status, response1 }) => {
                // console.log(response1);
                if (status) {
                    window.location.reload();
                }
              });

              //   }
              // });

              // console.log("addsongerror", page_values.photo);
              return;
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
      </div>
    </div>
  );
}

export default ISRCModal;
