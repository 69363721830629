import React from "react";
import { useState } from "react";
import { Images } from "../../../Common/Images/Images";
import BillMonthly_Annually from "../../../Common/PurchaseSuggested/BillMonthly_Annually";
import { PremiumUltra } from "../../../Common/PurchaseSuggested/PremiumUltra";
import Ps from "../../../Common/PurchaseSuggested/Ps";
import { SwitchBtn } from "../../../Custom/Button/Button";

const Anually = [
  {
    package: "anually",
    amount: "$499",
  },
  {
    package: "anually",
    amount: "$999",
  },
];
const Monthly = [
  {
    package: "monthly",
    amount: "$49",
  },
  {
    package: "monthly",
    amount: "$99",
  },
];
export const AlertForBasicPlan = ({ onevent }) => {
  const [current_showing_list, setcurrent_showing_list] = useState([
    ...Monthly,
  ]);
  const [selected_obj, setselected_obj] = useState([]);
  const [Check, setCheck] = useState(true);

  return (
    <>
      {window.innerWidth <= 500 ? (
        <>
          <div className="superior_premium_plan">
            <div className="media_premium_plan">
              <div className="inner_premium_plan">
                <div className="inner_premium_plan_title">
                  Upgrade your plan to avail unlimited storage and much more!!!
                </div>
                <div className="inner_premium_plan_list">
                  <div className="inner_title_alert_main_basic">
                    <div>
                      <Images img_var="check_tick" />
                    </div>
                    Register with PRO
                  </div>
                  <div className="inner_title_alert_main_basic">
                    <div>
                      <Images img_var="check_tick" />
                    </div>
                    Encode/Fingerprint song into Soundscan/BDS/GoogleID for
                    audio
                  </div>
                  <div className="inner_title_alert_main_basic">
                    <div>
                      <Images img_var="check_tick" />
                    </div>
                    Register with Content Aggregators (Apple Itunes, Spotify,
                    etc)
                  </div>
                  <div className="inner_title_alert_main_basic">
                    <div>
                      <Images img_var="check_tick" />
                    </div>
                    No cost for split sheet creation and download. Stores
                    unlimited song titles in dashboard.
                  </div>
                </div>
              </div>
            </div>
            <div className="lower_media_premium_plan">
              <div className="inner_lower_prermium_plan">
                <div>{"Billed Monthly"}</div>
                <div className="inner_lower_prermium_plan_switch">
                  <SwitchBtn
                    {...{
                      width: 35,
                      height: 20,
                      onevent,
                    }}
                  />
                </div>
              </div>
              <div className="inner_premium_tag">
                <PremiumUltra
                  inp_obj={{
                    plan_name: "Premium",
                    plan_amount: current_showing_list[0]?.amount,
                    plan_type: current_showing_list[0]?.package,
                    selected_plan: true,
                    setCheck,
                    Check,
                  }}
                />
              </div>
              <div className="inner_premium_tag">
                <PremiumUltra
                  inp_obj={{
                    plan_name: "Ultra",
                    plan_amount: current_showing_list[0]?.amount,
                    plan_type: current_showing_list[0]?.package,
                    selected_plan: true,
                    setCheck,
                    Check,
                  }}
                />
              </div>
              <div className="inner_premium_month">Continue with Ultra</div>
            </div>
          </div>
        </>
      ) : (
        <div className="main_alert_basic_plan">
          <div className="inner_alert_upgrade">
            <div className="inner_title_alert_basic">
              <span className="inner_title_alert_basic1">
                <span>
                  <Images img_var="king_crown" />
                </span>
                <span>Upgrade your plan to avail</span>
              </span>
              unlimited storage and much more!!!
            </div>
            <div className="inner_title_alert_main_basic">
              <div>
                <Images img_var="check_tick" />
              </div>
              Register with PRO
            </div>
            <div className="inner_title_alert_main_basic">
              <div>
                <Images img_var="check_tick" />
              </div>
              Encode/Fingerprint song into Soundscan/BDS/GoogleID for audio
            </div>
            <div className="inner_title_alert_main_basic">
              <div>
                <Images img_var="check_tick" />
              </div>
              Register with Content Aggregators (Apple Itunes, Spotify, etc)
            </div>
            <div className="inner_title_alert_main_basic">
              <div>
                <Images img_var="check_tick" />
              </div>
              No cost for split sheet creation and download. Stores unlimited
              song titles in dashboard.
            </div>
            <div className="inner_title_alert_main_basic1">
              And Many More Services
            </div>
          </div>
          <div className="inner_alert_upgrade1">
            <div className="inner_alert_upgrade_btn">
              <BillMonthly_Annually
                onevent={(is_active) => {
                  setselected_obj([]);
                  if (is_active) {
                    setcurrent_showing_list([...Anually]);
                  } else {
                    setcurrent_showing_list([...Monthly]);
                  }
                }}
              />
            </div>
            <div className="inner_premium_tag">
              <PremiumUltra
                inp_obj={{
                  plan_name: "Premium",
                  plan_amount: current_showing_list[0]?.amount,
                  plan_type: current_showing_list[0]?.package,
                  selected_plan: true,
                  setCheck,
                  Check,
                }}
              />
            </div>
            <div className="inner_premium_tag">
              <PremiumUltra
                inp_obj={{
                  plan_name: "Ultra",
                  plan_amount: current_showing_list[1]?.amount,
                  plan_type: current_showing_list[1]?.package,
                  selected_plan: true,
                  setCheck,
                  Check,
                }}
              />
            </div>
            <div className="inner_premium_month">Continue with Ultra</div>
            <div className="inner_member_ship_page">
              Check more services. Go to Membership Page{" "}
              <Images img_var="right_sign" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
