import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CardDetail,
  EditTicket,
  GetTicket,
  ImageToLink,
} from "../../Common/Api/Api";
import { Images } from "../../Common/Images/Images";
import { Button } from "../../Custom/Button/Button";
import { CustInput } from "../../Custom/CustInput/CustInput";

export const TicketModal = ({ close, inp_obj }) => {
  const current_store = useSelector((state) => state);
  // console.log(current_store);

  const dispatch = useDispatch();
  // console.log(inp_obj.modal_data.name);
  // console.log(current_store);
  const [page_values, setpage_values] = useState({
    name: "",
  });
  const [FileDocum, setFileDocum] = useState("");
  const [DataISthere, setDataISthere] = useState();
  const [IdgetThreLoc, setIdgetThreLoc] = useState("");
  // console.log(FileDocum);
  const [TicketData, setTicketData] = useState([]);
  useEffect(() => {
    GetTicket(({ status, response }) => {
      if (status) {
        setTicketData(response.data);
      }
    });
  }, []);
  useEffect(() => {
    let dat =
      localStorage.getItem("navTicketID") || localStorage.getItem("TicketID");
    setIdgetThreLoc(dat);
    localStorage.setItem("navTicketID", "");
    localStorage.setItem("TicketID", "");
  }, [TicketData]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setFileDocum(reader.result?.toString() || "");
        e.target.value = null;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const [InputError, setInputError] = useState({
    name: "",
  });
  useEffect(() => {
    setpage_values({
      name:
        inp_obj?.modal_data?.name ||
        inp_obj?.modal_data?.name_title ||
        TicketData.filter((el) => el.id == IdgetThreLoc).map(
          (el) => el.service_data.name
        ),
      id: inp_obj.modal_data.id || IdgetThreLoc,
      link:
        inp_obj?.modal_data?.link ||
        TicketData.filter((el) => el.id == IdgetThreLoc).map(
          (el) => el.pending.document
        )[0],
    });
    setDataISthere(
      inp_obj?.modal_data?.link?.document ||
        TicketData.filter((el) => el.id == IdgetThreLoc).map(
          (el) => el.pending.document
        )[0]
    );
    console.log(
      TicketData.filter((el) => el.id == IdgetThreLoc).map(
        (el) => el.pending.document
      )[0]
    );
  }, [current_store, TicketData]);
  console.log(page_values);

  // console.log(...TicketData.filter((el)=> el.id == IdgetThreLoc).map((el)=>el.service_data.name))
  return (
    <>
      <div className="main_admin_service_edit1">
        <div className="title_edit_modal">Ticket Detail</div>
        <div style={{ textAlign: "center" }}>{page_values.name}</div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {DataISthere ? (
            <div className="handler_inner_ticket_detail">
              <a
                href={page_values?.link?.document ||page_values?.link }
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="type1"
                  custclass="uploadbtn"
                  btn_obj={{
                    txt: "Uploaded",
                    onclick: () => {
                      console.log(page_values?.link);
                    },
                    loading: false,
                    show_type: "",
                  }}
                ></Button>
              </a>
              <div
                className="delete_btn_ticket"
                onClick={() => {
                  setDataISthere("");
                }}
              >
                <Images img_var={"delete"} />
              </div>
            </div>
          ) : FileDocum ? (
            "UPLOADED"
          ) : (
            <label htmlFor="tricket_file">
              <Button
                type="type1"
                custclass="uploadbtn_up"
                btn_obj={{
                  txt: "Upload Document",
                  onclick: () => "",
                  loading: false,
                  show_type: "",
                }}
              ></Button>
              <input
                type="file"
                className={"add_img_senior"}
                accept=".pdf,.docx,.doc"
                onChange={onSelectFile}
                id="tricket_file"
              />
            </label>
          )}

          {/* {FileDocum ? (
           "UPLOADED"
          ) : page_values?.link?.document ? (
            ""
          ) : (
            <label htmlFor="tricket_file">
              <Button
                type="type1"
                custclass="uploadbtn"
                btn_obj={{
                  txt: "Upload Document",
                  onclick: () => "",
                  loading: false,
                  show_type: "",
                }}
              ></Button>
              <input
                type="file"
                className={"add_img_senior"}
                accept=".pdf,.docx,.doc"
                onChange={onSelectFile}
                id="tricket_file"
              />
            </label>
          )} */}
        </div>
        <div className="btn_handler_inedit_modalTicket">
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
                // dispatch({
                //   type: "loading",
                // });
                // console.log(1);
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Save",
              onclick: () => {
                dispatch({
                  type: "loading",
                });
                ImageToLink(FileDocum, "document", ({ status, response }) => {
                  // console.log(response);

                  if (status) {
                    EditTicket(
                      { document: response.data, id: page_values.id },
                      ({ status, response1 }) => {
                        // console.log(response1);
                        if (status) {
                          window.location.reload();
                        }
                      }
                    );
                  }
                });

                // console.log("addsongerror", page_values.photo);
                return;
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};
