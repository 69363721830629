import React from "react";
import { CustInput } from "../../Custom/CustInput/CustInput";
// import { Button } from "@mui/material";
import { useState } from "react";
import { AdminAddServices, AdminEditServices } from "../../Common/Api/Api";
import { Button } from "../../Custom/Button/Button";
import { useDispatch } from "react-redux";

const AddServiceAdmin = ({ close }) => {
  const dispatch = useDispatch();
  const [page_values, setpage_values] = useState({
    service_name: "",
    service_desc: "",
    service_price: "",
    // show: "",
    link: "",
    state_name: "onwards",
    state_list: [],
    membership_type: "Basic",
  });
  const [InputError, setInputError] = useState({
    service_name: "",
    service_desc: "",
    service_price: "",
    // show: "",
    link: "",
    state_name: "onwards",
    state_list: [],
    membership_type: "",
  });
  return (
    <>
      <div className="admin_add_service_amin">
        <div className="title_admin_add_service_amin">Add Service</div>
        <CustInput
          custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Enter service name",
            placeholder: "",
            value: page_values.service_name,
            error: InputError.service_name,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                service_name: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                service_name: !target.value,
              }));
            },
          }}
        />
        <CustInput
          custclass="inner-input_modal"
          type="textarea"
          inp_obj={{
            title: "Enter service description",
            placeholder: "",
            value: page_values.service_desc,
            error: InputError.service_desc,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                service_desc: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                service_desc: !target.value,
              }));
            },
          }}
        />
        <CustInput
          custclass="inner-input_modal"
          type="number"
          inp_obj={{
            title: "Enter service price",
            placeholder: "",
            value: page_values.service_price,
            error: InputError.service_price,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                service_price: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                service_price: !target.value,
              }));
            },
          }}
        />
        <CustInput
          custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Expert call link",
            placeholder: "",
            value: page_values.link,
            error: InputError.link,
            on_event: ({ target }) => {
              // console.log(target.value);
              setpage_values((prev) => ({
                ...prev,
                link: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                link: !target.value,
              }));
            },
          }}
        />
        <div className="inner-input_modal custom_drop_down_title">
          <div className="serviceprice">Enter service price text</div>
          <CustInput
            custclass=""
            type="dropdown"
            inp_obj={{
              options: [
                {
                  option_key: "onwards",
                  option_value: "onwards",
                },
                {
                  option_key: "one time fee",
                  option_value: "one time fee",
                },
              ],
              value: page_values.state_name,
              on_event: (data) => {
                setpage_values((prev) => ({
                  ...prev,
                  state_name: data,
                  state_list: [],
                }));
              },
            }}
          />
        </div>
        <CustInput
          custclass="plan_admin_telleer"
          type="button_select"
          inp_obj={{
            title: "Select Membership Type",
            // readonly: current_login_page !== current_page,
            button_type: "horizontal",
            selection: "single",
            to_show_data: [
              {
                key: "Basic",
                value: "Basic",
              },
              {
                key: "Premium",
                value: "Premium",
              },
              {
                key: "Ultra",
                value: "Ultra",
              },
            ],
            value: page_values.membership_type,
            // error: InputError.organization,
            on_event: (value) => {
              setpage_values((prev) => ({
                ...prev,
                membership_type: value,
              }));
              // setInputError((prev) => ({
              //   ...prev,
              //   organization: value === "",
              // }));
            },
          }}
        />

        <div style={{ display: "flex", gap: "30px", margin: "30px 0 0 0" }}>
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass="btn2"
            btn_obj={{
              txt: "Save",
              onclick: () => {
                console.log(page_values);
                if (
                  page_values.service_name === "" ||
                  page_values.service_desc === "" ||
                  page_values.service_price === "" ||
                  page_values.link === ""
                ) {
                  setInputError((prev)=>({
                    ...prev,
                    service_name :  page_values.service_name === "" ,
                    service_desc :  page_values.service_desc === "" ,
                    service_price :  page_values.service_price === "" ,
                    link :  page_values.link === "" ,
                  }))
                  return;
                }
                AdminAddServices({ ...page_values }, ({ status, response }) => {
                  dispatch({
                    type: "loading",
                  });
                  if (status) {
                    window.location.reload();
                  }
                });
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default AddServiceAdmin;
