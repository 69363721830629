import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import SignaturePad from "react-signature-canvas";

export const SiganCanva = ({ close }) => {
  const [image, setimage] = useState("");
  const signCanvas = useRef();
  const dispatch = useDispatch();
  const [canvas, setcanvas] = useState("");
  const [canvas_err, setcanvas_err] = useState(false);
  return (
    <>
      <div className="mainSign_Canva">
        <div className="sign_canva_handler_main">
          <div className="close_handler_canva_sign"
            onClick={() => {
              close();
              // console.log("dhiraj");
            }}
          >
            Cancel
          </div>
          <div className="signature_title">
            Signature
          </div>
         <div className="close_handler_canva_sign"
         onClick={() => {
            if (!signCanvas.current.isEmpty()) {
                close();
              setimage(
                signCanvas.current.getTrimmedCanvas().toDataURL("img/png")
              );
              // console.log(
              //   signCanvas.current.getTrimmedCanvas().toDataURL("img/png")
              // );
            } else {
              setcanvas_err(true);
            }
          }}
         >
            Done
          </div>
        </div>
        <SignaturePad
          ref={signCanvas}
          canvasProps={{
            className: "cust_signaturepad",
          }}
        />
        {/* <button
          onClick={() => {
            if (!signCanvas.current.isEmpty()) {
              setimage(
                signCanvas.current.getTrimmedCanvas().toDataURL("img/png")
              );
              console.log(
                signCanvas.current.getTrimmedCanvas().toDataURL("img/png")
              );
            } else {
              setcanvas_err(true);
            }
          }}
        >
          Save
        </button> */}

        <div className="clear_new_sign" onClick={() => signCanvas.current.clear()}>Clear</div>
        {/* {image && <img src={image} />} */}
        {canvas_err && image == "" && <div>No sign found</div>}
      </div>
    </>
  );
};
