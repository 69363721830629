import React from "react";
import { useState } from "react";
import { CustInput } from "../../../Custom/CustInput/CustInput";
import { Button } from "../../../Custom/Button/Button";
import { useEffect } from "react";
import { AdminAddMisc, AdminEditMisc } from "../../../Common/Api/Api";
import UsersFilter from "../../../Common/Users/UsersFilter";

const AddServiceMisc = ({ close, modal_data }) => {
  const [page_values, setpage_values] = useState({
    name: "",
    businessname: "",
    link: "",
    phone_number: "",
    formats_release: "",
    utlisname : "",
      });
  const [page_data, setpage_data] = useState({
    user_filter_song: "All",
  });
  const [InputError, setInputError] = useState({
    name: "",
    businessname: "",
    link: "",
    phone_number: "",
    formats_release: "",
    user_filter_song: "All",
  });
const [ErrorIn, setErrorIn] = useState(false)
  const [For_user, setFor_user] = useState(false);
  const [userData, setUserData] = useState(false);
  const [FilterAsFollow, setFilterAsFollow] = useState(1);
  const [serviceDropdown, setserviceDropdown] = useState([]);
  // console.log(page_data);
  useEffect(() => {
    if (modal_data.id) {
      setpage_values({
        name: modal_data.name || "",
        businessname: modal_data.business_name || "",
        link: modal_data.link || "",
        utlisname :modal_data?.service_type.service_name || "",
        phone_number: modal_data.phone
          ? "(+1) " +
            (modal_data.phone + "").slice(0, 3) +
            " " +
            (modal_data.phone + "").slice(3, 6) +
            " " +
            (modal_data.phone + "").slice(6)
          : "",
      });
      setpage_data({
        formats_release: modal_data?.service_type?._id || "Select One",
      });
    }
  }, [modal_data]);
  console.log(modal_data);
  useEffect(() => {
    if(!userData){
      setErrorIn(false)
    }
  }, [userData])
  
  
  return (
    <>
      <div className="miscel_modal_main">
        {userData ? (
          <UsersFilter
            setpage_data={setpage_data}
            page_data={page_data}
            FilterAsFollow={FilterAsFollow}
            setUserData={setUserData}
            For_user={For_user}
            data={[
              ...modal_data.serviceDropdown.map((el) => ({
                name: el.service_name,
                id: el.id,
              })),
            ]}
          />
        ) : (
          ""
        )}
        <div className="mis_title">Add Miscellaneous</div>
        <CustInput
          //   custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Name",
            value: page_values.name,
            error: InputError.name,
            on_event: ({ target }) => {
              //   toast(target.value);
              // console.log(
              //   target.value.match(
              //     /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
              //   )
              // );
              setpage_values((prev) => ({
                ...prev,
                name: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                name: target.value === "",
              }));
            },
          }}
        />
        <CustInput
          //   custclass="inner-input_modal"
          type="text"
          inp_obj={{
            title: "Business Name",
            value: page_values.businessname,
            error: InputError.businessname,
            on_event: ({ target }) => {
              //   toast(target.value);
              // console.log(
              //   target.value.match(
              //     /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
              //   )
              // );
              setpage_values((prev) => ({
                ...prev,
                businessname: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                businessname: target.value === "",
              }));
            },
          }}
        />
        {/* <CustInput
          //   custclass="inner-song-user-input1"
          type="text"
          inp_obj={{
            title: "Contact",
            // placeholder: "johndoe@gmail.com",
            value: page_values.number,
            error: InputError.number,
            on_event: ({ target }) => {
              //   toast(target.value);
              // console.log(
              //   target.value.match(
              //     /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
              //   )
              // );
              setpage_values((prev) => ({
                ...prev,
                number: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                number: target.value === "",
              }));
            },
          }}
        /> */}
        <CustInput
          // custclass="inner-song-user-input"
          type="text"
          inp_obj={{
            title: "Contact",
            placeholder: "",
            value: page_values.phone_number,
            error: InputError.phone_number,
            on_event: ({ target }) => {
              //console.log(target.value);
              setInputError((prev) => ({
                ...prev,
                phone_number: !target.value,
              }));
              // const OnChangePhone = (e) => {
              // console.log(target.value.length);
              let phone = "";
              if (
                Number(target.value.slice(-1)).toString() == "NaN" ||
                target.value.length > 17 ||
                (target.value.slice(-1) == " " &&
                  ![5, 9, 13].some((el) => el == target.value.length))
              ) {
                return;
              }
              // setphone_err("true");
              // if (target.value.length == 17) {
              //   setphone_err("false");
              //   // setphone_verification_state('')
              // }
              switch (target.value.length) {
                case 1:
                  setpage_values((prev) => ({
                    ...prev,
                    phone_number: `(+1) ${target.value[0]}`,
                  }));
                  return;
                case 5:
                  setpage_values((prev) => ({
                    ...prev,
                    phone_number: "",
                  }));
                  return;
                case 9:
                  if (target.value.slice(-1) == " ") {
                    setpage_values((prev) => ({
                      ...prev,
                      phone_number: target.value.slice(0, 8),
                    }));
                    return;
                  }
                  setpage_values((prev) => ({
                    ...prev,
                    phone_number:
                      target.value.slice(0, 8) + " " + target.value.slice(-1),
                  }));
                  return;
                case 13:
                  if (target.value.slice(-1) == " ") {
                    setpage_values((prev) => ({
                      ...prev,
                      phone_number: target.value.slice(0, 12),
                    }));
                    return;
                  }
                  setpage_values((prev) => ({
                    ...prev,
                    phone_number:
                      target.value.slice(0, 12) + " " + target.value.slice(-1),
                  }));
                  return;
                default:
                  setpage_values((prev) => ({
                    ...prev,
                    phone_number: target.value,
                  }));
                  return;
              }
              // };
              //console.log(target.value);
              // setpage_values((prev) => ({
              //   ...prev,
              //   phone_number: target.value,
              // }));
              // setInputError((prev) => ({
              //   ...prev,
              //   phone_number: target.value === "",
              // }));

              // setpage_values((prev) => ({
              //   ...prev,
              //   phone_number: target.value,
              // }));
            },
          }}
        />

        <CustInput
          //   custclass="inner-song-user-input1"
          type="text"
          inp_obj={{
            title: "Website",
            // placeholder: "johndoe@gmail.com",
            value: page_values.link,
            error: InputError.link,
            on_event: ({ target }) => {
              //   toast(target.value);
              // console.log(
              //   target.value.match(
              //     /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
              //   )
              // );
              setpage_values((prev) => ({
                ...prev,
                link: target.value,
              }));
              setInputError((prev) => ({
                ...prev,
                link: target.value === "",
              }));
            },
          }}
        />
        {/* <div className="inner-input-side_mod"> */}
        <div
          className={`inner-input-side_mod seri_title_jij ${
            ErrorIn ? " error" : ""
          }`}
          onClick={() => {
            setUserData(true);
          }}
        >
          <div className="kim">Utilities</div>

          {page_data?.user_filter_song == "All"
            ? "Select Utilities"
            : modal_data?.serviceDropdown.filter(
                (el) => el.id == page_data?.user_filter_song
              )[0]?.service_name? modal_data?.serviceDropdown.filter(
                (el) => el.id == page_data?.user_filter_song
              )[0]?.service_name : page_values?.utlisname }
        </div>
        {/* <CustInput
          custclass="innerticket_width21"
          type="multi-dropSel"
          inp_obj={{
            options: [
              {
                option_key: "Select One",
                option_value: "Select One",
              },
              ...modal_data.serviceDropdown.map((el) => ({
                option_key: el.id,
                option_value: el.service_name,
              })),
            ],
            value: page_data.formats_release,
            // placeholder: "Select One...",
            on_event: (data) => {
              setpage_data((prev) => ({
                ...prev,
                formats_release: data,
                formats_list: [],
              }));
            },
          }}
        /> */}
        {/* <CustInput
                      custclass="inner-side-song-input"
                      type="autocomplete"
                      inp_obj={{
                        title: `s Role`,
                        placeholder: "",
                        error: InputError.formats_release,
                        autocomplete_list: [
                          ...modal_data.serviceDropdown.map((el) => ({
                            option_value: el.service_name,
                          })),
                        ],
                        value: page_values.formats_release,
                        on_event: ({ target }) => {
                          setpage_values((prev) => ({
                            ...prev,
                            formats_release: target.value,
                          }));
                          setInputError((prev) => ({
                            ...prev,
                            formats_release: target.value.length === "",
                          }));
                        },
                      }}
                    /> */}
        {/* </div> */}
        <div className="innerticket_width123">
          *If you do not find the service you want to add listed here, then
          please click on add service button.
        </div>
        <div className="mis_footer">
          <Button
            type="type1"
            custclass=""
            btn_obj={{
              txt: "Cancel",
              onclick: () => {
                close();
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
          <Button
            type="type1"
            custclass=""
            btn_obj={{
              txt: "Save",
              onclick: () => {
                // console.log([page_values.name === "" ,
                // page_values.businessname === ""].includes(false) != true ,
                // [page_values.phone_number.length < 17  ,
                // page_values.link === ""].includes(false) != true ,
                // page_data.formats_release == "Select One" ,
                // (page_values.phone_number.length < 17 && page_values.phone_number.length > 0));
                // return
                if (
                  [
                    page_values.name === "",
                    page_values.businessname === "",
                  ].includes(false) != true ||
                  [
                    page_values.phone_number.length < 17,
                    page_values.link === "",
                  ].includes(false) != true ||
                  (page_values.phone_number.length < 17 &&
                    page_values.phone_number.length > 0) ||
                    page_data.user_filter_song == "All"
                  // page_data.user_filter_song == "All"
                ) {
                  setInputError((prev) => ({
                    ...prev,
                    name: page_values.name === "",
                    businessname: page_values.businessname === "",
                    phone_number:
                      page_values.phone_number === "" ||
                      page_values.phone_number.length < 17,
                    link: page_values.link === "",
                    // user_filter_song: page_data.user_filter_song === "All",
                  }));
                  if(page_data.user_filter_song == "All"){
                    setErrorIn(true)
                  }
                  // else{
                  //   setErrorIn(true)
                  // }
                  return;
                }
                // console.log("ppppp");
                // return
                // if(page_data.user_filter_song == "All"){
                //   setErrorIn(true)
                //   return
                // }
                if (modal_data.id) {
                  AdminEditMisc(
                    { ...page_values, page_data, modal_data },
                    ({ status, response }) => {
                      if (status) {
                        window.location.reload();
                      }
                    }
                  );
                } else {
                  AdminAddMisc(
                    { ...page_values, page_data },
                    ({ status, response }) => {
                      if (status) {
                        window.location.reload();
                      }
                    }
                  );
                }
              },
              loading: false,
              show_type: "",
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};

export default AddServiceMisc;
