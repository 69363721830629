import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Songauth } from "../../../Common/Api/Api";
import { Button } from "../../../Custom/Button/Button";
import { CustInput } from "../../../Custom/CustInput/CustInput";
export const SongColabAuth = ({ modal_data, modal_page_data, close }) => {
  // console.log(modal_page_data, modal_data);
  const current_store = useSelector((state) => state);
  // console.log(current_store);
  const [page_data, setpage_data] = useState({
    name: "",
    email: "",
    role: "",
    percentage: "",
    note: "",
    photo_link: "",
    authorised: false,
  });
  //   const dispatch = useDispatch()
  useEffect(() => {
    setpage_data((prev) => ({
      ...prev,
      ...(Object.keys(modal_page_data).length > 0 ? modal_page_data : {}),
    }));

    return () => {};
  }, []);
  // console.log(page_data);
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("MMPAUTH")))
      setpage_data({ ...JSON.parse(localStorage.getItem("MMPAUTH")) });
  }, []);

  // console.log(page_data);
  return (
    <div className="songcolabauth">
      <div className="songcolab_details">
        <div
          className="songcolab_img"
          style={{
            backgroundImage: `url(${page_data.photo_link})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {/* <img
            src={page_data?.photo_link}
            onError={(e) => {
              e.target.onError = null;
              e.target.src =
                "https://julietteimages.s3.amazonaws.com/profileimages/default.png";
            }}
          /> */}
        </div>
        <div className="songcolab_txt">
          <div className="songcolab_name">
            {page_data?.name || "{Song Writer Name}"}
          </div>
          <div className="songcolab_percentage">
            {page_data?.percentage
              ? Number(page_data.percentage).toFixed(2)
              : "0"}
            %
          </div>
        </div>
      </div>
      <div className="datainput_group">
        <CustInput
          custclass=""
          type="text"
          inp_obj={{
            title: "Name",
            placeholder: "",
            readonly: true,
            value: page_data.name,
            on_event: ({ target }) => "",
            // setpage_data((prev) => ({ ...prev, name: target.value })),
          }}
        />
        <CustInput
          custclass=""
          type="text"
          inp_obj={{
            title: "Email",
            placeholder: "",
            readonly: true,
            value: page_data.email,
            on_event: ({ target }) => "",
            // setpage_data((prev) => ({ ...prev, email: target.value })),
          }}
        />
        <CustInput
          custclass=""
          type="text"
          inp_obj={{
            title: "Role",
            placeholder: "",
            readonly: true,
            value: page_data.role,
            on_event: ({ target }) => "",
            // setpage_data((prev) => ({ ...prev, role: target.value })),
          }}
        />
        <CustInput
          custclass=""
          type="number"
          inp_obj={{
            title: "Percentage Share",
            placeholder: "",
            readonly: true,
            value: page_data.percentage || "0",
            on_event: ({ target }) => "",
            // setpage_data((prev) => ({
            //   ...prev,
            //   percentage: target.value,
            // })),
          }}
        />
        <CustInput
          custclass=""
          type="text"
          inp_obj={{
            title: "Notes",
            placeholder: "",
            readonly: true,
            value: page_data.note,
            on_event: ({ target }) => "",
            // setpage_data((prev) => ({ ...prev, notes: target.value })),
          }}
        />
      </div>
      {/* {console.log(page_data.authorised)} */}
      <div className="btn_group">
        {page_data.authorised ? (
          <Button
            custclass="deauth_btn"
            type="type1"
            btn_obj={{
              txt: "De-Authorize",
              //   show_type:
              //     current_login_page === "complete_profile2" ? "hide" : "",
              onclick: () => {
                Songauth(
                  {
                    id: page_data.song_id,
                    auth: page_data.authorised ? "DeAuthorised" : "Authorised",
                  },
                  ({ status, response }) => {
                    if (status) {
                      return window.location.reload();
                    }
                  }
                );
              },
              loading: false,
            }}
          />
        ) : (
          <Button
            custclass="auth_btn"
            type="type1"
            btn_obj={{
              txt: "Submit Authorization",
              //   show_type:
              //     current_login_page === "complete_profile2" ? "hide" : "",
              onclick: () => {
                // console.log(page_data.song_id);
                Songauth(
                  {
                    id: page_data.song_id,
                    auth: page_data.authorised ? "DeAuthorised" : "Authorised",
                  },
                  ({ status, response }) => {
                    if (status) {
                      return window.location.reload();
                    }
                  }
                );
              },
              loading: false,
            }}
          />
        )}
        <Button
          custclass="cancel_btn"
          type="type1"
          btn_obj={{
            txt: "Cancel",
            //   show_type:
            //     current_login_page === "complete_profile2" ? "hide" : "",
            onclick: () => {
              close();
            },
            loading: false,
          }}
        />
      </div>
    </div>
  );
};
