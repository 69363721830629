import React from "react";
import { Images } from "../../../Common/Images/Images";
import { Button } from "../../../Custom/Button/Button";

function ServicesPage5({ close }) {
  return (
    <>
      <div className="main-thank-you">
        <div className="inner-thank-you">
          Thank you!
          <div >
            <Images img_var={"like"} />
          </div>
        </div>
        {window.innerWidth <= 500 ? (
          <>
            <div className="inner-thank-you2">
              You will receive the code in your email.
              <br />
              Please check your inbox.
            </div>
          </>
        ) : (
          <>
            <div className="inner-thank-you2">
              You will receive an email from us within 2 days regarding the
              further procedure.
              <br />
              <br />
              Please keep a check on your inbox
            </div>
          </>
        )}

        <Button
          type="type1"
          custclass="Okay-btn"
          btn_obj={{
            txt: "Okay",
            onclick: () => {
              close();
              window.location.reload()
            },
            loading: false,
            show_type: "",
          }}
        ></Button>
      </div>
    </>
  );
}

export default ServicesPage5;
