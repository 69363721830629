import React from "react";
import { SwitchBtn } from "../../Custom/Button/Button";
import { calc_horizontal } from "../utils/utils";

function BillMonthly_Annually({ onevent ,data11 }) {
  return (
    <div className="inner_annually_monthly"  >
      <div>Billed Monthly</div>
      <div className="sw-btn-case">
        <SwitchBtn
          {...{
            width:  35,
            height: 20,
            onevent,
            data11
          }}
        />
      </div>

      <div>Billed Anually</div>
    </div>
  );
}

export default BillMonthly_Annually;
