import React, { useState } from "react";
import { Images } from "../Images/Images";
import { CustInput } from "../../Custom/CustInput/CustInput";
import { useSelector } from "react-redux";

const alfa = "abcdedfgijklmnopqrstuvwxyz".split("")
const UsersFilter = ({
  setpage_data,
  page_data,
  FilterAsFollow,
  setUserData,
  data,
  For_user
}) => {
  const current_store = useSelector((state) => state);
  const [page_values, setpage_values] = useState({
    name_person: "",
  });
  const [SrchEmailorName, setSrchEmailorName] = useState();
  // const data = ;
//  console.log(data);
  let temp_char = ""
  return (
    <>
      <div
        className="userFilter_main"
        id="superuserFilter_main_id"
        onClick={(e) => {
          if (e.target.id == "superuserFilter_main_id") {
            setUserData(false);
          }
        }}
      >
        <div className="userFilter_submain">
          <div className="userFilter_title">
            <div onClick={()=>{
                setUserData(false);
            }}>

            <Images img_var={"cancel_cross"} />
            </div>
            <CustInput
              custclass=""
              type="text"
              inp_obj={{
                title: "",
                placeholder: "Type a name",
                value: page_values.name_person,
                // error: InputError.name_person,
                on_event: ({ target }) => {
                  setpage_values((prev) => ({
                    ...prev,
                    name_person: target.value,
                  }));
                },
              }}
            />
          </div>
          <div className="userFilter_body11">
            {data
              .filter((el) => {
                if (
                  el.name
                    .toLowerCase()
                    .includes(page_values.name_person.toLowerCase())
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .sort((x, y) => {
                let [a,b] = [x.name.toLowerCase(), y.name.toLowerCase()]
                if ( a < b){
                    return -1;
                  }
                  if ( a > b ){
                    return 1;
                  }
                  return 0;
              })
              .map((el, i) => {
                return (
                  <>
                  <div className="userFilter_body11_ko" key={i}>

                 
                  {el.name[0].toLowerCase() != temp_char.toLowerCase() &&
                    <div className="temp_char_io">
                        {
                            temp_char = el.name[0]
                        }
                        
                  </div>}
                    <div
                      className="userFilter_body11_title"
                      onClick={() => {
                        setpage_data((prev) => ({
                          ...prev,
                          user_filter_song: el.id,
                          user_song_list: [],
                        }));
                        if(For_user){
                          setTimeout(() => {
                            setpage_data((prev) => ({
                              ...prev,
                              user_filter_song: el.id,
                              user_song_list: [
                                ...(FilterAsFollow === 1
                                  ? [...page_data.creator_song]
                                  : FilterAsFollow === 2
                                  ? [...page_data.colab_song]
                                  : FilterAsFollow === 3
                                  ? [...page_data.split_Sheet]
                                  : [...page_data.required_signature]),
                              ],
                            }));
                          }, 100);
                        }
                        setUserData(false);
                      }}
                    >
                        
                      {el.name}
                    </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersFilter;
