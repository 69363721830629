import React, { useEffect } from "react";
import { Images } from "../Images/Images";

const greetingArray = [
  ..."11111".split("").map((el) => "Night"), // night 12 to 4.59
  ..."1111111".split("").map((el) => "Morning"), // morning 5 to 11.59
  ..."111111".split("").map((el) => "Afternoon"), // evening 12 to 5
  ..."1111111".split("").map((el) => "Evening"), //afternon 5 to 11.59
];

export const PageHeader = ({ user_name, current_page, show_logo }) => {
  // let name;
  // useEffect(() => {
  //   // if (user_name != "") {
  //   //   name =
  //   //     user_name?.split(" ")[0].charAt().toUpperCase() +
  //   //     user_name?.split(" ")[0].slice(1);
  //   // }
  // }, []);

  // console.log(user_name);

  return (
    <div className="page_header">
      {window.innerWidth < 500 ? "" : show_logo && <Images img_var="logo" />}
      <div className="current_page">{current_page || "{Current_page}"}</div>
      {window.innerWidth < 500 ? (
        ""
      ) : (
        <div className="good_morning_user">
          <div style={{ color: "var(--violetRed)" , whiteSpace:"nowrap"}}>
            Good {greetingArray[new Date().getHours()]},
          </div>
          {/* <div>Good {greetingArray[new Date().getHours()? 18: 0]},</div> */}
          <div
            style={{
              // width: "29%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {user_name &&
              user_name?.split(" ")[0].charAt(0).toUpperCase() +
                (user_name?.split(" ")[0].slice(1) || "")}
          </div>
        </div>
      )}
    </div>
  );
};
