import React from "react";
import { SwitchBtn } from "../../Custom/Button/Button";

function Ps({onevent,data11}) {
  // console.log(data11,"hiiiiiiii");
  return (
    <>
      <div className="contact-sp">
        <div className="contact-ps-1">Purchased</div>
        <div className="sw-btn-case">
        <SwitchBtn {...{
                  width:35,
                  height:20,
                  onevent,
                  data11,
                }}
                />
        </div>
        
        <div className="contact-ps-1">Suggested</div>
      </div>
    </>
  );
}

export default Ps;
